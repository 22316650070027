import React from "react";
import ReactDOM from "react-dom";
import Class from "classnames";
import Button from "commonui/Button";
import { PopCard, PopCardHeader, PopCardContent } from "./PopCard";
import * as PropTypes from "prop-types";
import marked from "marked";
import $ from "jquery";
import "./PortalPage.scss";

const propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        extra: PropTypes.string,
    }).isRequired,
};

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { open: false };
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <div>
                <span onClick={this.toggle}>{this.props.item.name}</span>
                <div className={Class("extra", { visible: this.state.open })}>
                    <span dangerouslySetInnerHTML={{ __html: marked(this.props.item.extra) }}></span>
                    <a href={this.props.item.link}><Button title="Visit" fill raised coloured className="light-blue" /></a>
                </div>
            </div>
        );
    }
}

Item.propTypes = propTypes;

const data = [
    {
        name: "Excel",
        image: "http://media.fyre.co/HmWDsHkfTFyShq7GqbE1_b01.png",
        link: [
            {
                name: "Development",
                extra: `**Database: ** exceldigital_9999_99_99\n\n**Version: ** 3.3.3`,
                link: "http://www.google.com",
            },
            {
                name: "Staging",
                extra: "**wasd** wasd",
                link: "http://www.reddit.com",
            },
        ],
    },
    {
        name: "NatBuild",
        image: "http://media.fyre.co/HmWDsHkfTFyShq7GqbE1_b01.png",
        link: [
            {
                name: "Gear Two",
                extra: `**Database: ** natbuild\n\n**Version: ** 0.1`,
                link: "http://10.0.0.10:3000",
            },
            {
                name: "Production",
                extra: `**Database: ** natbuild\n\n**Version: ** 0.1`,
                link: "http://natbuild.webfrontgears.com",
            },
        ],
    },
];

class PortalPage extends React.Component {
    constructor(props) {
        super(props);

        this.renderItems = this.renderItems.bind(this);
        this.renderLinks = this.renderLinks.bind(this);

        /*
        $.ajax({
            url: "/portals/page_json",
            success: this.onLoad.bind(this),
        });
        this.state = { data: null };
        */
        this.state = { data: data };
    }

    onLoad(data) {
        this.setState({ data: data });
    }

    renderItems(item, index) {
        return (
            <div key={index} className="portal">
                <PopCard>
                    <PopCardHeader
                        title={item.name}
                        image={item.image}
                        />
                    <PopCardContent>
                        {item.link.map(this.renderLinks)}
                    </PopCardContent>
                </PopCard>
            </div >
        );
    }

    renderLinks(item) {
        return (
            <Item key={item.name} item={item} />
        );
    }

    render() {
        return (
            <div className="portal-page">
                {this.state.data ?
                    <div className="portal-container">
                        {this.state.data.map(this.renderItems)}
                    </div>
                    :
                    <div className="empty">
                        Loading
                    </div>}
            </div>
        );
    }
}

export default PortalPage;
window.createPortal = function() {
    ReactDOM.render(<PortalPage />, $("#drxml")[0]);
};
