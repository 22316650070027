import * as Class from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";
import "./SubmitLoader.scss";
import { bind } from "decko";
import Icon from "./Icon";

const defaultProps = {
    enabled: true,
    title: "Submit",
};

interface SubmitLoaderProps {
    className?: string;
    disabled?: boolean;
    enabled?: boolean;
    onSubmit: (loader: SubmitLoader) => void;
    title?: string;
}

interface SubmitLoaderState {
    complete?: boolean;
    loading?: boolean;
}

/**
 * A button that morphs into a loading spinner
 */
export default class SubmitLoader extends React.Component<SubmitLoaderProps, SubmitLoaderState> {
    private static defaultProps = defaultProps;

    constructor(props: SubmitLoaderProps) {
        super(props);

        this.state = { loading: false, complete: false };
    }

    @bind
    private onClick(e: React.MouseEvent<HTMLDivElement>) {
        this.setState({ loading: true });
        if (this.props.onSubmit) {
            this.props.onSubmit(this);
        }
    }

    /** Call this when submit loader should finish animating */
    @bind
    public finish() {
        this.setState({ complete: true });
    }

    /** Cancel the loading animation */
    @bind
    public cancel() {
        this.setState({ loading: false });
    }

    @bind
    public reset() {
        this.setState({ complete: false, loading: false });
    }

    public render() {
        const { enabled, disabled, title, className, ...other } = this.props;
        const realDisabled = disabled || !enabled;
        return (
            <div
                className={Class("submit-loader-container", className, { disabled: realDisabled, loading: this.state.loading, complete: this.state.complete })}
                onClick={realDisabled ? undefined : this.onClick}
                tabIndex={0}
                aria-role="button"
                {...other}>
                <div className="submit-loader">
                    <span className="submit-title">
                        {title}
                    </span>
                    <Icon icon="fa-check" />
                </div>
                <div className="submit-overlay"></div>
            </div>
        );
    }
}
