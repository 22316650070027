import * as React from "react";
import Select2 from "react-select";
import "react-select/dist/react-select.min.css";
import "./Select.scss";

export interface SelectOptions {
    value: string | number;
    label: string;
}

class Select extends React.Component<any, never> {
    public render() {
        return (
            <span className="custom-select">
                <Select2
                    {...this.props} />
            </span>
        );
    }
}

export default Select;
