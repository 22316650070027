import "./Input.scss";

import * as Class from "classnames";
import * as $ from "jquery";
import * as React from "react";

import { action, IObservableObject, IObservableValue, isObservable } from "mobx";

import { observer } from "mobx-react";
import Icon from "./Icon";
import formatter from "./util/formatter";
import { bind } from 'decko';

interface IInputProps {
    disabled?: boolean;
    enabled?: boolean;
    error?: string;
    icon?: string;
    label?: string;
    onChange?: (value: string | number) => void;
    name?: string;
    password?: boolean;
    search?: boolean;
    placeholder?: string;
    selectOnFocus?: boolean;
    shrinkLabel?: boolean;
    type?: string;
    typeOptions?: object;
    value: string;
    simple?: boolean;
    observable?: object;
    onFocus?: () => void;
    onBlur?: () => void;
}

interface IInputState {
    focus?: boolean;
    error?: string | null;
}

/**
 * A material style input box with a few more extra nice options
 */
@observer
export default class Input extends React.Component<IInputProps, IInputState> {
    public static defaultProps: Partial<IInputProps> = { enabled: true, value: "", type: "text" };
    private inputRef: HTMLInputElement;

    constructor(props: IInputProps) {
        super(props);

        this.state = { focus: false, error: null };
    }

    private getRealValue(props: IInputProps): string {
        let realValue = props.value;
        if (props.observable) {
            realValue = props.observable[props.value];
        } else if (isObservable(realValue)) {
            realValue = (realValue as IObservableValue<string | number>).get();
        }
        return realValue as string;
    }

    @bind
    private onChange(event: any) {
        const value = event.currentTarget.value;
        this.changeValue(value);
    }

    @bind
    @action
    private changeValue(newValue: string | number, props = this.props) {
        if (props.observable) {
            props.observable[props.value] = newValue;
            if (props.onChange) {
                props.onChange(newValue);
            }
            return;
        }
        if (isObservable(props.value)) {
            const value = props.value as IObservableValue<string | number>;
            value.set(newValue);
            if (props.onChange) {
                props.onChange(newValue);
            }
        }
    }

    @bind
    private onFocus() {
        this.setState({ focus: true });
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    @bind
    private onBlur() {
        this.setState({ focus: false });
        if (this.props.type === "currency" || this.props.type === "number") {
            const realValue = this.getRealValue(this.props);
            if (typeof (realValue) === "string") {
                const value = parseFloat(realValue.replace(/[$,]/g, ""));
                if (isNaN(value)) {
                    this.changeValue(0);
                } else {
                    this.changeValue(value);
                }
            }
        }
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    public componentDidUpdate(prevProps: IInputProps, prevState: IInputState) {
        if (this.props.selectOnFocus) {
            if (!prevState.focus && this.state.focus) {
                this.inputRef.select();
            }
        }
    }

    /**
     * Focuses all the text inside input and selects it
     */
    @bind
    public focus() {
        $(this.inputRef).focus().select();
    }
    /**
     * Selects all text inside input
     */
    @bind
    public select() {
        $(this.inputRef).select();
    }
    /**
     * Blurs focus on input
     */
    @bind
    public blur() {
        this.inputRef.blur();
    }

    public render() {
        const { value, observable, simple, shrinkLabel, error, placeholder, search, type, typeOptions, enabled, disabled, label, icon, password, onBlur, onFocus, onChange, selectOnFocus, ...other } = this.props;
        const realDisabled = disabled || !enabled;

        let realValue = value;
        if (observable) {
            realValue = observable[value];
        }
        realValue = realValue as string;
        const active = realValue !== "" || placeholder !== undefined || this.state.focus || shrinkLabel !== undefined;

        let formattedValue = realValue;
        if ((!this.state.focus || realDisabled) && type === "currency") {
            formattedValue = formatter(realValue, type, typeOptions);
        }

        if (simple) {
            return (
                <input
                    aria-label={label}
                    ref={(ref) => this.inputRef = ref as HTMLInputElement}
                    readOnly={realDisabled}
                    type={password ? "password" : search ? "search" : "text"}
                    value={formattedValue === null ? "" : formattedValue}
                    placeholder={placeholder}
                    onFocus={realDisabled ? undefined : this.onFocus}
                    onBlur={realDisabled ? undefined : this.onBlur}
                    onChange={realDisabled ? undefined : this.onChange}
                    {...other}
                />
            );
        }

        return (
            <span className={Class("input", { invalid: error, disabled: realDisabled })}>
                {icon ?
                    (<Icon icon={icon} />)
                    : undefined}
                <div className={Class({ "has-icon": icon !== undefined })}>
                    <span className={Class("label", { active, focus: this.state.focus })}>{label}</span>
                    <span className={Class("error-label", { active, focus: this.state.focus })}>{error}</span>
                    <input
                        className={Class({ active: this.state.focus })}
                        aria-label={label}
                        ref={(ref) => this.inputRef = ref as HTMLInputElement}
                        readOnly={realDisabled}
                        type={password ? "password" : search ? "search" : "text"}
                        value={formattedValue}
                        placeholder={placeholder}
                        onFocus={realDisabled ? undefined : this.onFocus}
                        onBlur={realDisabled ? undefined : this.onBlur}
                        onChange={realDisabled ? undefined : this.onChange}
                        {...other}
                    />
                </div>
            </span>
        );
    }
}

export { Input as MobXInput };
