import * as React from "react";

interface ILazyLoaderState {
    loaded?: React.ComponentClass<any>;
}

export default class LazyLoader extends React.Component<any, ILazyLoaderState>{
    private editorRef: any | null;

    constructor(props: any) {
        super(props);

        import(/* webpackChunkName: "monaco" */ "./MonacoEditor")
            .then((monaco: any) => {
                this.setState({ loaded: monaco.default });
                if (!monaco.default) {
                    console.error("Unable to load monaco editor");
                }
            });

        this.state = { loaded: undefined };
    }

    public getEditor() {
        if (this.editorRef) {
            return this.editorRef.getEditor();
        }
    }

    public getModels() {
        if (this.editorRef) {
            return this.editorRef.getModels();
        }
    }

    public render() {
        if (!this.state.loaded) {
            return null;
        } else {
            return React.createElement(this.state.loaded, { ...this.props, ref: (ref) => this.editorRef = ref });
        }
    }
};

