import * as React from "react";
import "./ButtonGroup.scss";

/**
 * ButtonGroup holds multiple button items to display in a row
 */
export default class ButtonGroup extends React.Component<React.HTMLProps<HTMLSpanElement>, never> {
    constructor(props: React.HTMLProps<HTMLSpanElement>) {
        super(props);
    }

    public render() {
        return (
            <span className="button-group">
                {this.props.children}
            </span>
        );
    }
}
