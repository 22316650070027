import * as Class from "classnames";
import * as React from "react";
import style from "./Page.scss";
import { observer } from 'mobx-react';

interface IPageProps extends React.HTMLProps<HTMLSpanElement> {
    title?: string;
    type?: string;
}

@observer
export default class Page extends React.Component<IPageProps, never> {
    public componentDidMount() {
        if (this.props.title) {
            document.title = this.props.title;
        }
    }
    public render() {
        const { title, type, className, children, ...other } = this.props;
        switch (type) {
            case "webform":
                return (
                    <div className={Class("page", style.webform, "webform", className)} {...other}>
                        <div className={Class(style.backdrop, "backdrop")} />
                        <div className={Class(style.content, "content")}>
                            {children}
                        </div>
                    </div>
                );
            default:
                return <div className={Class("page", className)} {...other}>{children}</div>;
        }
    }
}
