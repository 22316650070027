/// <reference path="../global.d.ts" />
import * as Promise from "bluebird";

export function memoizedLoader(target: Loader, propertyKey: string, descriptor: PropertyDescriptor) {
    const {get} = descriptor;
    function memoizedGet() {
        const promise = (get as () => any).call(this);
        Object.defineProperty(this, propertyKey, {value: promise});
        promise.then((mod: any) => {this.modules[propertyKey] = mod; });
        return promise;
    }
    descriptor.get = memoizedGet;
}
export class Loader {
    public modules: any;
    constructor(loadTarget?: any, preload?: string[]) {
        this.modules = loadTarget || {};
        if (preload) {
            this.loadModules(preload);
        }
    }
    public loadModules(toLoad: string[]): Promise<any> {
        return Promise.map(toLoad, (item) => (this as any)[item]);
    }
}
