import * as Class from "classnames";
import * as React from "react";
import * as styles from "./CardBottom.scss";

/**
 * Displays content neatly at the bottom of a card
 */
export default class CardBottom extends React.Component<React.HTMLProps<HTMLDivElement>, never> {
    public render() {
        const { children, ...other } = this.props;
        return (
            <div {...other}>
                <div className={Class(styles["card-bottom-placeholder"], "card-bottom-placeholder")} />
                <div className={Class(styles["card-bottom"], "card-bottom")}>
                    {children}
                </div>
            </div>
        );
    }
}
