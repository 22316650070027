import * as React from "react";
import * as styles from "./ErrorDisplayComponent.scss";

interface IErrorDisplayComponentProps {
    children?: Array<false | true | JSX.Element | string | undefined> | JSX.Element;
}

interface IErrorDisplayComponentState {
    error?: string;
    info?: string;
}

export default class ErrorDisplayComponent extends React.Component<IErrorDisplayComponentProps, IErrorDisplayComponentState> {
    constructor(props: IErrorDisplayComponentProps) {
        super(props);
        this.state = {
            error: "",
            info: "",
        };
    }

    public componentDidCatch(error: any, info: any) {
        // Log error while removing our component from the stack
        this.setState({
            error: error.toString(),
            info: info.componentStack.replace("\n", "").replace(/^\s+in [(ErrorDisplayComponent)(Unknown)][^\n]*$\n/gm, ""),
        });
    }
    public render() {
        if (this.state.error) {
            return (
                <div className={styles.error}>
                    {this.state.error}
                    <pre>{this.state.info}</pre>
                </div>);
        }
        if (this.props.children) {
            return this.props.children;
        } else {
            return null;
        }
    }
}
