import * as Class from "classnames";
import * as React from "react";
import * as styles from "./CardContent.scss";

/**
 * Displays content inside a card and applies styling
 */
export default class CardContent extends React.Component<React.HTMLProps<HTMLDivElement>, never> {
    public render() {
        const { children, className, ...other } = this.props;
        return (
            <div className={Class(styles["card-content"], "card-content", className)} {...other}>
                {children}
            </div>
        );
    }
}
