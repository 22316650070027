import * as Class from "classnames";
import * as React from "react";
import styles from "./ActionBar.scss";

import { Menu, MenuItem } from "./Menu";

import { find } from "lodash";
import { inject, observer } from "mobx-react";
import ActionItem from "./ActionItem";
import Icon from "./Icon";
import Input from "./Input";
import Select from "./Select";

declare const global: any;

interface IFilterOptions {
    value: string;
    label: string;
}

interface IActionBarProps {
    activeFilter?: string;
    filterOptions?: IFilterOptions[];
    hasTabBar?: boolean;
    isLoading?: boolean;
    name?: string;
    onBackPressed?: () => void;
    onChangeFilter?: (value: IFilterOptions) => void;
    onSearch?: (search: string) => void;
    onSearchChange?: (val: any) => void;
    onSidebarPressed?: () => void;
    searchMulti?: boolean;
    searchObservable?: object;
    searchOptions?: any;
    searchValue?: any;
    selectSearch?: boolean;
    sidebar?: boolean;
    /** Scroll dynamically with javascript to auto-hide */
    smartScroll?: boolean;
    store?: any;
    subtitle?: string;
    title?: string;
    titleImage?: string;
    transparent?: boolean;
}

interface IActionBarState {
    showingSearch: boolean;
}

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !global.MSStream;
const isMobile = /Mobi/.test(navigator.userAgent);

@inject((props, stores, context) => props)
@observer
export default class ActionBar extends React.Component<IActionBarProps, IActionBarState> {
    private searchInputRef: Input | null;
    private actionbarRef: HTMLDivElement | null;
    private lastScroll: number = 0;

    constructor(props: IActionBarProps) {
        super(props);

        this.changeFilter = this.changeFilter.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.state = { showingSearch: false };
    }

    public componentDidMount() {
        if (this.props.smartScroll) {
            document.addEventListener("scroll", this.onScroll);
        }
    }

    public componentWillUnmount() {
        if (this.props.smartScroll) {
            document.removeEventListener("scroll", this.onScroll);
        }
    }

    private onScroll() {
        if (window.scrollY <= this.lastScroll) {
            const posY = this.actionbarRef.getBoundingClientRect().top;
            if (0 >= posY + this.actionbarRef.clientHeight) {
                this.actionbarRef.style.top = `${Math.max(0, window.scrollY - this.actionbarRef.clientHeight)}px`;
            }
            if (posY >= 0) {
                this.actionbarRef.style.position = "sticky";
                this.actionbarRef.style.top = `0px`;
            }
        } else {
            if (this.actionbarRef.style.position) {
                this.actionbarRef.style.position = null;
                this.actionbarRef.style.top = `${this.lastScroll}px`;
            }
        }
        this.lastScroll = window.scrollY;
    }

    private changeFilter(e: React.MouseEvent<HTMLElement>) {
        const { onChangeFilter, filterOptions } = this.props;
        const newValue = e.currentTarget.dataset.value;
        const newFilter = find(filterOptions || [], { value: newValue }) as IFilterOptions;
        if (onChangeFilter) {
            onChangeFilter(newFilter);
        }
    }

    private startSearch() {
        this.setState({ showingSearch: true });
        setTimeout(() => {
            if (this.searchInputRef) {
                this.searchInputRef.focus();
            }
        }, 10);
    }

    private onSearch(e?: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLElement>) {
        if (this.props.onSearch) {
            this.props.onSearch(this.props.searchObservable[this.props.searchValue]);
            this.setState({ showingSearch: false });
        }
        if (e) {
            e.preventDefault();
        }
        return false;
    }

    private onClearSearch() {
        if (this.props.searchObservable) {
            this.props.searchObservable[this.props.searchValue] = "";
            this.onSearch();
        }
    }

    public render() {
        const { name, title, subtitle, sidebar, onSidebarPressed, hasTabBar, smartScroll, transparent, titleImage, store, onBackPressed, searchObservable, activeFilter, filterOptions, isLoading, searchValue, searchOptions, searchMulti, onSearchChange, children, ...other } = this.props;

        const realChildren = (Array.isArray(children) ? children : [children]) as Array<React.ReactElement<any>>;

        let maxIcons = 6;
        const width = global.innerWidth / global.devicePixelRatio;
        if (width <= 1280) {
            maxIcons--;
            if (width <= 960) {
                maxIcons--;
                if (width <= 840) {
                    maxIcons--;
                    if (width <= 600) {
                        maxIcons--;
                    }
                }
            }
        }

        // Remove one icon slot for search icon
        if (searchOptions) {
            if (width <= 600) {
                maxIcons--;
            } else {
                maxIcons = 4;
            }
        }

        // Generate the lists of shown icons and overflow options
        const iconList: React.ReactFragment[] = [];
        const excludedList: any[] = [];
        const menuList: React.ReactFragment[] = [];
        // Loop first to get all the show always icons
        realChildren.forEach((item, index: number) => {
            if (item && (item.type === ActionItem || item.type.name === "ActionItem") && item.props.showAsIcon === "always") {
                iconList.push(<ActionItem key={index} type="icon" index={index} {...item.props} />);
            }
        });
        // Fit all the ones taht can fit
        realChildren.forEach((item, index: number) => {
            if (item && (item.type === ActionItem || item.type.name === "ActionItem")) {
                if ((item.props.showAsIcon === "ifRoom" || !item.props.showAsIcon) && iconList.length < maxIcons) {
                    iconList.push(<ActionItem key={index} type="icon" index={index} {...item.props} />);
                } else if (item.props.showAsIcon !== "always") {
                    excludedList.push(item);
                }
            }
        });
        // Push everything else into menu
        if (excludedList.length > 1) {
            excludedList.forEach((item, index: number) => {
                menuList.push(<ActionItem key={index} type="menu" {...item.props} />);
            });
        } else if (excludedList.length) {
            const item = excludedList[0];
            iconList.push(<ActionItem key={iconList.length + 1} type="icon" index={iconList.length + 1} {...item.props} />);
        }

        const hasLeftIcon = sidebar || onBackPressed !== undefined;
        const hasFilter = filterOptions !== undefined;
        const activeFilterObject = find(filterOptions || [], { value: activeFilter }) || { label: "Error" };
        let displayTitle = title;
        if (searchObservable && searchObservable[searchValue]) {
            displayTitle = searchObservable[searchValue];
        }

        return (
            <div
                ref={(ref) => this.actionbarRef = ref}
                className={Class("action-bar", [styles["action-bar"]], {
                    [styles.ios]: iOS,
                    [styles.standalone]: window.navigator.standalone,
                    [styles.searching]: this.state.showingSearch,
                    [styles.transparent]: transparent,
                    [styles["has-tab-bar"]]: hasTabBar,
                    [styles["smart-scroll"]]: smartScroll,
                })}>
                {sidebar && !this.state.showingSearch &&
                    <div
                        className={Class("sidebar-button", styles["sidebar-button"])}
                        tabIndex={0}
                        onClick={store ? store.onSidebar : onSidebarPressed}>
                        <Icon
                            icon="fa-bars" />
                    </div>
                }
                {onBackPressed && !this.state.showingSearch &&
                    <div
                        tabIndex={0}
                        onClick={onBackPressed}
                        className={Class("back-button", styles["back-button"])}>
                        <Icon
                            icon={iOS ? "fa-angle-left" : "fa-arrow-left"} />
                    </div>
                }
                {!this.state.showingSearch &&
                    <header id={`actionbar-header${name}`} className={Class({ [styles.hasLeftIcon]: hasLeftIcon, [styles.hasFilter]: hasFilter, [styles.hasSubtitle]: subtitle })}>
                        {titleImage &&
                            <img src={titleImage} />
                        }
                        {!titleImage &&
                            <span className={Class("title", styles.title, { [styles.center]: iOS && !subtitle && realChildren.length <= 1 })}>{displayTitle}</span>
                        }
                        {!titleImage &&
                            <span className={Class("filter", styles.filter)}>{subtitle || (hasFilter && activeFilterObject.label)}</span>
                        }

                        {filterOptions &&
                            <Menu target={`#actionbar-header${name}`} mount="below-right">
                                {filterOptions.map((filter) => {
                                    return <MenuItem
                                        key={filter.value}
                                        title={filter.label || "Error"}
                                        data-value={filter.value}
                                        onClick={this.changeFilter} />;
                                })}
                            </Menu>}
                    </header>
                }
                {searchOptions &&
                    <Select
                        value={searchValue}
                        onChange={onSearchChange}
                        options={searchOptions}
                        multi={searchMulti} />
                }
                {searchObservable && this.state.showingSearch &&
                    <span>
                        <i className="search-button" onClick={this.onSearch} />
                        <form action="." onSubmit={this.onSearch}>
                            <Input
                                search
                                placeholder="Search..."
                                ref={(ref) => this.searchInputRef = ref}
                                name="search"
                                observable={searchObservable}
                                value={searchValue} />
                        </form>
                        <i className="close-button" onClick={this.onClearSearch} />
                    </span>
                }
                {!this.state.showingSearch &&
                    <div className={Class("action-container", styles["action-container"])}>
                        {(searchOptions || searchObservable) && <i className="material-icons hide-desktop" onClick={this.startSearch}>search</i>}
                        {iconList}
                        {menuList.length > 0 &&
                            <span>
                                <Icon id={`actionbar-menu${name}`} icon={iOS ? "fa-ellipsis-h" : "fa-ellipsis-v"} />
                            </span>
                        }
                    </div>
                }
                {!this.state.showingSearch && menuList.length > 0 &&
                    <Menu target={`#actionbar-menu${name}`} mount="below-left">
                        {menuList}
                    </Menu>}
                {isLoading && <div className={Class("loading-bar")} />}
            </div>
        );
    }
}
