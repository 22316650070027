import  {registerCalculationType} from "./Calculator";
import PercentageTiers from "./PercentageTiers";
import { ITiersOptions} from "./Tiers";

export interface ISimplePercentageTiersOptions extends ITiersOptions {
    calculation_type: "simple_percentage_tiers";
}

export default class SimplePercentageTiers extends PercentageTiers {
    public calculationFunction(inputs: any): any {
        inputs.tierLookupValue = inputs.baseValue;
        return this.percentage_tiers_calculate(inputs);
    }
}

registerCalculationType("simple_percentage_tiers", SimplePercentageTiers);
