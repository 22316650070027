import { IObservableObject, IObservableValue, isObservable } from "mobx";
import { observer } from "mobx-react";
import * as PropTypes from "prop-types";
import * as React from "react";

const defaultProps = {
    enabled: true,
};

const childContextTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

interface IBaseRadioGroupProps {
    name?: string;
    onChange?: (value: string) => void;
    enabled?: boolean;
    disabled?: boolean;
    label?: string;
}

interface IStandardRadioGroupProps extends IBaseRadioGroupProps {
    observable?: never;
    value: string | IObservableValue<string>;
}

interface IObservableRadioGroupProps extends IBaseRadioGroupProps {
    observable: IObservableObject;
    value: string;
}

type RadioGroupProps = IStandardRadioGroupProps | IObservableRadioGroupProps;

/**
 * A RadioGroup holds multiple Radio's and manages their state
 */
@observer
export default class RadioGroup extends React.Component<RadioGroupProps, never> {
    public static defaultProps = defaultProps;
    public static childContextTypes = childContextTypes;

    constructor(props: RadioGroupProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    private getChildContext() {
        let value = this.props.value;
        if (this.props.observable) {
            value = this.props.observable[this.props.value];
        } else if (isObservable(value)) {
            value = value.get();
        }
        return { onChange: this.onChange, value, disabled: this.props.disabled || !this.props.enabled };
    }

    private onChange(newValue: string) {
        if (this.props.observable) {
            this.props.observable[this.props.value] = newValue;
            if (this.props.onChange) {
                this.props.onChange(newValue);
            }
            return;
        }
        if (isObservable(this.props.value)) {
            const value = this.props.value as IObservableValue<string>;
            value.set(newValue);
            if (this.props.onChange) {
                this.props.onChange(newValue);
            }
            return;
        }
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    }

    public render() {
        const { children, name, observable, value, enabled, disabled, onChange, label, ...other } = this.props;

        let realValue = value;
        if (observable) {
            realValue = observable[value];
        } else {
            if (isObservable(value)) {
                realValue = (value as IObservableValue<string>).get();
            }
        }
        realValue = realValue as string;

        return (
            <div {...other}>
                {children}
                <input aria-label={label} name={name} value={realValue} disabled style={{ display: "none" }} />
            </div>
        );
    }
}

export { RadioGroup as MobXRadioGroup };
