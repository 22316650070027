exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1MdvzdZ-SoCbXjSehqhIcA{height:52px;width:100%}.eBgcISgYf3BlxtMKQzzqH{background:rgba(0,0,0,0.05);padding:16px;padding-left:32px;padding-right:32px;position:absolute;bottom:0px;left:0px;right:0px;box-sizing:content-box}\n", ""]);

// exports
exports.locals = {
	"card-bottom-placeholder": "_1MdvzdZ-SoCbXjSehqhIcA",
	"card-bottom": "eBgcISgYf3BlxtMKQzzqH"
};