import "./Toast.scss";

import * as Class from "classnames";
import * as $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";

interface IToastOptions {
    /** Additional classnames that are added to the content inside the toast */
    classNames?: string;
    /** Durations in milliseconds to show for, defaults to 2400 */
    duration?: number;
    /** Sound to play when toast appears */
    sound?: string;
    /** Height of toast */
    height?: number;
    /** Width of toast */
    width?: number;
    /** Easing CSS function to use */
    easing?: string;
    /** Classname that is added to toast when it first appears */
    onShowClass?: string;
    /** Display HTML content instead, overrides anything in content parameter */
    htmlContent?: boolean;
}

/**
 * A toast singleton to display short notifications on the screen
 */
class Toast {
    private divContainer: HTMLDivElement;
    private closeTimer: any;

    private init() {
        if (!this.divContainer) {
            this.divContainer = document.createElement("div");
            document.body.appendChild(this.divContainer);
        }
    }

    public show(content: string, options: IToastOptions = {}) {
        this.init();
        this.render(content, options);
        setTimeout(() => { $("#toast-container").addClass(options.onShowClass || "open"); }, 1);
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
        if (options.sound) {
            (new Audio(options.sound)).play();
        }
        this.closeTimer = setTimeout(this.close, options.duration || 2400);
    }

    private close() {
        $("#toast-container").removeClass().addClass("toast-container");
    }

    private render(content: string, options: IToastOptions) {
        ReactDOM.render(
            <div
                id="toast-container"
                className="toast-container"
                style={{
                    height: options.height,
                    width: options.width,
                    transitionTimingFunction: options.easing,
                }}>
                <div className={Class("toast", options.classNames)}>
                    {!options.htmlContent &&
                        <span>{content}</span>
                    }
                    {options.htmlContent &&
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    }
                </div>
            </div>,
            this.divContainer,
        );
    }
}

export default new Toast();
