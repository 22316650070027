import * as React from "react";
import * as $ from "jquery";
import { observable, action } from "mobx";
import { observer, Provider, inject } from "mobx-react";
import GearsActionSubscriber from "../util/GearsActionSubscriber";
import { NoteType } from './NoteType';

interface NoteProps {
    channel?: string;
    route?: string;
    submitRoute?: string;
    email?: string;
    userLookup?: string | (() => number);
    data?: any;
}

interface User {
    id: number;
    text: string;
}

@observer
export default class NoteManager extends React.Component<NoteProps, never>{
    @observable public notes: NoteType[] = [];
    @observable public user: number = -1;
    @observable public loading: boolean = true;
    @observable public canResolve: boolean = false;
    public channel?: string = "";
    @observable public route?: string = "";
    @observable public submitRoute?: string = "";
    @observable public email?: string = "";
    @observable public data?: any;

    constructor(props: NoteProps) {
        super(props);

        this.channel = props.channel;
        this.route = props.route;
        this.submitRoute = props.submitRoute;
        this.email = props.email;
        this.data = props.data;

        const filter = this.channel
            ? {
                filter: {
                    logic: "and",
                    filters: [{
                        operator: "eq",
                        field: "channel",
                        value: props.channel,
                    }]
                }
            }
            : this.props.data;

        // Load all the existing notes
        $.ajax({
            url: props.route || "/notes/gears_index",
            type: "POST",
            dataType: "json",
            data: filter,
        }).then(action((results: any) => {
            this.notes = results.data;
            this.loading = false;
            new GearsActionSubscriber({
                model: "note",
                data: this.notes,
            });
        }));

        // Load who we are
        if (!props.email) {
            if (!props.userLookup || typeof props.userLookup === "string") {
                $.ajax({
                    url: props.userLookup || "/homelookups.json",
                    type: "GET",
                    dataType: "json",
                }).then(action((result) => {
                    this.user = result.user.id;
                    this.canResolve = result.user.webfront_relations.tags.administrator;
                }))
            } else {
                this.user = props.userLookup();
            }
        }
    }

    @action
    public componentDidUpdate(prevProps: NoteProps) {
        if (prevProps.channel !== this.props.channel
            || prevProps.route !== this.props.route
            || prevProps.submitRoute !== this.props.submitRoute
            || prevProps.email !== this.props.email
            || prevProps.data !== this.props.data
        ) {
            this.channel = this.props.channel;
            this.route = this.props.route;
            this.submitRoute = this.props.submitRoute;
            this.email = this.props.email;
            this.data = this.props.data;
        }
    }

    public render() {
        this.channel;
        this.route;
        this.submitRoute;
        this.email;
        this.data;
        return [
            <link href="/gearscss.css"
                type="text/css"
                key="css-linker"
                rel="stylesheet" />,
            <Provider
                key="provider-linker"
                noteStore={this}>
                <div>
                    {this.props.children}
                </div>
            </Provider>
        ];
    }
}
