import React from "react";
import { Card, CardContent } from "commonui/Card";
import PropTypes from "prop-types";

const propTypes = {
    email: PropTypes.string.isRequired,
};

/**
* @access private
*/
class MBIECreated extends React.Component {
    constructor(props) {
        super(props);
        this.colour = $("#mbie").attr("data-colour");
    }

    render() {
        // {/*<CardHeader style={{backgroundImage: "url(/ga_logo/mbie_logo.png)"}}/>*/}
        return (
            <div className="mbie-created-page">
                <Card style={{ width: "800px", margin: "auto auto" }} colour={this.colour}>
                    <CardContent>
                        <div className="content">
                            <h2>Account Created</h2>
                            An email has been sent to <span>{this.props.email}</span><br/>Please open the email and click the link to verify your account.
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

MBIECreated.propTypes = propTypes;
export default MBIECreated;
