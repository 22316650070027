import styles from "./Button.scss";

import * as Class from "classnames";
import * as React from "react";
import { bind } from "decko";

import Icon from "./Icon";

export interface IButtonProps extends React.HTMLProps<HTMLElement> {
    className?: string;
    coloured?: boolean;
    disabled?: boolean;
    enabled?: boolean;
    fill?: boolean;
    flat?: boolean;
    icon?: string;
    onClick?: any;
    title?: string;
}

const isMobile = window.orientation !== undefined;

function isTouchEvent(arg: any): arg is React.TouchEvent<HTMLButtonElement> {
    return arg.clientX === undefined;
}

/**
 * A customizable button
 */
export default class Button extends React.Component<IButtonProps, never> {
    public static defaultProps: IButtonProps = { enabled: true };
    private buttonRef: HTMLElement | null;
    private rippleRef: HTMLElement | null;
    private rippleExpansionTimer?: NodeJS.Timer;

    public shouldComponentUpdate(nextProps: IButtonProps) {
        if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
            return true;
        }
        return false;
    }

    @bind
    private onMouseDown(e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) {
        if (!this.buttonRef || !this.rippleRef) {
            return;
        }
        const position = this.buttonRef.getBoundingClientRect();
        let x: number;
        let y: number;
        if (!isTouchEvent(e)) {
            x = e.clientX - position.left;
            y = e.clientY - position.top;
        } else {
            x = e.touches[0].clientX - position.left;
            y = e.touches[0].clientY - position.top;
        }
        const initialSize = 48; // About size of a finger press
        const expandSpeed = 128;
        const maxSize = Math.max(this.buttonRef.clientWidth * 2, this.buttonRef.clientHeight * 2);
        const expansionTime = Math.floor((maxSize / expandSpeed) * 300);
        Object.assign(this.rippleRef.style, {
            transition: "",
            left: (x - initialSize / 2) + "px",
            top: (y - initialSize / 2) + "px",
            width: `${initialSize}px`,
            height: `${initialSize}px`,
            opacity: "1",
            display: "block",
        });
        setTimeout(() => {
            if (this.buttonRef && this.rippleRef) {
                Object.assign(this.rippleRef.style, {
                    transition: `width, height, opacity, background-color, left, top`,
                    transitionDuration: `${expansionTime}ms`,
                    transitionTimingFunction: "ease-out",
                    opacity: "0",
                    width: maxSize + "px",
                    height: maxSize + "px",
                    left: (x - maxSize / 2) + "px",
                    top: (y - maxSize / 2) + "px",
                });
                if (this.rippleExpansionTimer) {
                    clearTimeout(this.rippleExpansionTimer);
                    this.rippleExpansionTimer = undefined;
                }
                this.rippleExpansionTimer = setTimeout(() => {
                    if (this.buttonRef && this.rippleRef) {
                        Object.assign(this.rippleRef.style, {
                            display: "none",
                        });
                    }
                }, expansionTime);
            }
        }, 1);
    }

    public render() {
        const { icon, title, flat, className, disabled, enabled, coloured, fill, onClick, ...other } = this.props;
        const realDisabled = disabled || !enabled;
        const classNames = {
            [styles["disabled"]]: realDisabled,
            [styles["no-icon"]]: !icon,
            [styles["no-title"]]: !title,
            [styles.coloured]: coloured,
            [styles.fill]: fill,
            [styles.flat]: flat,
            "disabled": realDisabled,
            "no-icon": !icon,
            "no-title": !title,
            coloured,
            fill,
            flat,
        };
        if (!title && !other["aria-label"]) {
            console.warn("Button without title requires an aria-label!");
        }
        return (
            <button
                ref={(ref) => this.buttonRef = ref}
                onClick={onClick}
                onMouseDown={!isMobile ? this.onMouseDown : undefined}
                onTouchStart={this.onMouseDown}
                disabled={realDisabled}
                className={Class(styles["simple-button"], "simple-button", className, classNames)}
                {...other}>
                <div
                    ref={(ref) => this.rippleRef = ref}
                    className={styles.ripple} />
                {icon ? (<Icon icon={icon} fixedWidth small />) : ""}
                {icon && title ? " " : ""}
                {title}
            </button>
        );
    }
}
