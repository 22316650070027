/// <reference path="../global.d.ts" />
import * as Promise from "bluebird";
import * as csvtojson from "../../node_modules/@types/csvtojson/index.d.ts";
import { Loader, memoizedLoader } from "./Loader";
import { ISassModule } from "./sass";
import { TemplatesCompilers } from "./templates";

// declare var require: {
//     <T>(path: string): T;
//     (paths: string[], callback: (...modules: any[]) => void): void;
//     ensure: (paths: string[], callback: (require: <T>(path: string) => T) => void) => void;
// };
(global as any).Promise = Promise;
(global as any).Bluebird = Promise;
if (Promise.longStackTraces) {
    Promise.longStackTraces();
}

export class LiteLoader extends Loader {
    public modules: { templates: TemplatesCompilers, sass: ISassModule, csvtojson: csvtojson.Converter };

    constructor(loadTarget?: any, preload?: string[]) {
        super(loadTarget, preload);
    }

    @memoizedLoader
    get sass(): Promise<ISassModule> {
        return Promise.resolve(import(/* webpackChunkName: "sass" */ "./sass"));
        /*
        return new Promise((resolve) => {
            require.ensure(["./sass"], () => {
                resolve(System.import("./sass"));
            }, "sass");
        }) as Promise<ISassModule>;
        */
    }

    @memoizedLoader
    get csvtojson(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "csvtojson" */ "csvtojson"));
        /*
        return new Promise((resolve) => {
            require.ensure(["csvtojson"], () => {
                resolve(System.import("csvtojson"));
            }, "csvtojson");
        }) as Promise<ISassModule>;
        */
    }

    @memoizedLoader
    get templateBuilder(): Promise<TemplatesCompilers> {
        const tc = Promise.resolve(import(/* webpackChunkName: "template_builder" */ "../react-components/template_builder/TemplateBuilder"));
        return Promise.join(
            tc,
            this.templates,
            this.sass,
            (tb) => (global as any).templateBuilder = tb,
        ).return(tc);
        /*
        const tc = new Promise((resolve) => {
            require.ensure(["../react-components/template_builder/TemplateBuilder"], () => {
                resolve(System.import("../react-components/template_builder/TemplateBuilder"));
            }, "template_builder");
        });
        return Promise.join(tc, this.templates, this.sass, (tb) => (global as any).templateBuilder = tb).return(tc);
        */
    }

    @memoizedLoader
    get templateHelp(): Promise<TemplatesCompilers> {
        const tc = Promise.resolve(import(/* webpackChunkName: "template_help" */ "../react-components/template_builder/help/TemplateHelp"));
        return Promise.join(
            this.templates,
            (th) => (global as any).templateHelp = th,
        ).return(tc);
        /*
        //const tc = System.import("../react-components/template_builder/help/TemplateHelp");
        const tc = new Promise((resolve) => {
            require.ensure(["../react-components/template_builder/help/TemplateHelp"], () => {
                resolve(System.import("../react-components/template_builder/help/TemplateHelp"));
            }, "template_help");
        });
        return Promise.join(tc, (th) => (global as any).templateHelp = th).return(tc);\
        */
    }

    @memoizedLoader
    get templates(): Promise<TemplatesCompilers> {
        return new Promise((resolve) => {
            require.ensure(["./templates"], () => {
                resolve(System.import("./templates"));
            }, "templates");
        })
            .tap((templates: TemplatesCompilers) => ({ ...global, ...templates })) as Promise<TemplatesCompilers>;
        /*
        return Promise.resolve(System.import("./templates"))
            .tap((templates: TemplatesCompilers) => Object.assign(global, templates));
            */
    }

    @memoizedLoader
    get dropzone(): Promise<> {
        return new Promise((resolve) => {
            require.ensure(["dropzone/dist/min/dropzone.min.css", "react-dropzone-component/styles/filepicker.css", "react-dropzone-component"], () => {
                resolve(System.import("react-dropzone-component"));
            }, "dropzone");
        });
    }

    @memoizedLoader
    get charts(): Promise<> {
        return new Promise((resolve) => {
            require.ensure(["recharts"], () => {
                resolve(System.import("recharts"));
            }, "recharts");
        });
    }
}

export default new LiteLoader();

//const opener = (global as any).opener;
//const lite: LiteLoader = opener && (opener.lite as LiteLoader) || new LiteLoader();
