/// <reference path="../../global.d.ts" />

import * as Promise from "bluebird";
import * as Class from "classnames";
import * as FileSaver from "filesaver.js-npm/FileSaver";
import * as $ from "jquery";
import "jquery-deparam";
import * as _ from "lodash";
import * as marked from "marked";
import * as md5 from "md5";
import * as MobX from "mobx";
import * as moment from "moment-timezone";
import * as React from "react";
import * as ReactDOM from "react-dom";
//import * as Rx from "rxjs/Rx";

import ActionTab from "commonui/ActionTab";
import ActionTabs from "commonui/ActionTabs";
import { ButtonGroup, ButtonItem } from "commonui/ButtonGroup";
import { Card, CardAction, CardBottom, CardContent, CardHeader, CardTab, CardTabs } from "commonui/Card";
import Checkbox, { MobXCheckbox } from "commonui/Checkbox";
import Input, { MobXInput } from "commonui/Input";
import { Menu, MenuHeader, MenuItem } from "commonui/Menu";
import { PageController, PageRoute } from "commonui/Page";
import Portal from "commonui/Portal";
import PortalImage from "commonui/PortalImage";
import PullToRefresh from "commonui/PullToRefresh";
import Range from "commonui/Range";
import Switch, { MobXSwitch } from "commonui/Switch";
import TextArea, { MobXTextArea } from "commonui/TextArea";
import Theme from "commonui/Theme";
import createConfigDialog, { ConfigDialogBuilder } from "commonui/ConfigDialog";
import {
    arraysToDataGrid,
    dataGridToArrays,
    dataGridToCSV,
    dataGridToObjects,
    dataGroupToArrays,
    dataGroupToObjects,
    mappedObjectsToDataGrid,
    objectsToDataGrid,
    objectToDataGroup,
} from "commonui/util/grid-converter";
import { errorDisplay, rtError } from "../errorDisplay";

import ActionBar from "commonui/ActionBar";
import ActionItem from "commonui/ActionItem";
import Button from "commonui/Button";
import CircleImage from "commonui/CircleImage";
import DataGrid from "commonui/DataGrid";
import DataGridBuilder from "commonui/DataGrid/DataGridBuilder";
import DatePicker from "commonui/DatePicker";
import Device from "commonui/Device";
import gearsDialog from "commonui/Dialog";
import FloatingActionButton from "commonui/FloatingActionButton";
import Icon from "commonui/Icon";
import List, { ListItem } from "commonui/List";
import Loader from "commonui/Loader";
import Radio from "commonui/Radio";
import RadioGroup from "commonui/RadioGroup";
import Select from "commonui/Select";
import Sidebar from "commonui/Sidebar";
import SmartGrid from "commonui/SmartGrid";
import SmartGridBuilder from "commonui/SmartGrid/SmartGridBuilder";
import SnackBar from "commonui/SnackBar";
import StarRating from "commonui/StarRating";
import SubmitLoader from "commonui/SubmitLoader";
import Toast from "commonui/Toast";
import Tooltip from "commonui/Tooltip";
import format from "commonui/util/formatter";
import * as numeral from "numeral";
import tryCatch from "../../gears/helpers/tryCatch";
import lite from "../../modules/LiteLoader";
import Format from "../Format";
import FormEntry from "../public/FormEntry";
import FormPage from "../public/FormPage";
import FormSubmitted from "../public/FormSubmitted";
import Markdown from "../public/Markdown";
import MultiPage from "../public/MultiPage";
import Page from "../public/Page";
import Perspective from "../public/Perspective";
import ErrorDisplayComponent from "../ErrorDisplayComponent";
import GearsActionSubscriber from "../util/GearsActionSubscriber";
import { Note, NoteManager } from "../Note"
import { observer } from 'mobx-react';
import { camelize, capitalize, classify, dasherize, demodulize, foreign_key, humanize, indexOf, inflect, ordinalize, pluralize, singularize, tableize, titleize, transform, underscore } from "inflection";

declare const global: any;

type HomeLookup = InvalidHomeLookup | ValidHomeLookup;

interface InvalidHomeLookup {
    title: string;
    message: string;
    error: string;
    query_log_id: number;
}

// TODO: Replace with proper homelookups User interface
interface ValidHomeLookup {
    user: User;
}

interface User {
    id: number;
    name: string;
}

function isValidHomeLookup(arg: any): arg is ValidHomeLookup {
    return arg.user !== undefined;
}

function requireLogin(redirect: boolean = false) {
    return $.ajax({
        url: "/homelookups.json",
    }).then((result: HomeLookup) => {
        if (isValidHomeLookup(result)) {
            return true;
        } else {
            return false;
        }
    }).catch(() => {
        return false;
    }).then((loggedIn: boolean) => {
        if (redirect && !loggedIn) {
            const redirectURL = btoa(window.location.pathname + window.location.search + window.location.hash);
            window.location.assign("/login#redirect=" + redirectURL);
        }
        return loggedIn;
    }).then((loggedIn: boolean) => {
        if (!loggedIn) {
            throw new Error("Not logged in");
        }
        return loggedIn;
    });
}

type IDataSourceFilterItem = kendo.data.DataSourceFilterItem;
type IDataSourceFilters = {
    logic: "and" | "or",
    filters: Array<IDataSourceFilterItem | IDataSourceFilters>,
};

interface FilterOptions {
    field: string;
    value: string | number | boolean | string[] | number[] | boolean[];
    operator?: "eq" | "neq";
}

/**
 * Creates a kendo compatible filter by iterating over an array of input values
 */
function createFilter(options: FilterOptions[], fullText?: string): IDataSourceFilters {
    const filter: IDataSourceFilters = {
        logic: "and",
        filters: [],
    }
    for (const option of options) {
        if (Array.isArray(option.value)) {
            const subFilter: IDataSourceFilters = {
                logic: (option.operator || "eq") === "eq" ? "or" : "and",
                filters: [],
            };
            for (const value of option.value) {
                subFilter.filters.push({
                    field: option.field,
                    operator: option.operator || "eq",
                    value: value,
                });
            }
            filter.filters.push(subFilter);
        } else {
            filter.filters.push({
                field: option.field,
                operator: option.operator || "eq",
                value: option.value,
            })
        }
    }
    if (fullText) {
        filter.filters.push({
            field: "fulltext",
            operator: "contains",
            value: fullText,
        })
    }
    return filter;
}

export const modules: any = {
    $,
    DataGridBuilder,
    SmartGridBuilder,
    FileSaver,
    MobX,
    mobx: MobX,
    Promise,
    React,
    ReactDOM,
    Toast,
    SnackBar,
    _,
    arraysToDataGrid,
    dataGridToArrays,
    dataGridToCSV,
    dataGridToObjects,
    dataGroupToArrays,
    dataGroupToObjects,
    errorDisplay,
    extendObservable: MobX.extendObservable,
    gearsDialog,
    mappedObjectsToDataGrid,
    md5,
    moment,
    Moment: moment,
    objectToDataGroup,
    objectsToDataGrid,
    observable: MobX.observable,
    rtError,
    tryCatch,
    numeral,
    Portal,
    Loader,
    format,
    Class,
    GearsActionSubscriber,
    requireLogin,
    createConfigDialog,
    ConfigDialogBuilder,
    createFilter,
    observer,
    camelize,
    capitalize,
    classify,
    dasherize,
    demodulize,
    foreign_key,
    humanize,
    indexOf,
    inflect,
    ordinalize,
    pluralize,
    singularize,
    tableize,
    titleize,
    transform,
    underscore,
};

interface ILazyLoaderState {
    loaded?: React.ComponentClass<any>;
}
/**
 *  Loads a component dynamically by calling liteloader on the appropiate module
 */
type Module = "dropzone";
function lazyLoadFactory(module: Module, component: string = "default", defineGlobal?: string) {
    return class LazyLoader extends React.Component<any, ILazyLoaderState>{
        constructor(props: any) {
            super(props);

            lite[module].then((loadedModule: any) => {
                if (defineGlobal) {
                    global[defineGlobal] = loadedModule[component];
                }
                this.setState({ loaded: loadedModule[component] });
                if (!loadedModule[component]) {
                    gearsDialog({
                        title: "Error",
                        content: `Unable to load component (${component}) from module (${module})`,
                        buttons: ["OK"],
                    });
                }
            });

            this.state = { loaded: undefined };
        }

        public render() {
            if (!this.state.loaded) {
                return null;
            } else {
                return React.createElement(this.state.loaded, { ...this.props });
            }
        }
    };
}

/**
 *  Special loader for recharts as children as not rendered
 */
function lazyChartFactory(component: string) {
    return class RechartsLoader extends React.Component<any, ILazyLoaderState> {
        constructor(props: any) {
            super(props);

            lite.charts.then((chartModule: any) => {
                // Top level components
                global.AreaChart = chartModule.AreaChart;
                global.BarChart = chartModule.BarChart;
                global.LineChart = chartModule.LineChart;
                global.ComposedChart = chartModule.ComposedChart;
                global.PieChart = chartModule.PieChart;
                global.RadarChart = chartModule.RadarChart;
                global.RadialBarChart = chartModule.RadialBarChart;
                global.ScatterChart = chartModule.ScatterChart;
                global.Treemap = chartModule.Treemap;

                global.ResponsiveContainer = chartModule.ResponsiveContainer;
                global.Legend = chartModule.Legend;
                global.ChartTooltip = chartModule.Tooltip;
                global.Cell = chartModule.Cell;
                global.ChartText = chartModule.Text;
                global.ChartLabel = chartModule.Label;
                global.ChartLabelList = chartModule.LabelList;

                global.Area = chartModule.Area;
                global.Bar = chartModule.Bar;
                global.Line = chartModule.Line;
                global.Scatter = chartModule.Scatter;
                global.XAxis = chartModule.XAxis;
                global.YAxis = chartModule.YAxis;
                global.ZAxis = chartModule.ZAxis;

                global.Brush = chartModule.Brush;
                global.CartesianAxis = chartModule.CartesianAxis;
                global.CartesianGrid = chartModule.CartesianGrid;
                global.ReferenceLine = chartModule.ReferenceLine;
                global.ReferenceDot = chartModule.ReferenceDot;
                global.ReferenceArea = chartModule.ReferenceArea;
                global.ErrorBar = chartModule.ErrorBar;

                global.Pie = chartModule.Pie;
                global.Radar = chartModule.Radar;
                global.RadialBar = chartModule.RadialBar;
                global.PolarAngleAxis = chartModule.PolarAngleAxis;
                global.PolarGrid = chartModule.PolarGrid;
                global.PolarRadiusAxis = chartModule.PolarRadiusAxis;

                global.Cross = chartModule.Cross;
                global.Curve = chartModule.Curve;
                global.Dot = chartModule.Dot;
                global.Polygon = chartModule.Polygon;
                global.Rectangle = chartModule.Rectangle;
                global.Sector = chartModule.Sector;

                this.setState({ loaded: chartModule[component] });
            });

            this.state = { loaded: undefined };
        }

        public render() {
            if (!this.state.loaded) {
                return null;
            } else {
                return React.createElement(this.state.loaded, { ...this.props });
            }
        }
    }
}

const Dropzone = lazyLoadFactory("dropzone");

const AreaChart = lazyChartFactory("AreaChart");
const BarChart = lazyChartFactory("BarChart");
const LineChart = lazyChartFactory("LineChart");
const ComposedChart = lazyChartFactory("ComposedChart");
const PieChart = lazyChartFactory("PieChart");
const RadialChart = lazyChartFactory("RadialChart");
const RadialBarChart = lazyChartFactory("RadialBarChart");
const ScatterChart = lazyChartFactory("ScatterChart");
const Treemap = lazyChartFactory("Treemap");

const ResponsiveContainer = lazyChartFactory("ResponsiveContainer");
const Legend = () => null;
const ChartTooltip = () => null;
const Cell = () => null;
const ChartText = () => null;
const ChartLabel = () => null;
const ChartLabelList = () => null;

const Area = () => null;
const Bar = () => null;
const Line = () => null;
const Scatter = () => null;
const XAxis = () => null;
const YAxis = () => null;
const ZAxis = () => null;

const Brush = () => null;
const CartesianAxis = () => null;
const CartesianGrid = () => null;
const ReferenceLine = () => null;
const ReferenceDot = () => null;
const ReferenceArea = () => null;
const ErrorBar = () => null;

const Pie = () => null;
const Radar = () => null;
const RadialBar = () => null;
const PolarAngleAxis = () => null;
const PolarGrid = () => null;
const PolarRadiusAxis = () => null;

const Cross = () => null;
const Curve = () => null;
const Dot = () => null;
const Polygon = () => null;
const Rectangle = () => null;
const Sector = () => null;

const rechartAPI = "http://recharts.org/#/en-US/api/";

interface ReactComponent {
    name: string;
    object: any;
    children: boolean;
    documentation?: string;
    props: Array<string | ReactComponentProp>;
    optionalProps?: Array<string | ReactComponentProp>;
}

interface ReactComponentProp {
    name: string;
    documentation?: string;
    /** Doesn't actually mean boolean, only that we shouldn't add ="" to the result. Do not use on properties that are likely to be dynamic */
    boolean?: boolean;
}

const observableDoc = "Observable object to listen change values of";
const valueDoc = "String value to get from observable";

export const reactComponents: ReactComponent[] = [{
    name: "ActionBar",
    object: ActionBar,
    children: true,
    props: [],
    optionalProps: [{
        name: "title"
    }, {
        name: "subtitle"
    }, {
        name: "sidebar",
        boolean: true,
        documentation: "Transforms the back arrow into a hamburger open-sidebar button"
    }, {
        name: "hasTabBar",
        boolean: true,
        documentation: "Tells the ActionBar to resize to fit a tab bar, this does not add the ActionTabs!",
    }, {
        name: "onBackPressed",
        documentation: "Callback when user clicks on the back arrow or open sidebar menu (if sidebar property is true)",
    }, {
        name: "isLoading",
        documentation: "Creates an indeterminate loading bar right underneath the ActionBar",
    }, {
        name: "smartScroll",
        documentation: "Makes actionbar hide when scrolling down, but appear as soon as user scrolls up",
        boolean: true,
    }],
    documentation: "A navigation pane that appears at the top of the page",
}, {
    name: "ActionItem",
    object: ActionItem,
    children: false,
    props: [{
        name: "title",
        documentation: "Label of the icon when it appears in a menu, or as a tooltip when it appears as an item"
    }, {
        name: "icon"
    }],
    optionalProps: [{
        name: "showAsIcon",
        boolean: true,
        documentation: "Controls when the item should be displayed as icon or in menu. Accepted values are 'ifRoom', 'never', 'always'",
    }, {
        name: "onClick",
        documentation: "Callback when user clicks this button or menu item, does not work if 'menu' prop is present",
    }, {
        name: "menu",
        documentation: "JSON structure matching <Menu> component to create a dropdown menu, disables the onClick button",
    }],
    documentation: "Creates a button icon inside ActionBar that users can click (or creates a dropdown menu on small screens)",
},
{ name: "ActionTab", object: ActionTab, children: false, props: ["title", "icon"] },
{ name: "ActionTabs", object: ActionTabs, children: true, props: [] },
{
    name: "Button",
    object: Button,
    children: false,
    props: ["title", "icon"],
    optionalProps: [{
        name: "fill",
        documentation: "Fill the width of the container button is in",
        boolean: true,
    }, {
        name: "flat",
        documentation: "Remove shadows and background colour",
        boolean: true,
    }, {
        name: "coloured",
        documentation: "Changes the button colour, colour can be chosen changed by adding a className or using a <Theme> component",
        boolean: true,
    }, {
        name: "onClick",
        documentation: "Callback when user presses button",
    }],
},
{ name: "ButtonGroup", object: ButtonGroup, children: true, props: [] },
{ name: "ButtonItem", object: ButtonItem, children: false, props: ["title"] },
{ name: "Card", object: Card, children: true, props: [] },
{ name: "CardAction", object: CardAction, children: false, props: ["icon", "onClick"] },
{ name: "CardBottom", object: CardBottom, children: true, props: [] },
{ name: "CardContent", object: CardContent, children: true, props: [] },
{ name: "CardHeader", object: CardHeader, children: true, props: ["title"] },
{ name: "CardTab", object: CardTab, children: true, props: ["title"] },
{ name: "CardTabs", object: CardTabs, children: true, props: [] },
{ name: "Checkbox", object: Checkbox, children: false, props: ["onChange", "value", "label"] },
{ name: "CircleImage", object: CircleImage, children: false, props: ["size", "src"], help: "circleimage" },
{ name: "DatePicker", object: DatePicker, children: false, props: ["displayFormat", "editFormat"] },
{ name: "Device", object: Device, children: true, props: ["device"] },
{ name: "Dropzone", object: Dropzone, children: false, props: ["config", "eventHandlers", "djsConfig"] },
{ name: "FloatingActionButton", object: FloatingActionButton, children: false, props: ["onClick"] },
{ name: "Format", object: Format, children: false, props: ["value", "format", "className", "zeroFormat", "nullFormat"] },
{ name: "Icon", object: Icon, children: false, props: ["icon"] },
{ name: "Input", object: Input, children: false, props: ["onChange", "value", "label"] },
{ name: "List", object: List, children: true, props: ["onLoadMore"] },
{ name: "ListItem", object: ListItem, children: true, props: ["title", "subtitle"] },
{ name: "Markdown", object: Markdown, children: true, props: [] },
{ name: "Menu", object: Menu, children: true, props: ["target"] },
{ name: "MenuHeader", object: MenuHeader, children: false, props: ["title"] },
{ name: "MenuItem", object: MenuItem, children: true, props: ["title"], },
{ name: "MultiPage", object: MultiPage, children: true, props: ["page"], },
{ name: "Page", object: Page, children: true, props: ["title", "type"], },
{ name: "PageController", object: PageController, children: true, props: [], },
{ name: "PageRoute", object: PageRoute, children: true, props: ["route", "component"], },
{ name: "Perspective", object: Perspective, children: true, props: [], },
{ name: "PortalImage", object: PortalImage, children: false, props: ["width", "height", "maxOffset", "image"], },
{ name: "PullToRefresh", object: PullToRefresh, children: false, props: ["onRefresh"], },
{ name: "Radio", object: Radio, children: false, props: ["label", "value"], },
{
    name: "RadioGroup",
    object: RadioGroup,
    children: true,
    props: [{
        name: "observable",
        documentation: observableDoc,
    }, {
        name: "value",
        documentation: valueDoc,
    }],
    optionalProps: [{
        name: "name",
        documentation: "Name used if this RadioGroup is used in a HTML form"
    }],
    documentation: "Group Manager for a series of <Radio/> components",
},
{ name: "Range", object: Range, children: false, props: ["observable", "value", "min", "max"], },
{ name: "Select", object: Select, children: false, props: ["value", "options"], },
{ name: "Sidebar", object: Sidebar, children: true, props: ["open", "onBackPressed"], },
{ name: "SmartGrid", object: SmartGrid, children: false, props: ["rt-props"], },
{ name: "StarRating", object: StarRating, children: false, props: ["rating"], },
{ name: "SubmitLoader", object: SubmitLoader, children: false, props: ["onSubmit"] },
{ name: "Switch", object: Switch, children: false, props: ["onChange", "value", "label"] },
{ name: "TextArea", object: TextArea, children: false, props: ["value", "onChange"] },
{
    name: "Theme",
    object: Theme,
    children: true,
    props: [{
        name: "theme",
        documentation: "The base theme colour, can be one of Material Design colour presets or a custom CSS class name",
    }],
    documentation: "Sets the theme colour for certain child components"
},
{ name: "Tooltip", object: Tooltip, children: true, props: ["target"] }, {
    name: "NoteManager",
    object: NoteManager,
    children: true,
    props: ["channel"],
    optionalProps: [{
        name: "route",
        documentation: "Custom route to load notes from",
    }, {
        name: "submitRoute",
        documentation: "Custom route to post notes too",
    }, {
        name: "email",
        documentation: "Submits notes as an email instead of automatically based on user ID",
    }, {
        name: "userLookup",
        documentation: "URL to fetch to get user ID, if function it will instead use the return value as user id",
    }, {
        name: "data",
        documentation: "Custom filtering mechanism when channel is not provided"
    }],
    documentation: "Manages notes for display by Note components"
}, {
    name: "Note",
    object: Note,
    children: false,
    props: [],
    optionalProps: [{
        name: "filter",
        documentation: "Filter data to match on, notes submitted here will also have that filter data attached to them",
    }, {
        name: "name",
        documentation: "Name for this note area, if <Note> does not have a name and a note message does, it will be displayed",
    }, {
        name: "limit",
        documentation: "Limit number of notes that can be created while previous notes are still unresolved",
    }, {
        name: "hideResolve",
        documentation: "Hide notes that have already been resolved",
    }, {
        name: "canCreate",
        documentation: "Completely disables creating notes or replies if false"
    }, {
        name: "showHeader",
        documentation: "Disables showing the header of this notes name if false",
    }, {
        name: "showName",
        documentation: "Disables showing names on notes if false",
    }, {
        name: "removeControls",
        documentation: "Removes controls (including resolving) if true"
    }],
    documentation: "Shows notes that are managed by a NoteManager"
},
/**
 * Lots of charting stuff, should move this into a seperately loaded module so it doesn't bloat gears_lite
 */
{ name: "AreaChart", object: AreaChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "AreaChart" },
{ name: "BarChart", object: BarChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "BarChart" },
{ name: "LineChart", object: LineChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "LineChart" },
{ name: "ComposedChart", object: ComposedChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "ComposedChart" },
{ name: "PieChart", object: PieChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "PieChart" },
{ name: "RadialChart", object: RadialChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "RadialChart" },
{ name: "RadialBarChart", object: RadialBarChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "RadialBarChart" },
{ name: "ScatterChart", object: ScatterChart, children: true, props: ["width", "height", "data"], help: rechartAPI + "ScatterChart" },
{ name: "Treemap", object: Treemap, children: true, props: ["width", "height", "data"], help: rechartAPI + "Treemap" },

{ name: "ResponsiveContainer", object: ResponsiveContainer, children: true, props: ["width", "height"], help: rechartAPI + "ResponsiveContainer" },
{ name: "Legend", object: Legend, children: false, props: [], help: rechartAPI + "Legend" },
{ name: "ChartTooltip", object: ChartTooltip, children: false, props: [], help: rechartAPI + "Tooltip" },
{ name: "Cell", object: Cell, children: false, props: [], help: rechartAPI + "Cell" },
{ name: "ChartText", object: ChartText, children: false, props: [], help: rechartAPI + "Text" },
{ name: "ChartLabel", object: ChartLabel, children: false, props: [], help: rechartAPI + "Label" },
{ name: "ChartLabelList", object: ChartLabelList, children: false, props: [], help: rechartAPI + "LabelList" },

{ name: "Area", object: Area, children: false, props: [], help: rechartAPI + "Area" },
{ name: "Bar", object: Bar, children: false, props: [], help: rechartAPI + "Bar" },
{ name: "Line", object: Line, children: false, props: [], help: rechartAPI + "Line" },
{ name: "Scatter", object: Scatter, children: false, props: [], help: rechartAPI + "Scatter" },
{ name: "XAxis", object: XAxis, children: false, props: [], help: rechartAPI + "XAxis" },
{ name: "YAxis", object: YAxis, children: false, props: [], help: rechartAPI + "YAxis" },
{ name: "ZAxis", object: ZAxis, children: false, props: [], help: rechartAPI + "ZAxis" },

{ name: "Brush", object: Brush, children: false, props: [], help: rechartAPI + "Brush" },
{ name: "CartesianAxis", object: CartesianAxis, children: false, props: [], help: rechartAPI + "CartesianAxis" },
{ name: "CartesianGrid", object: CartesianGrid, children: false, props: [], help: rechartAPI + "CartesianGrid" },
{ name: "ReferenceLine", object: ReferenceLine, children: false, props: [], help: rechartAPI + "ReferenceLine" },
{ name: "ReferenceDot", object: ReferenceDot, children: false, props: [], help: rechartAPI + "ReferenceDot" },
{ name: "ReferenceArea", object: ReferenceArea, children: false, props: [], help: rechartAPI + "ReferenceArea" },
{ name: "ErrorBar", object: ErrorBar, children: false, props: [], help: rechartAPI + "ErrorBar" },

{ name: "Pie", object: Pie, children: false, props: [], help: rechartAPI + "Pie" },
{ name: "Radar", object: Radar, children: false, props: [], help: rechartAPI + "Radar" },
{ name: "RadialBar", object: RadialBar, children: false, props: [], help: rechartAPI + "RadialBar" },
{ name: "PolarAngleAxis", object: PolarAngleAxis, children: false, props: [], help: rechartAPI + "PolarAngleAxis" },
{ name: "PolarGrid", object: PolarGrid, children: false, props: [], help: rechartAPI + "PolarGrid" },
{ name: "PolarRadiusAxis", object: PolarRadiusAxis, children: false, props: [], help: rechartAPI + "PolarRadiusAxis" },

{ name: "Cross", object: Cross, children: false, props: [], help: rechartAPI + "Cross" },
{ name: "Curve", object: Curve, children: false, props: [], help: rechartAPI + "Curve" },
{ name: "Dot", object: Dot, children: false, props: [], help: rechartAPI + "Dot" },
{ name: "Polygon", object: Polygon, children: false, props: [], help: rechartAPI + "Polygon" },
{ name: "Rectangle", object: Rectangle, children: false, props: [], help: rechartAPI + "Rectangle" },
{ name: "Sector", object: Sector, children: false, props: [], help: rechartAPI + "Sector" },

{
    name: "ErrorDisplayComponent",
    object: ErrorDisplayComponent,
    children: true,
    props: [],
    documentation: "Wraps child components so that errors do not crash the entire page.",
}];

// Components that still need to be in global scope, but should not appear in template builder
export const legacyComponents = {
    FormEntry,
    FormPage,
    FormSubmitted,
    MobXCheckbox,
    MobXInput,
    MobXSwitch,
    MobXTextArea,
    MobXTextarea: MobXTextArea,
    Textarea: TextArea,
    DataGrid,
};

export const htmlComponents: string[] = [
    "button",
    "div",
    "fieldset",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "input",
    "label",
    "legend",
    "li",
    "p",
    "span",
    "table",
    "tbody",
    "td",
    "textbox",
    "th",
    "thead",
    "tr",
    "ul",
];

global.reactComponents = reactComponents;

Object.assign(global, modules, legacyComponents);
reactComponents.map((item: any) => {
    global[item.name] = item.object;
});
