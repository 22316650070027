import React from "react";
import ReactDOM from "react-dom";
import { observable } from "mobx";
import MBIECreateAccount from "./MBIECreateAccount";
import $ from "jquery";

var mobxdata = observable({
    emails: [],
});
function loadList(list) {
    var items = list.results;
    items.forEach((item) => {
        mobxdata.emails.push({ value: item.text, label: item.text });
    });
}

window.createMBIEAccountPage = function() {
    $.get({
        url: "/mbie_domain_lists/lookup",
        success: loadList,
    });
    ReactDOM.render(
        <MBIECreateAccount emails={mobxdata}/>
        , $("#mbie")[0],
    );
};

$(document).ready(() => {
    if ($("#mbie")[0] && module.hot){
        window.createMBIEAccountPage();
    }
});
