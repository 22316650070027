import { SidebarGroupConfig, SidebarItemConfig, LayoutConfig, LayoutConfigChild } from "./ConfigDialog";
import { titleize, underscore } from "inflection";
import { find } from "lodash";

class LayoutBuilder {
    private name?: string;
    private items: LayoutConfigChild[] = [];

    constructor(name?: string) {
        this.name = name;
    }

    public addField(object: LayoutConfigChild) {
        // Add a title if none was given, we use undefined so we can forcefully remove titles with empty string
        if (object.title === undefined) {
            object.title = titleize(underscore(object.field));
        }
        this.items.push(object);
        return this;
    }

    public build() {
        return {
            name: this.name,
            children: this.items,
        };
    }
}

class SidebarItemBuilder {
    private name: string;
    private layouts: LayoutBuilder[] = [];

    constructor(name: string) {
        this.name = name;
    }

    public addFieldGroup(name?: string) {
        const newGroup = new LayoutBuilder(name);
        this.layouts.push(newGroup);
        return newGroup;
    }

    /**
     * Skip past adding field group and add item directly
     */
    public addField(object: LayoutConfigChild) {
        let existingGroup = find(this.layouts, { name: undefined });
        if (!existingGroup) {
            existingGroup = this.addFieldGroup();
        }
        return existingGroup.addField(object);
    }

    public build() {
        return {
            name: this.name,
            config: this.layouts.map((group) => group.build()),
        };
    }
}

class SidebarGroupBuilder {
    private name?: string;
    private sidebarItems: SidebarItemBuilder[] = [];

    constructor(name?: string) {
        this.name = name;
    }

    public addSidebarItem(name: string) {
        const newItem = new SidebarItemBuilder(name);
        this.sidebarItems.push(newItem);
        return newItem;
    }

    public build() {
        return {
            name: this.name,
            children: this.sidebarItems.map((item) => item.build())
        };
    }
}

export default class ConfigDialogBuilder {
    private sidebarGroups: SidebarGroupBuilder[] = [];

    /**
     * Adds a group to the sidebar
     */
    public addSidebarGroup(name?: string) {
        const newGroup = new SidebarGroupBuilder(name);
        this.sidebarGroups.push(newGroup);
        return newGroup;
    }

    /**
     * Skip past adding sidebar group and add item directly
     */
    public addSidebarItem(name: string) {
        let existingGroup = find(this.sidebarGroups, { name: undefined });
        if (!existingGroup) {
            existingGroup = this.addSidebarGroup();
        }
        return existingGroup.addSidebarItem(name);
    }

    /**
     *  Verify that all the config fields actually exist on the data
     *  NOT IMPLEMENTED
     */
    private verify() {
    }

    public build(): SidebarGroupConfig[] {
        return this.sidebarGroups.map((group) => group.build());
    }
}
