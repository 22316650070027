import "./Radio.scss";

import * as Class from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";

import { observer } from "mobx-react";
import { bind } from 'decko';

const defaultProps = {
    enabled: true,
};

const contextTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

interface IRadioProps {
    className?: string;
    disabled?: boolean;
    enabled?: boolean;
    label: string;
    value: string;
}

interface IRadioContext {
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
}

/**
 * A react style radio that is fully customizable from CSS
 * This component must be somewhere inside a RadioGroup in order to function
 */
export default class Radio extends React.Component<IRadioProps, never> {
    public static defaultProps = defaultProps;
    public static contextTypes = contextTypes;
    public context: IRadioContext;

    @bind
    private onChange() {
        this.context.onChange(this.props.value);
    }

    @bind
    private onKeyPress(e: React.KeyboardEvent<HTMLElement>) {
        if (e.key === "Enter") {
            this.onChange();
        }
    }

    public render() {
        const { value, enabled, disabled, label, className, ...other } = this.props;
        const realDisabled = this.context.disabled || disabled || !enabled;
        const classNames = Class("radio", className, { active: this.context.value === value, disabled: realDisabled });
        return (
            <span tabIndex={0} onKeyPress={this.onKeyPress} className={classNames} onClick={realDisabled ? undefined : this.onChange} {...other}>
                <div />
                {label ?
                    (<span className="label">{label}</span>)
                    : null}
            </span>
        );
    }
}
