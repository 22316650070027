import * as Promise from "bluebird";
import * as Class from "classnames";
import * as React from "react";
import styles from "./PullToRefresh.scss";

import Icon from "./Icon";

interface IPullToRefreshProps {
    onRefresh: () => Promise<any>;
    className?: string;
    style?: any;
}

interface IPullToRefreshState {
    cancelled: boolean;
    finishing: boolean;
    refreshing: boolean;
    dragging: boolean;
    startRefreshY: number;
    currentRefreshY: number;
}

export default class PullToRefresh extends React.Component<IPullToRefreshProps, IPullToRefreshState> {
    private requiredDragDistance: number = window.innerHeight / 4;
    private currentRefreshY: number = 0;
    private refreshRef: HTMLDivElement;
    private iconRef: HTMLDivElement;

    constructor(props: IPullToRefreshProps) {
        super(props);

        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);

        this.state = { cancelled: false, finishing: false, refreshing: false, dragging: false, startRefreshY: 0, currentRefreshY: 0 };
    }

    public handleTouchStart(e) {
        if (!this.state.refreshing && e.touches.length === 1 && window.scrollY === 0 && e.touches[0].clientY < window.innerHeight / 2) {
            this.setState({
                cancelled: false,
                dragging: true,
                startRefreshY: e.touches[0].clientY,
                currentRefreshY: e.touches[0].clientY,
            });
            return false;
        }
    }

    public handleTouchMove(e) {
        if (this.state.dragging) {
            if (e.touches[0].clientY < this.state.startRefreshY) {
                this.setState({ dragging: false });
            } else {
                e.preventDefault();
                this.setState({ currentRefreshY: e.touches[0].clientY });
                return false;
            }
        }
    }

    public handleTouchEnd(e) {
        this.setState({ dragging: false });
        const distance = this.state.currentRefreshY - this.state.startRefreshY;
        if (distance > this.requiredDragDistance) {
            this.setState({ refreshing: true });
            this.props.onRefresh()
                .then(() => {
                    this.setState({ refreshing: false, finishing: true });
                    setTimeout(() => {
                        this.setState({ finishing: false, startRefreshY: 0, currentRefreshY: 0 });
                    }, 600);
                });
        } else {
            this.setState({ cancelled: true });
        }
    }

    public componentDidMount() {
        window.addEventListener("touchstart", this.handleTouchStart, { passive: false });
        window.addEventListener("touchmove", this.handleTouchMove, { passive: false });
        window.addEventListener("touchend", this.handleTouchEnd);
    }

    public componentWillUnmount() {
        window.removeEventListener("touchstart", this.handleTouchStart, { passive: false });
        window.removeEventListener("touchmove", this.handleTouchMove, { passive: false });
        window.removeEventListener("touchend", this.handleTouchEnd);
    }

    public render() {
        const { onRefresh, className, style, ...other } = this.props;
        const distance = this.state.currentRefreshY - this.state.startRefreshY;
        if (!distance) {
            return null;
        }
        //this.state.refreshing && !this.state.dragging && !this.state.finishing
        let adjustedDistance = Math.min(distance / this.requiredDragDistance, 1);
        adjustedDistance = adjustedDistance * (2 - adjustedDistance);
        const rotation = Math.min(distance / this.requiredDragDistance, 1);
        const adjustedRotation = (rotation * (2 - rotation)) * 270;
        return (
            <div
                ref={(ref) => this.refreshRef = ref}
                className={Class(styles.container, "pull-to-refresh", className)}
                style={{
                    transition: this.state.cancelled ? "top 400ms" : undefined,
                    top: this.state.cancelled ? 0 : adjustedDistance * 128,
                    animation: this.state.finishing ? "pull-refresh-fade 400ms" : undefined,
                    opacity: this.state.finishing ? "0" : undefined,
                    ...style,
                }}>
                <Icon
                    style={{
                        animation: (this.state.refreshing || this.state.finishing) && "pull-refresh-rotate 400ms infinite linear",
                        color: (distance > this.requiredDragDistance) ? "#1E88E5" : "#BDBDBD",
                        transform: `rotate(${adjustedRotation}deg)`,
                    }}
                    icon="fa-sync-alt" />
            </div>
        );
    }
}
