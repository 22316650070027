import * as classnames from "classnames";
import * as numeral from "numeral";
import * as React from "react";

interface IFormatProps {
    value: number;
    format?: string;
    className?: string;
    zeroFormat?: string;
    nullFormat?: string;
}

export default class Format extends React.Component<IFormatProps, never> {
    public render() {
        const {className, value, format, zeroFormat, nullFormat} = this.props;
        const number = numeral(value);
        let formatted: string;
        if (zeroFormat != null && number.value() === 0) {
            formatted = zeroFormat as string;
        } else if (nullFormat != null && number.value() === null) {
            formatted = nullFormat as string;
        } else {
            formatted = number.format(format);
        }
        return <span className={classnames("formatted-number", className)}>{formatted}</span>;
    }
}
