import "./ButtonItem.scss";

import * as Class from "classnames";
import * as React from "react";

import Icon from "../Icon";

const defaultProps = {
    iconAlign: "left",
};

interface IButtonItemProps extends React.HTMLProps<HTMLButtonElement> {
    title?: string;
    icon?: string;
    iconAlign?: "left" | "right";
}

/**
 * A single button in a buttongroup, it's styling is effected by it's neighbors
 */
export default class ButtonItem extends React.Component<IButtonItemProps, never> {
    public static defaultProps = defaultProps;
    /**
     * @param {object} props
     * @param {string} [props.title] - Title of the button
     * @param {string} [props.icon] - Icon in the button
     * @param {string} [props.iconAlign] - 'left' or 'right' aligns which side the icon appears on
     */
    constructor(props: IButtonItemProps) {
        super(props);
    }

    public render() {
        const { title, icon, iconAlign, className, children, ...other } = this.props;
        const iconOnly = !title;
        return (
            <button className={Class("button-item", "coloured", className)} {...other}>
                {icon && iconAlign === "left" ?
                    <Icon className={Class("left", { iconOnly })} icon={icon} small fixedWidth />
                    : null}
                {title ? title : null}
                {icon && iconAlign === "right" ?
                    <Icon className={Class("right", { iconOnly })} icon={icon} small fixedWidth />
                    : null}
            </button>
        );
    }
}
