import * as Class from "classnames";
import { bind } from "decko";
import { action, IObservableObject, IObservableValue, isObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import * as styles from "./Switch.scss";

interface IBooleanObservable {
    [key: string]: boolean;
}

interface IBaseSwitchProps {
    className?: string;
    disabled?: boolean;
    enabled?: boolean;
    label?: string;
    name?: string;
    onChange?: (state: boolean) => void;
}

interface IStandardSwitchProps extends IBaseSwitchProps {
    observable?: never;
    value: boolean | IObservableValue<boolean>;
}

interface IObservableSwitchProps extends IBaseSwitchProps {
    observable: any;
    value: string;
}

type SwitchProps = IStandardSwitchProps | IObservableSwitchProps;

interface ISwitchState {
    focused: boolean;
    ripple: boolean;
}

function hasObservable(arg: any): arg is IObservableSwitchProps {
    return arg.observable !== undefined;
}

/**
 * A react style checkbox / switch that is fully customizable from CSS
 * The actual checkbox is hidden and laid over the top
 */
@observer
export default class Switch extends React.Component<SwitchProps, ISwitchState> {
    public static defaultProps: Partial<SwitchProps> = { enabled: true };

    constructor(props: SwitchProps) {
        super(props);

        this.state = { focused: false, ripple: false };
    }

    @bind
    @action
    private onClick() {
        if (hasObservable(this.props)) {
            this.props.observable[this.props.value] = !this.props.observable[this.props.value];
            if (this.props.onChange) {
                this.props.onChange(this.props.observable[this.props.value]);
            }
            return;
        }
        if (isObservable(this.props.value)) {
            const value = this.props.value as IObservableValue<boolean>;
            value.set(!value.get());
            if (this.props.onChange) {
                this.props.onChange(value.get());
            }
        }
        if (this.props.onChange) {
            this.props.onChange(!this.props.value);
        }
    }

    @bind
    private onBlur() {
        this.setState({ focused: false });
    }

    @bind
    private onFocus() {
        this.setState({ focused: true });
    }

    @bind
    private ripple() {
        this.setState({ ripple: true });
        setTimeout(this.resetRipple, 800);
    }

    @bind
    private resetRipple() {
        this.setState({ ripple: false });
    }

    public render() {
        const { name, value, observable, onChange, enabled, disabled, className, label, ...other } = this.props;
        const realDisabled = disabled || !enabled;

        let realValue = value;
        if (hasObservable(this.props)) {
            realValue = this.props.observable[this.props.value];
        } else {
            if (isObservable(value)) {
                realValue = (value as IObservableValue<boolean>).get();
            }
        }
        realValue = realValue as boolean;
        return (
            <span className={Class("switch", styles.switch, { disabled: realDisabled, [styles.disabled]: realDisabled, focused: this.state.focused, [styles.focused]: this.state.focused }, className)} {...other}>
                <div className={Class(className, { active: realValue, [styles.active]: realValue })}>
                    <div className={Class("back", styles.back)} />
                    <div className={Class("front", styles.front)}>
                        <div className={Class({ ripple: this.state.ripple })} />
                    </div>
                </div>
                {label ?
                    (<span className={Class("label", styles.label)}>{label}</span>)
                    : null}
                <input
                    type="checkbox"
                    name={name}
                    aria-label={label}
                    disabled={realDisabled}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onMouseDown={realDisabled ? undefined : this.ripple}
                    onClick={realDisabled ? undefined : this.onClick}
                    value={realValue ? 1 : 0} />
            </span>
        );
    }
}

export { Switch as MobXSwitch };
