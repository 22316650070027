import * as Class from "classnames";
import * as React from "react";
import * as styles from "./List.scss";
import * as $ from "jquery";

type HeightFunction = (index: number) => number;

interface IListProps extends React.HTMLProps<HTMLUListElement> {
    itemHeight?: number | number[] | HeightFunction;
    onLoadMore: () => {};
}

interface IListState {
    offset: number;
}

function childrenAsArray(children: React.ReactNode): React.ReactNode[] {
    if (Array.isArray(children)) {
        return children;
    } else {
        return [children];
    }
}

const defaultRowHeight = 76; // Definitely upgrade this in future to something more dynamic

export default class List extends React.Component<IListProps, IListState> {
    private listRef: HTMLUListElement;
    private canLoadMore: boolean = true;

    constructor(props: IListProps) {
        super(props);

        this.onScroll = this.onScroll.bind(this);

        this.state = { offset: 0 };
    }

    public componentDidMount() {
        $(window).on("scroll", this.onScroll);
    }

    public componentWillUnmount() {
        $(window).off("scroll", this.onScroll);
    }

    private onScroll() {
        const rect = this.listRef.getBoundingClientRect();
        const rowHeight = this.props.itemHeight || defaultRowHeight;
        if (Math.abs(window.pageYOffset - this.state.offset - rect.top) >= rowHeight) {
            this.setState({ offset: window.pageYOffset });
        }
        const childrenArray = childrenAsArray(this.props.children);
        if (window.pageYOffset - this.getTotalHeight() + window.innerHeight > -rowHeight) {
            if (this.canLoadMore && this.props.onLoadMore) {
                this.canLoadMore = false;
                this.props.onLoadMore();
            }
        }
    }

    public componentWillUpdate(nextProps: IListProps, nextState: IListState) {
        const childrenArray = childrenAsArray(this.props.children);
        const nextChildrenArray = childrenAsArray(nextProps.children);
        if (nextChildrenArray.length !== childrenArray.length) {
            this.canLoadMore = true;
        }
    }

    private getTotalHeight() {
        const rowHeight = this.props.itemHeight || defaultRowHeight;
        if (typeof rowHeight === "number") {
            const childrenArray = childrenAsArray(this.props.children);
            return childrenArray.length * rowHeight;
        } else {
            if (typeof rowHeight === "function") {
                let total = 0;
                const childrenArray = childrenAsArray(this.props.children);
                childrenArray.forEach((val, index) => {
                    total += rowHeight(index);
                });
                return total;
            } else {
                return rowHeight.reduce((val, acc) => acc + val, 0);
            }
        }
    }

    public render() {
        const { itemHeight, onLoadMore, children, ...other } = this.props;
        const childrenArray = childrenAsArray(children);
        if (childrenArray) {
            const rowHeight = (itemHeight || defaultRowHeight) as number; // Temporarily just accept a number
            const height = window.innerHeight;
            const canFit = height / rowHeight;
            const first = Math.floor(this.state.offset / rowHeight);
            const startBufferHeight = Math.max(0, Math.min((first - canFit) * rowHeight, this.getTotalHeight()));
            const endBufferHeight = Math.max(0, (childrenArray.length - (first + canFit * 2)) * rowHeight);
            return (
                <ul
                    ref={(ref) => this.listRef = ref}
                    className={Class(styles.list, "list")}
                    {...other}>
                    <li style={{ height: startBufferHeight }} />
                    {childrenArray.map((child, index) => {
                        if (index >= first - canFit && index < first + canFit * 2) {
                            return child;
                        }
                    })}
                    <li style={{ height: endBufferHeight }} />
                </ul>
            );
        } else {
            return null;
        }
    }
}
