declare const global: any;

if (!console.table) {
    console.table = console.log;
}

import disableBackspace from "./gears/helpers/disableBackspace";
disableBackspace();

global.$ = require("jquery");
global.jQuery = global.$;
require("../vendor/assets/javascripts/url.min");

import tryCatch from "./gears/helpers/tryCatch";
global.tryCatch = tryCatch;
import lite from "./modules/LiteLoader";
global.lite = lite;

global.Promise = global.Bluebird;

import { ActionCable, default as cable } from "./gears/actioncable";
global.cable = cable;
global.ActionCable = ActionCable;

import * as calculation from "./calculation";
global.calculation = calculation;
// require("./react-components/ReactPage");
import StandalonePage from "./react-components/template_builder/StandalonePage";
global.StandalonePage = StandalonePage;
require("./react-components/mock/MBIEInitiliazer");
require("./react-components/PortalPage");

require("../vendor/assets/javascripts/bootstrap.min");

// Live Reloading
if ((module as any).hot) {
    (module as any).hot.accept();
}
