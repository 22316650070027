import * as marked from "marked";
import * as React from "react";
import * as Class from "classnames";
import styles from "./Markdown.scss";

interface IMarkdownProps extends React.HTMLProps<HTMLSpanElement> {
    children: any;
}

marked.setOptions({
    breaks: true,
    smartypants: true,
});

export default class Markdown extends React.Component<IMarkdownProps, never> {
    private createMarkup(value: string) {
        return { __html: marked(value) };
    }

    public render() {
        const { children, className, ...other } = this.props;
        let trimmedChildren = children || "";
        if(Array.isArray(trimmedChildren)){
            trimmedChildren = trimmedChildren.join();
        }
        trimmedChildren = trimmedChildren.trim();
        return <span className={Class(className, styles.markdown)} dangerouslySetInnerHTML={this.createMarkup(trimmedChildren)} {...other} />;
    }
}
