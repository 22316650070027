import React from "react";
import ReactDOM from "react-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, CardBottom } from "commonui/Card";
import MBIECreated from "./MBIECreated";
import Select from "react-select";
import $ from "jquery";
import "./MBIEStyle.scss";

const invalidChars = /[\@\+\-\\\/\?\#\$\%\^\&\*\(\)\[\]\{\}\<\>\:\;\"\'\~\`\_\=]/;

var data = observable({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
});

const rules = {
    "firstname": "required|string",
    "lastname": "required|string",
    "email": "required|email|string",
    "password": "required|between:8,25",
};

/**
 * @access private
 */
@observer
class MBIECreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeEmail = this.changeEmail.bind(this);

        this.colour = null; //$("#mbie").attr("data-colour");
        this.state = { errorMessage: "", showError: false, showLogin: false, submitted: false, email: "", submitDisabled: false };
    }

    changeEmail(event) {
        this.setState({ email: event });
    }

    fullEmail = () => {
        const email = this.emailRef.value;
        if (email.includes("@")) {
            return email;
        } else {
            return `${email}${this.state.email.value}`;
        }
    }

    onSubmit(event) {
        event.preventDefault();
        //this.validator = new Validator(data, rules);
        //this.validator.passes();
        if (this.firstnameRef.value == "") {
            this.setState({ errorMessage: "You must enter a first name", showError: true, showLogin: false });
            return true;
        }
        // if (invalidChars.test(this.firstnameRef.value)) {
        //     this.setState({ errorMessage: "First name contains invalid characters", showError: true });
        //     return false;
        // }
        if (this.surnameRef.value == "") {
            this.setState({ errorMessage: "You must enter a surname", showError: true, showLogin: false });
            return false;
        }
        // if (invalidChars.test(this.surnameRef.value)) {
        //     this.setState({ errorMessage: "Surname contains invalid characters", showError: true });
        //     return false;
        // }
        if (this.emailRef.value == "") {
            this.setState({ errorMessage: "You must enter a email", showError: true, showLogin: false });
            return false;
        }
        if (this.emailRef.value.match(/[<>()\[\]\\,;:\s"]/)) {
            this.setState({ errorMessage: "Email must not contain invalid characters", showError: true, showLogin: false });
            return false;
        }
        if (this.passwordRef.value.length < 6) {
            this.setState({ errorMessage: "Password must be longer than 5 characters", showError: true, showLogin: false });
            return false;
        }
        var email;
        if (this.emailRef.value.includes("@")) {
            email = this.emailRef.value;
            var parts = this.emailRef.value.split("@");
            var domain = "@" + parts[1];
            var valid = false;
            try {
                for (var i = 0; i < this.props.emails.emails.length; i++) {
                    var item = this.props.emails.emails[i];
                    if (item.label === domain) {
                        valid = true;
                    }
                }
                if (!valid) {
                    this.setState({ errorMessage: "Email must contain a valid domain", showError: true, showLogin: false });
                    return false;
                }
            } catch (e) {
                console.log("Disabled email debugging", e);
            }
        } else {
            email = `${this.emailRef.value}${this.state.email.value}`;
            if (!email.includes("@")) {
                this.setState({ errorMessage: "Email must contain a domain", showError: true, showLogin: false });
                return false;
            }
        }
        const fullName = `${this.firstnameRef.value} ${this.surnameRef.value}`;

        this.setState({ submitDisabled: true });
        $.post({
            url: "/invite_mbie",
            dataType: "json",
            data: {
                user: {
                    name: fullName,
                    email_address: email,
                    password: this.passwordRef.value,
                    password_confirmation: this.passwordRef.value,
                },
            },
        }).then((data) => {
            console.log("Data returned", data);
            this.setState({ submitDisabled: false });
            if (data.error) {
                var showLogin = false;
                if (data.error.includes("Email Address has already been taken")) {
                    showLogin = true;
                }
                this.setState({ errorMessage: data.error, showError: true, showLogin: showLogin });
            } else {
                this.setState({ submitted: true });
            }
        }).fail((data) => {
            console.log("Data returned", data);
            this.setState({ submitDisabled: false });
            if (data.error) {
                var showLogin = false;
                if (data.error.includes("Email Address has already been taken")) {
                    showLogin = true;
                }
                this.setState({ errorMessage: data.error, showError: true, showLogin: showLogin });
            } else {
                this.setState({ submitted: true });
            }
        });
    }

    render() {
        // <CardHeader style={{ backgroundImage: "url(/ga_logo/mbie_logo.png)" }} />
        // <div className="image-holder" style={{ backgroundImage: "url(/ga/mbie_login.jpg)" }} />
        if (!this.state.submitted) {
            return (
                <div className="mbie-create-page">
                    <form onSubmit={this.onSubmit}>
                        <Card style={{ width: "800px", margin: "auto auto" }} colour={this.colour}>
                            <CardHeader title="Create Account" style={{ color: "black", backgroundColor: "rgba(0, 0, 0, 0.05)" }} />
                            <CardContent>
                                <input type="password" style={{ display: "none" }} />
                                <div className="content">
                                    <div className="megaflex">
                                        <div className="fb-6">
                                            <span className="input-label">First Name</span>
                                            <input type="text" ref={ref => this.firstnameRef = ref} onChange={val => data.firstname = val.currentTarget.value} />
                                            {this.validator && this.validator.errors.first("firstname")}
                                        </div>
                                        <div className="fb-6">
                                            <span className="input-label">Surname</span>
                                            <input type="text" ref={ref => this.surnameRef = ref} onChange={val => data.lastname = val.currentTarget.value} />
                                            {this.validator && this.validator.errors.first("lastname")}
                                        </div>
                                        <div className="fb-6">
                                            <span className="input-label">Email</span>
                                            <div className="email-container">
                                                <input type="text"
                                                    autoComplete="off"
                                                    ref={ref => this.emailRef = ref}
                                                    onChange={val => data.email = val.currentTarget.value}
                                                    />
                                                <span>
                                                    {false && this.props.emails.length &&
                                                        <Select
                                                            value={this.state.email}
                                                            options={this.props.emails.emails}
                                                            onChange={this.changeEmail}
                                                            clearable={false}
                                                            />
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="fb-6">
                                            <span className="input-label">Password</span>
                                            <input type="password"
                                                defaultValue=""
                                                ref={ref => this.passwordRef = ref}
                                                onChange={val => data.password = val.currentTarget.value}
                                                />
                                            {this.validator && this.validator.errors.first("password")}
                                        </div>
                                    </div>
                                    {this.state.errorMessage != "" ?
                                        <div className="error-container" dangerouslySetInnerHTML={{__html: this.state.errorMessage.replace("\n", "<br/>")}}></div>
                                        : null}
                                    {this.state.showLogin ?
                                        <a href="/mbie_login" style={{textDecoration: "none"}}>
                                            <button type="button" style={{ margin: "auto", display: "block" }}>Login as {data.email}</button>
                                        </a>
                                        : null}
                                    {/*<img className="captcha" style={{opacity:"0.5"}} src="/assets/fake_captcha.png" />*/}
                                    <br style={{ clear: "both" }} />
                                </div>
                            </CardContent>
                            <CardBottom>
                                <button type="submit" style={{ float: "right" }} onClick={this.onSubmit} disabled={this.state.submitDisabled}>Create</button>
                                <br style={{ clear: "both" }} />
                            </CardBottom>
                        </Card>
                    </form>
                </div>
            );
        } else {
            return <MBIECreated email={this.fullEmail()} />;
        }
    }
}

export default MBIECreateAccount;
window.createMBIEAccountPage = function() {
    ReactDOM.render(
        <MBIECreate />,
        $("#mbie")[0],
    );
};
