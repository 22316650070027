import * as ActionCable from "actioncable";

const cable: ActionCable.Cable = ActionCable.createConsumer();

export {ActionCable};
export default cable;

export enum ActionEvent {
    Update = "UPDATE",
    Create = "CREATE",
    Delete = "DELETE",
}

export interface ICableAction {
    event: ActionEvent;
    typed_id: string;
    object: any;
}
