exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2dC8-bL64u-fZmFxXaJbwS{opacity:0.5}\n", ""]);

// exports
exports.locals = {
	"disabled": "_2dC8-bL64u-fZmFxXaJbwS"
};