exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2oY6gKJ0UXA2z9taRsC_M0{background-color:#2B2B2B;padding:16px;color:rgba(255,255,255,0.87) !important}._2oY6gKJ0UXA2z9taRsC_M0 pre{font-family:monospace;font-size:1rem;line-height:1rem;background:rgba(0,0,0,0.25);padding:16px;color:rgba(255,255,255,0.87) !important}\n", ""]);

// exports
exports.locals = {
	"error": "_2oY6gKJ0UXA2z9taRsC_M0"
};