import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    children: PropTypes.any,
    header: PropTypes.string,
    text: PropTypes.string,
};

const contextTypes = {
    submitted: PropTypes.bool,
};

class FormSubmitted extends React.Component {
    render() {
        if (this.context.submitted) {
            return (
                <div className="submit-page">
                    <div className="submit-icon"><i className="fa fa-paper-plane" /></div>
                    <h1>{this.props.header}</h1>
                    <p>
                        {this.props.text}
                    </p>
                    {this.props.children}
                </div>
            );
        }
        return null;
    }
}

FormSubmitted.propTypes = propTypes;
FormSubmitted.contextTypes = contextTypes;
export default FormSubmitted;
