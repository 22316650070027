import * as Class from "classnames";
import * as React from "react";
import Icon from "../Icon";
import * as styles from "./ListItem.scss";

const colours = [
    "#FF5722",
    "#2196F3",
    "#9C27B0",
    "#1976D2",
    "#FF9800",
    "#689F38",
    "#C2185B",
    "#0097A7",
    "#d32f2f",
    "#0288D1",
    "#512DA8",
    "#303F9F",
    "#F57C00",
    "#00796B",
    "#FBC02D",
    "#E91E63",
    "#FFC107",
    "#4CAF50",
    "#00BCD4",
    "#8BC34A",
    "#673AB7",
    "#009688",
    "#7B1FA2",
    "#03A9F4",
    "#f44336",
    "#3F51B5",
];

interface IListItemProps extends React.HTMLProps<HTMLElement> {
    title: string;
    subtitle?: string;
    icon?: string;
    showCircle?: boolean;
    removeText?: string;
    onClick?: () => void;
    onRemove?: (e: any) => void;
    onUndo?: () => void;
}

interface IListItemState {
    swiping: boolean;
    showBackground: boolean;
    swipedAway: boolean;
}

const isMobile = window.orientation !== undefined;

export default class ListItem extends React.Component<IListItemProps, IListItemState> {
    private listContainerRef: HTMLLIElement;
    private listRef: HTMLDivElement;
    private rippleRef: HTMLDivElement;
    private startTouchY: number = 0;
    private startTouchX: number = 0;
    private swipeOffset: number = 0;
    private canSwipe: boolean;
    private swipeSpeed: number = 0;
    private swipedLeft: boolean = false;
    private clearBackgroundTimer?: NodeJS.Timer;
    private rippleExpansionTimer?: NodeJS.Timer;

    constructor(props: IListItemProps) {
        super(props);

        this.createRipple = this.createRipple.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.clearBackground = this.clearBackground.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = { swiping: false, showBackground: false, swipedAway: false };
    }

    public componentWillUnmount() {
        if (this.clearBackgroundTimer) {
            clearTimeout(this.clearBackgroundTimer);
            this.clearBackgroundTimer = undefined;
        }
    }

    private clearBackground() {
        this.setState({ showBackground: false });
    }

    private onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
        if (this.props.onRemove) {
            if (e.touches.length === 1) {
                const x = e.touches[0].clientX;
                const y = e.touches[0].clientY;
                this.startTouchX = x;
                this.startTouchY = y;
                this.canSwipe = true;
            }
        }
    }

    private onClick(e) {
        this.createRipple(e);
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    private onTouchMove(e: React.TouchEvent<HTMLDivElement>) {
        if (e.touches.length === 1) {
            if (!this.state.swiping) {
                if (Math.abs(e.touches[0].clientX - this.startTouchX) > 16 && this.canSwipe) {
                    this.swipeOffset = e.touches[0].clientX - this.startTouchX;
                    this.setState({ swiping: true, showBackground: true });
                    e.preventDefault();
                }
                if (Math.abs(e.touches[0].clientY - this.startTouchY) > 8) {
                    this.canSwipe = false;
                }
            } else if (this.canSwipe) {
                e.preventDefault();
                const x = e.touches[0].clientX;
                if (!this.swipeSpeed) {
                    this.swipeSpeed = Math.abs(this.swipeOffset - (x - this.startTouchX));
                } else {
                    this.swipeSpeed = (this.swipeSpeed * 5 + Math.abs(this.swipeOffset - (x - this.startTouchX))) / 6;
                }
                this.swipeOffset = x - this.startTouchX;
                this.listRef.style.left = `${this.swipeOffset}px`;
            }
        }
    }

    private onTouchEnd(e: React.TouchEvent<HTMLDivElement>) {
        if (Math.abs(this.swipeOffset) > this.listContainerRef.clientWidth / 2 || this.swipeSpeed >= 20) {
            this.swipedLeft = this.swipeOffset < 0;
            this.setState({ swipedAway: true });
            if (this.props.onRemove) {
                this.props.onRemove({ currentTarget: this.listContainerRef });
            }
        } else {
            if (this.clearBackgroundTimer) {
                clearTimeout(this.clearBackgroundTimer);
            }
            this.clearBackgroundTimer = setTimeout(this.clearBackground, 400);
        }
        this.swipeSpeed = 0;
        this.setState({ swiping: false });
    }

    private createRipple(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) {
        const position = this.listContainerRef.getBoundingClientRect();
        let x: number;
        let y: number;
        if (e.clientX !== undefined) {
            x = e.clientX - position.left;
            y = e.clientY - position.top;
        } else {
            x = e.touches[0].clientX - position.left;
            y = e.touches[0].clientY - position.top;
        }
        const initialSize = 48; // About size of a finger press
        const expandSpeed = 128;
        const maxSize = Math.max(this.listContainerRef.clientWidth * 2, this.listContainerRef.clientHeight * 2);
        const expansionTime = Math.floor((maxSize / expandSpeed) * 100);
        Object.assign(this.rippleRef.style, {
            transition: "",
            left: (x - initialSize / 2) + "px",
            top: (y - initialSize / 2) + "px",
            width: `${initialSize}px`,
            height: `${initialSize}px`,
            opacity: "1",
            display: "block",
        });
        setTimeout(() => {
            if (this.listContainerRef) {
                Object.assign(this.rippleRef.style, {
                    transition: `width, height, opacity, background-color, left, top`,
                    transitionDuration: `${expansionTime}ms`,
                    transitionTimingFunction: "ease-out",
                    opacity: "0",
                    width: maxSize + "px",
                    height: maxSize + "px",
                    left: (x - maxSize / 2) + "px",
                    top: (y - maxSize / 2) + "px",
                });
                if (this.rippleExpansionTimer) {
                    clearTimeout(this.rippleExpansionTimer);
                    this.rippleExpansionTimer = undefined;
                }
                this.rippleExpansionTimer = setTimeout(() => {
                    if (this.listContainerRef) {
                        Object.assign(this.rippleRef.style, {
                            display: "none",
                        });
                    }
                }, expansionTime);
            }
        }, 1);
    }

    public render() {
        const { title, subtitle, icon, showCircle, children, onClick, className, removeText, onRemove, onUndo, ...other } = this.props;
        return (
            <li
                className={Class(styles["list-item"], "list-item", className)}
                ref={(ref) => this.listContainerRef = ref}
                onClick={this.onClick}
                {...other}>
                {(this.state.swiping || this.state.showBackground) &&
                    <div className={Class(styles["action-back"], "action-back")}>
                        <Icon icon="fa-trash" className={Class(styles["trash-icon"], "trash-icon", { [styles.active]: this.state.swipedAway, active: this.state.swipedAway })} />
                        <div className={Class(styles["action-swiped-container"], "action-swiped-container", { [styles.active]: this.state.swipedAway, active: this.state.swipedAway })}>
                            <span className={Class(styles.title, "title")}>{this.props.removeText || "Removed"}</span>
                            <span className={Class(styles.undo, "undo")}
                                onClick={() => this.setState({ swipedAway: false })}>Undo</span>
                        </div>
                    </div>
                }
                <div
                    ref={(ref) => this.rippleRef = ref}
                    className={Class(styles.ripple, "ripple")} />
                <div
                    ref={(ref) => this.listRef = ref}
                    className={Class(styles.content, "content", {
                        [styles["non-swiping"]]: !this.state.swiping,
                        [styles["swiped-away"]]: this.state.swipedAway,
                        [styles["swiped-left"]]: this.swipedLeft,
                        [styles["has-ball"]]: showCircle || icon !== undefined,
                        "non-swiping": !this.state.swiping,
                        "swiped-away": this.state.swipedAway,
                        "swiped-left": this.swipedLeft,
                        "has-ball": showCircle || icon !== undefined,
                    })}
                    onTouchStart={this.onTouchStart}
                    onTouchMove={this.onTouchMove}
                    onTouchEnd={this.onTouchEnd}
                    style={{ left: this.state.swiping ? this.swipeOffset : "" }}>
                    {showCircle &&
                        <span
                            aria-disabled
                            className={Class(styles["icon-ball"], "icon-ball")}
                            style={{ background: colours[title.toLowerCase().charCodeAt(0) - 97] }}>
                            {title.charAt(0)}
                        </span>
                    }
                    {icon &&
                        <Icon icon={icon} className={Class(styles.icon, "icon")} />
                    }
                    <span className={Class(styles.title, "title")}>
                        {title}
                    </span>
                    <span className={Class(styles.subtitle, "subtitle")}>
                        {subtitle}
                    </span>
                    <span className={Class(styles.action, "action")}>
                        {children}
                    </span>
                </div>
            </li>
        );
    }
}
