import * as Class from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as styles from "./SnackBar.scss";

export interface ISnackBarOptions {
    /** URL of the sound to play */
    sound?: string;
}

/**
 * A toast singleton to display short notifications on the screen
 */
export class SnackBar {
    private divContainer: HTMLDivElement;
    private closeTimer: any;
    private textQueue: any[] = [];

    private init() {
        if (!this.divContainer) {
            this.divContainer = document.createElement("div");
            document.body.appendChild(this.divContainer);
        }
    }

    public show(content: string, options: ISnackBarOptions = {}) {
        this.init();
        this.render(content, options);
        setTimeout(() => { $("#snackbar-container").addClass(styles.open); }, 50);
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
        if (options.sound) {
            (new Audio(options.sound)).play();
        }
        this.closeTimer = setTimeout(this.close, 2400);
    }

    private close() {
        $("#snackbar-container").removeClass().addClass(styles["snackbar-container"]);
    }

    private render(content: string, options: ISnackBarOptions) {
        ReactDOM.render(
            <div
                id="snackbar-container"
                className={Class(styles["snackbar-container"])}>
                <div className={Class(styles.snackbar)}>
                    <span>{content}</span>
                </div>
            </div>,
            this.divContainer,
        );
    }
}

export default new SnackBar();
