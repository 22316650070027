import * as Class from "classnames";
import { bind } from "decko";
import * as $ from "jquery";
import * as React from "react";
import * as styles from "./Modal.scss";

declare const global: any;

if (!"HTMLDialogElement" in global) {
    global.dialogPolyfill = import(/* webpackChunkName: "dialog-polyfill" */ "dialog-polyfill");
}

export interface IModalProps {
    className?: string;
    dialogStyle?: any;
    show: boolean;
    targetX?: number;
    targetY?: number;
    enablePolyfill?: boolean;
}

const isIEorEdge = /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent);
const isMobile = /Mobi/.test(navigator.userAgent);

/**
 * A react based modal
 */
export default class Modal extends React.Component<IModalProps, never> {
    private backdropRef: HTMLDivElement;
    private containerRef: HTMLDivElement;
    private modalRef: HTMLDivElement;
    private emergencyBackdropRef: HTMLDivElement;
    private mutator: MutationObserver;

    public componentDidMount() {
        if ("MutationObserver" in global) {
            this.mutator = new MutationObserver(() => {
                const $containerRef = $(this.containerRef);
                const $modalRef = $(this.modalRef);
                $containerRef.css({
                    top: `calc(50% - ${Math.floor($modalRef.outerHeight() / 2)}px)`,
                    left: this.polyfill ? 0 : `calc(50% - ${Math.floor($modalRef.outerWidth() / 2)}px)`,
                    height: "auto",
                    position: "fixed",
                });
            });
        }
        this.popin();
        document.body.style.overflow = "hidden";
        window.addEventListener("resize", this.onWindowResize);
    }

    public componentWillUnmount() {
        document.body.style.overflow = "";
        window.removeEventListener("resize", this.onWindowResize);
    }

    private get polyfill() {
        return false;
        //return /Chrome/i.test(navigator.userAgent) ? true : isIEorEdge ? false : this.props.enablePolyfill;
    }

    @bind
    private onWindowResize() {
        const $containerRef = $(this.containerRef);
        const $modalRef = $(this.modalRef);
        const height = $modalRef.outerHeight() || 0;
        const width = $modalRef.outerWidth() || 0;
        $containerRef.css({
            width,
            top: `calc(50% - ${Math.floor($modalRef.outerHeight() / 2)}px)`,
            left: this.polyfill ? 0 : `calc(50% - ${Math.floor($modalRef.outerWidth() / 2)}px)`,
            height: "auto",
        });
    }

    public componentDidUpdate() {
        this.popin();
    }

    private popin() {
        if (this.props.show) {
            if (this.polyfill && global.dialogPolyfill) {
                global.dialogPolyfill.registerDialog(this.containerRef);
            }
            let $containerRef = $(this.containerRef);
            if (!$containerRef.css) {
                return;
            }
            let $modalRef = $(this.modalRef);
            let targetX = window.innerWidth / 2;
            let targetY = window.innerHeight / 2;
            if (this.props.targetX !== undefined && this.props.targetY !== undefined) {
                const angle = Math.atan2(this.props.targetY - window.innerHeight / 2, this.props.targetX - window.innerWidth / 2);
                targetX = targetX + Math.cos(angle) * 256;
                targetY = targetY + Math.sin(angle) * 256;
            }
            const offsetX = (targetX / window.innerWidth);
            const offsetY = (targetY / window.innerHeight);
            if (this.polyfill) {
                this.containerRef.showModal();
            } else {
                this.emergencyBackdropRef.classList.add(styles.polyfill);
                this.containerRef.classList.add(styles.polyfill);
            }
            $containerRef.css({
                //left: `${this.polyfill ? 0 : offsetX * window.innerWidth}px`,
                //top: `${offsetY * window.innerHeight}px`,
                width: `auto`,
                transition: `none`,
                opacity: "0",
                height: "auto",
            });
            $modalRef.css({
                opacity: 0.5,
                maxHeight: "100% !important",
            });
            setTimeout(() => {
                $containerRef = $(this.containerRef);
                $modalRef = $(this.modalRef);
                if (!this.polyfill) {
                    this.emergencyBackdropRef.classList.add(styles.active, "active");
                }
                this.containerRef.classList.add(styles.active, "active");
                this.modalRef.classList.add(styles.active, "active");
                const height = $modalRef.outerHeight() || 0;
                const width = $modalRef.outerWidth() || 0;
                $containerRef.css({
                    top: `calc(50% - ${Math.floor(height / 2)}px)`,
                    left: this.polyfill ? 0 : `calc(50% - ${Math.floor(width / 2)}px)`,
                    width,
                    height,
                    opacity: 1,
                });
                $modalRef.css({
                    left: 0,
                    top: 0,
                    opacity: 1,
                    maxHeight: "100%",
                });
                setTimeout(() => {
                    $containerRef = $(this.containerRef);
                    $modalRef = $(this.modalRef);
                    const height = $modalRef.outerHeight() || 0;
                    const width = $modalRef.outerWidth() || 0;
                    this.mutator.observe(this.modalRef, { childList: true, subtree: true, attributes: true, characterData: true });
                    $containerRef.css({
                        top: `calc(50% - ${Math.floor(height / 2)}px)`,
                        left: this.polyfill ? 0 : `calc(50% - ${Math.floor(width / 2)}px)`,
                        height: "auto",
                    });
                    $modalRef.css({
                        display: "block",
                    });
                    setTimeout(() => {
                        $containerRef = $(this.containerRef);
                        $modalRef = $(this.modalRef);
                        $containerRef.css({
                            top: `calc(50% - ${Math.floor($modalRef.outerHeight() / 2)}px)`,
                            left: this.polyfill ? 0 : `calc(50% - ${Math.floor($modalRef.outerWidth() / 2)}px)`,
                            height: "auto",
                        });
                    }, 2000);
                }, 400);
            }, 1);
        }
    }

    public render() {
        const { show, children, className, dialogStyle, enablePolyfill, ...other } = this.props;
        const style = { ...dialogStyle };
        if (show) {
            return (
                <div>
                    {!this.polyfill && <div className={Class("emergency-backdrop", styles["emergency-backdrop"])} ref={(ref) => this.emergencyBackdropRef = ref} />}
                    <dialog
                        ref={(ref) => this.containerRef = ref}
                        className={Class(styles["react-modal-container"], "react-modal-container")}>
                        <div {...other}
                            ref={(ref) => this.modalRef = ref}
                            className={Class(styles["react-modal"], "react-modal", className)}
                            style={style}>
                            {children}
                        </div>
                    </dialog>
                </div>
            );
        } else {
            return null;
        }
    }
}
