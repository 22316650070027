import React from "react";
import "./FormPage.scss";
import PropTypes from "prop-types";

const childContextTypes = {
    submitted: PropTypes.bool,
    form: PropTypes.object,
};

class FormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { submitted: false };
    }

    getChildContext() {
        return { submitted: this.state.submitted, form: this };
    }

    setSubmitted(submitted) {
        this.setState({ submitted: submitted });
    }

    render() {
        return (
            <div className="form">
                {this.props.children}
            </div>
        );
    }
}

FormPage.childContextTypes = childContextTypes;
export default FormPage;
