import * as mobx from "mobx";
import * as React from "react";

import { observer } from "mobx-react";

interface IMultiPageProps extends React.HTMLProps<HTMLElement> {
    page: number | string;
}

@observer
export default class MultiPage extends React.Component<IMultiPageProps, never> {
    private keyList: any = {};

    constructor(props: IMultiPageProps) {
        super(props);

        this.createChildList(props);
    }

    private createChildList(props: IMultiPageProps) {
        let { children } = this.props;
        this.keyList = {};
        let index = 0;
        if (!Array.isArray(children)) {
            children = [children];
        }
        if (children) {
            for (const child of children) {
                this.keyList[child.key] = index;
                index++;
            }
        }
    }

    public shouldComponentUpdate(nextProps: IMultiPageProps) {
        if (nextProps.page !== this.props.page) {
            return true;
        }
        return false;
    }

    public componentWillUpdate(nextProps: IMultiPageProps) {
        this.createChildList(nextProps);
    }

    public render() {
        let { children, page, ...other } = this.props;
        if (!children) {
            return (
                <div>
                    No child pages of MultiPage
                </div>
            );
        }
        if (!Array.isArray(children)) {
            children = [children];
        }

        if (typeof page === "number") {
            return children[page];
        }
        if (typeof page === "string") {
            if (this.keyList[page] !== undefined) {
                return children[this.keyList[page]];
            } else {
                return (
                    <div {...other}>
                        Unable to render page, key "{page}" does not exist
                    </div>
                );
            }
        }
    }
}
