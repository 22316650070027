import * as Class from "classnames";
import { inject, observer } from "mobx-react";
import * as React from "react";
import * as styles from "./Sidebar.scss";

interface ISidebarProps extends React.HTMLProps<HTMLDivElement> {
    open: boolean;
    rhs?: boolean;
    hasActionBar?: boolean;
    store?: any;
    onBackdropClicked: () => void;
}

const isMobile = window.orientation !== undefined;

/**
 * A mobile friendly navigation pane / sidebar component that doesn't rely on ReactMDL
 */
@inject((props, stores, context) => props)
@observer
export default class Sidebar extends React.Component<ISidebarProps, never> {
    private backdropRef: HTMLDivElement;
    private sidebarRef: HTMLDivElement;
    private previousOpen?: boolean;

    constructor(props: ISidebarProps) {
        super(props);

        this.onKeyboard = this.onKeyboard.bind(this);
        this.previousOpen = props.open;
    }

    public componentDidMount() {
        if (this.open) {
            document.body.classList.add("no-scroll");
            document.addEventListener("keypress", this.onKeyboard);
        }
    }

    public componentDidUpdate(prevProps: ISidebarProps) {
        if (document.body.classList.contains("no-scroll")) {
            document.body.classList.remove("no-scroll");
        }
        if (this.open) {
            document.body.classList.add("no-scroll");
            document.addEventListener("keypress", this.onKeyboard);
        }
        if (!this.open && this.previousOpen) {
            this.sidebarRef.classList.add(styles["scroll-transition"]);
            document.removeEventListener("keypress", this.onKeyboard);
            setTimeout(() => {
                this.sidebarRef.classList.remove(styles["scroll-transition"]);
            }, 400);
        }
        this.previousOpen = this.open;
    }

    public componentWillUnmount() {
        if (document.body.classList.contains("no-scroll")) {
            document.body.classList.remove("no-scroll");
            document.removeEventListener("keypress", this.onKeyboard);
        }
    }

    private get open() {
        return this.props.open || (this.props.store ? this.props.store.open : false);
    }

    private onKeyboard(e: KeyboardEvent) {
        // Manaully control tabs to create a keyboard trap
        if (e.keyCode === 9) {
            e.preventDefault();
            const elements = this.sidebarRef.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]");
            let activeIndex = -1;
            const activeElement: Element = document.activeElement;
            const maxIndex = elements.length;
            for (const index in elements) {
                if (elements[index]) {
                    const element = elements[index];
                    if (element === document.activeElement) {
                        activeIndex = parseInt(index, 0);
                    }
                }
            }
            let newIndex = activeIndex;
            // Go forward
            if (!e.shiftKey) {
                newIndex++;
                if (newIndex >= maxIndex) {
                    newIndex = -1;
                }
            } else {
                // Go backwards
                newIndex--;
                if (newIndex === -2) {
                    newIndex = maxIndex - 1;
                }
            }
            if (newIndex >= 0) {
                elements[newIndex].focus();
            } else if (activeElement) {
                activeElement.blur();
            }
        }
        // Match escape
        if (e.keyCode === 27) {
            const realBackdropClicked = this.props.onBackdropClicked || (this.props.store ? this.props.store.onBackdropClicked : undefined);
            realBackdropClicked();
        }
    }

    public render() {
        const { hasActionBar, open, store, rhs, onBackdropClicked, className, children, ...other } = this.props;
        const realOpen = open || (store ? store.open : false);
        const realBackdropClicked = onBackdropClicked || (store ? store.onBackdropClicked : undefined);

        const width = Math.min(isMobile ? 320 : 400, window.innerWidth - 56);
        const left = rhs ? realOpen ? `calc(100vw - ${width}px)` : "100vw" : realOpen ? `0%` : `-${width}px`;

        return (
            <div>
                <div
                    ref={(ref) => this.backdropRef = ref}
                    onClick={realBackdropClicked}
                    className={Class("backdrop", styles.backdrop, { open: realOpen, [styles.open]: realOpen, [styles["has-action-bar"]]: hasActionBar || store })}
                />
                <div
                    aria-disabled={!realOpen}
                    aria-hidden={!realOpen}
                    ref={(ref) => this.sidebarRef = ref}
                    className={Class(className, "sidebar", styles.sidebar, { open: realOpen, [styles.open]: realOpen, [styles["has-action-bar"]]: hasActionBar || store })}
                    style={{ width: width + "px", left }}
                    {...other}>
                    {children}
                </div>
            </div>
        );
    }
}
