import * as Class from "classnames";
import * as React from "react";

import { IColumn, IMergedRow } from "./SmartGrid";

import { bind } from "decko";
import { observer } from "mobx-react";
import DatePicker from "../DatePicker";
import Icon from "../Icon";
import Input from "../Input";
import format from "../util/formatter";

interface ICellProps {
    column: IColumn;
    rowData: any;
    isGroupHeader?: boolean;
    isTotal?: boolean;
    disabled?: boolean;
    onKeyDown?: (e: any) => void;
}

function idString(str: string) {
    return (str.toString()).replace(new RegExp(/[ .+-/!/@/#/$/%/^/&/*/;/:/?/|<>/\\(\)\'\"]/, "g"), "_").toLowerCase();
}

@observer
export default class Cell extends React.Component<ICellProps, never> {
    private inputRef?: any;

    @bind
    public blur() {
        if (this.inputRef && this.inputRef.blur) {
            this.inputRef.blur();
        }
    }

    @bind
    public focus() {
        if (this.inputRef && this.inputRef.focus) {
            this.inputRef.focus();
        }
    }

    private computeWidth(column: IColumn) {
        if (column.width === undefined) {
            return;
        }
        // Width was a percentage, so we need children to fill 100%
        if (column.width.toString().indexOf("%") >= 0) {
            return "100%";
        }
        return column.width;
    }

    public render() {
        const { column, rowData, isGroupHeader, isTotal, disabled, onKeyDown, ...other } = this.props;
        this.inputRef = undefined;

        let observable = rowData;
        let value = column.field;
        let isDisabled = isGroupHeader || disabled || column.disabled;
        const realValue = rowData[column.field];
        if (typeof (realValue) === "object" && realValue !== null) {
            observable = realValue;
            value = "value";
            isDisabled = isDisabled || realValue.disabled;
        }
        if (!observable) {
            throw new Error("Expected observable, got null or undefined");
        }

        let render;
        if (column.spacer) {
            return isGroupHeader ? <th className="spacer" /> : <td className="spacer" />;
        } else if (observable.renderer) {
            render = observable.renderer(isGroupHeader ? "group" : isTotal ? "total" : "row", column, observable, value);
        } else if (column.renderer) {
            render = column.renderer(isGroupHeader ? "group" : isTotal ? "total" : "row", column, observable, value);
        } else {
            if (isDisabled) {
                switch (column.type) {
                    case "icon":
                        render = <Icon small icon={observable[value]} />;
                        break;
                    case "date":
                    case "datetime":
                    case "time":
                    case undefined:
                    case "string":
                    case "number":
                    case "currency":
                        const val = observable[value];
                        if (column.type === "currency" && val === undefined) {
                            render = <span>Error, undefined value</span>;
                            break;
                        }
                        render = <span
                            style={{ width: this.computeWidth(column) }}
                            {...other}>
                            {column.type === "currency" ? format(val, "currency", column.typeOptions) : val}
                        </span>;
                        break;
                    default:
                        render = rowData[column.field];
                }
            } else {
                switch (column.type) {
                    case "icon":
                        render = <Icon small icon={observable[value]} />;
                        break;
                    case "date":
                    case "datetime":
                    case "time":
                        render = <DatePicker
                            ref={(ref) => this.inputRef = ref}
                            observable={observable}
                            value={value}
                            disabled={isDisabled}
                            style={{ width: this.computeWidth(column) }}
                            {...other}
                        />;
                        break;
                    case undefined:
                    case "string":
                    case "number":
                    case "currency":
                        if (column.type === "currency" && observable[value] === undefined) {
                            render = <span>Error, undefined value</span>;
                            break;
                        }
                        render = <Input
                            ref={(ref: Input) => this.inputRef = ref}
                            observable={observable}
                            value={value}
                            type={column.type || "number"}
                            typeOptions={column.typeOptions}
                            selectOnFocus
                            disabled={isDisabled}
                            onKeyDown={onKeyDown}
                            style={{ width: this.computeWidth(column) }}
                            simple
                            {...other}
                        />;
                        break;
                    default:
                        render = rowData[column.field];
                }
            }
        }
        return (
            isGroupHeader ?
                <th
                    className={Class("cell", column.className, column.alignment, { ["hidden-cell"]: column.name === "" || column.name === undefined })}
                    style={{ width: column.width }}>
                    {render}
                </th>
                :
                <td
                    className={Class("cell", column.className, column.alignment, { ["hidden-cell"]: column.name === "" || column.name === undefined })}
                    style={{ width: column.width }}>
                    {render}
                </td>
        );
    }
}
