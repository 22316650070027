import * as numeral from "numeral";
import  {ICalculationInputs, NUMBER_FORMATS, registerCalculationType} from "./Calculator";
import {ITier, ITiersOptions, Tiers} from "./Tiers";

export default class PercentageTiers extends Tiers {
    public readonly tiers: ITier[];

    public calculationFunction(inputs: ICalculationInputs): any {
        return this.percentage_tiers_calculate(inputs);
    }

    public percentage_tiers_calculate(inputs: any): any {
        const base = inputs.baseValue;
        let tierLookupValue = inputs.tierLookupValue;
        if (tierLookupValue == null) {
            tierLookupValue = base;
        }
        const baseValue = numeral(base);
        const tier = this.findTier(tierLookupValue);
        const percentage = numeral(tier.percentage + "%");
        const rawResult = percentage.value() * baseValue.value();
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const resultUnit = "$";
        const rounding = this.roundFormula("" + rawResult);
        const formula = "inputValue * calculatedTierResult";
        const calculation = `${numeral(baseValue).format(NUMBER_FORMATS.numberFormat)} * ${percentage.format(NUMBER_FORMATS.percentFormat)} = ${formattedResult}`;
        //const groupTotalValue = (groupTotal != baseValue) ? groupTotal : undefined;
        const res = {
            calculation_type: this.calculation_type,
            calculation,
            formula,
            formattedResult,
            inputValue: baseValue.value(),
            tierLookupValue,
            percentage: percentage.format(NUMBER_FORMATS.percentFormat),
            result: result.value(),
            rawResult,
            rounding,
            resultUnit,
            tier,
        };
        return res;
    }
}

// registerCalculationType("group_percentage_tiers", PercentageTiers);
registerCalculationType("percentage_tiers", PercentageTiers);
