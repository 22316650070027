import { default as cable, ICableAction, ActionEvent } from "../../gears/actioncable"; // Use a singleton action cable to reduce server load.
import { action } from "mobx";

interface IGearsActionSubscriberOptions {
    /** Name of the model to subscribe too */
    model: string;
    /** Reference to a MobX observable array that will be updated */
    data: any[];
    /** Custom matcher used to updating existing array values, defaults to 'id' */
    matcher?: string;
    /** Callback function that occurs every time an update is processed */
    callback?: (update: ICableAction) => void;
}

/** Listens for updates on a model and automatically updates a data object */
export default class GearsActionSubscriber {
    private cable = cable;

    constructor(options: IGearsActionSubscriberOptions) {
        const matcher = options.matcher || "id";
        this.cable.subscriptions.create({
            channel: "ActionChannel",
            typed_id: options.model,
        }, {
                connected: () => {
                    //console.log("connected");
                },
                disconnected: () => {
                    //console.log("disconnected");
                },
                received: action((update: ICableAction) => {
                    const index = _.findIndex(options.data, { [matcher]: update.object[matcher] });
                    if (update.event === ActionEvent.Delete) {
                        options.data.splice(index, 1);
                    } else if (index >= 0) {
                        // Support treating updates of missing objects as inserts
                        // We are updating
                        options.data[index] = update.object;
                    } else {
                        // We are creating a new entry
                        options.data.push(update.object);
                    }
                    if (options.callback) {
                        options.callback(update);
                    }
                })
            }
        );
    }
}

