const backspaceTagnames = /^(INPUT|SELECT|TEXTAREA)$/i;
const backspaceInputTypes = /^(RADIO|CHECKBOX|SUBMIT|BUTTON)$/i;
function backspaceListener(e: KeyboardEvent): void {
    const target = e.target as HTMLInputElement;
    if (!target || e.keyCode !== 8) { // 8 == backspace
        return;
    }
    const inputType = target.getAttribute("type") as string;

    if (backspaceTagnames.test(target.tagName)) {
        if (!target.disabled && !target.readOnly && !backspaceInputTypes.test(inputType)) {
            return;
        }
    } else {
        if (target.isContentEditable) {
            return;
        }
    }
    e.preventDefault();
}

export default function disableBackspace(): void {
    document.addEventListener("keydown", backspaceListener);
}