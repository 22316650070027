exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._9U_Vk_ddRibDROl3huXK2{position:relative;margin:0px;padding-bottom:16px;padding-top:24px;padding:32px}\n", ""]);

// exports
exports.locals = {
	"card-content": "_9U_Vk_ddRibDROl3huXK2"
};