import * as Class from "classnames";
import { observer } from "mobx-react";
import * as PropTypes from "prop-types";
import * as React from "react";

import Icon from "../Icon";
import FabEditor from "./FabEditor";

const contextTypes = {
    colour: PropTypes.string,
};

interface ICardHeaderProps extends React.HTMLProps<HTMLDivElement> {
    title: string;
    subtitle?: string;
    fabIcon?: string;
    small?: boolean;
    onFabPress?: () => void;
    sticky?: boolean;
}

/**
 * Card header controls the top of a card and is where you can place CardActions
 */
@observer
export default class CardHeader extends React.Component<ICardHeaderProps, never> {
    public static contextTypes = contextTypes;

    constructor(props: ICardHeaderProps) {
        super(props);
    }

    public render() {
        const { className, style, title, subtitle, children, fabIcon, small, onFabPress, sticky, ...other } = this.props;
        let newStyle = style;
        if (this.context.colour) {
            if (!newStyle) {
                newStyle = {};
            }
            newStyle.backgroundColor = this.context.colour;
        }
        return (
            <div
                className={Class("card-header", className, { sticky, small, coloured: this.context.colour })}
                style={newStyle} {...other}>
                <div className="title">
                    {title}
                </div>
                <div className="subtitle">
                    {subtitle}
                </div>
                <div className="card-actions">
                    {children}
                </div>
                {fabIcon &&
                    <div className="fab">
                        <Icon icon={fabIcon} onClick={onFabPress} />
                    </div>}
            </div>
        );
    }
}
