exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1LqSvBx-1R4v2NPSaSXUUN{margin:4px;position:relative}._1LqSvBx-1R4v2NPSaSXUUN textarea{resize:both;outline:none;background:none;box-sizing:border-box;border:1px solid rgba(0,0,0,0.87);border-radius:4px;padding:16px;padding-left:10px;padding-top:32px;margin:1px}._1LqSvBx-1R4v2NPSaSXUUN textarea:disabled{color:rgba(0,0,0,0.42);background-color:rgba(0,0,0,0.06);border-color:rgba(0,0,0,0.12)}._1LqSvBx-1R4v2NPSaSXUUN textarea:not(:disabled):hover,._1LqSvBx-1R4v2NPSaSXUUN textarea:not(:disabled):focus{border-width:2px;border-color:rgba(0,0,0,0.42);margin:0px}._1LqSvBx-1R4v2NPSaSXUUN textarea:not(:disabled):focus{border-color:var(--primary-colour, #1976D2)}._1LqSvBx-1R4v2NPSaSXUUN textarea._1ZJDem9AFvjX4bLPN7ZNUA{padding-top:8px}._1LqSvBx-1R4v2NPSaSXUUN label{position:absolute;top:12px;left:12px;font-size:12px}._1LqSvBx-1R4v2NPSaSXUUN .HHpx-TNfh7haZ4aBpzyJZ{display:block;text-align:right;position:relative;opacity:0;z-index:0;transition:margin-top 200ms, opacity 200ms}._1LqSvBx-1R4v2NPSaSXUUN .HHpx-TNfh7haZ4aBpzyJZ i{border-radius:50%;border:1px solid rgba(0,0,0,0.25);margin-left:4px;background:white;font-size:24px;cursor:pointer}._1LqSvBx-1R4v2NPSaSXUUN .HHpx-TNfh7haZ4aBpzyJZ i:hover{background:#DDDDDD}._1LqSvBx-1R4v2NPSaSXUUN._3SvFw8cPcN8AfzF0Mc2ZVh .HHpx-TNfh7haZ4aBpzyJZ{margin-top:0px;opacity:1}\n", ""]);

// exports
exports.locals = {
	"textarea": "_1LqSvBx-1R4v2NPSaSXUUN",
	"without-label": "_1ZJDem9AFvjX4bLPN7ZNUA",
	"button-container": "HHpx-TNfh7haZ4aBpzyJZ",
	"unsaved": "_3SvFw8cPcN8AfzF0Mc2ZVh"
};