import * as Class from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";

import Icon from "../Icon";
import { IconDefinition } from '@fortawesome/fontawesome';

const defaultProps = {
    enabled: true,
};

const contextTypes = {
    menu: PropTypes.any,
};

export interface IMenuItemProps extends React.HTMLProps<HTMLDivElement> {
    icon?: string | IconDefinition;
    iconColour?: string;
    title: string;
    shortcut?: any;
    disabled?: boolean;
    enabled?: boolean;
    danger?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * A single item in a Menu
 */
export default class MenuItem extends React.Component<IMenuItemProps, never> {
    private static defaultProps = defaultProps;
    private static contextTypes = contextTypes;
    private timer?: NodeJS.Timer;

    constructor(props: IMenuItemProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    private onClick(e: React.MouseEvent<HTMLDivElement>) {
        if (e.button === 0) {
            if (this.props.onClick) {
                this.context.menu.stayOpen = this.props.onClick(e);
            } else {
                this.context.menu.stayOpen = true;
            }
        }
    }

    public render() {
        const { icon, title, shortcut, enabled, disabled, children, iconColour, danger, onClick, ...other } = this.props;
        const realDisabled = disabled || !enabled;
        return (
            <div
                className={Class({ disabled: realDisabled, danger })}
                onMouseDown={!realDisabled ? this.onClick : undefined}
                {...other}>
                <span>
                    <span>
                        {icon ? <Icon icon={icon} fixedWidth colour={iconColour} /> : undefined}
                        {title}
                    </span>
                    <span className="shortcut">
                        {children ? <Icon icon="fa-chevron-right" /> : shortcut}
                    </span>
                </span>
                {children}
            </div>
        );
    }
}
