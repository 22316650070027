import * as Class from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";
import "./StarRating.scss";

import fontAwesome from "@fortawesome/fontawesome";
import { faStar as faStarO } from "@fortawesome/fontawesome-pro-regular";
import { faStar } from "@fortawesome/fontawesome-pro-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { IObservable, action } from "mobx";
import { observer } from "mobx-react";
import { bind } from 'decko';

fontAwesome.library.add({ faStar, faStarO });

const defaultProps = {
    filledIcon: faStar,
    emptyIcon: faStarO,
    editable: false,
    rating: 0,
};

interface IStarRatingProps {
    observable?: IObservable;
    value?: string;
    filledIcon?: string;
    emptyIcon?: string;
    editable?: boolean;
    disabled?: boolean;
    rating?: number;
    onChange?: Function;
    className?: string;
}
interface IStarRatingState {
    defaultRating: number;
    rating: number;
}

/**
* A 5-star rating display
*/
export default class StarRating extends React.Component<IStarRatingProps, IStarRatingState> {
    public static defaultProps = defaultProps;

    constructor(props: IStarRatingProps) {
        super(props);

        if (!props.observable) {
            this.state = { rating: props.rating || defaultProps.rating, defaultRating: props.rating || defaultProps.rating };
        } else {
            this.state = { rating: props.observable[props.value], defaultRating: props.observable[props.value] || defaultProps.rating };
        }
    }

    public componentWillReceiveProps(nextProps: IStarRatingProps) {
        if (!nextProps.observable) {
            this.setState({
                defaultRating: nextProps.rating || defaultProps.rating,
                rating: nextProps.rating || defaultProps.rating,
            });
        } else {
            this.setState({
                defaultRating: nextProps.rating || defaultProps.rating,
                rating: nextProps.observable[nextProps.value],
            });
        }
    }

    @bind
    private onMouseMove(e: React.MouseEvent<HTMLUListElement>) {
        const left = e.clientX - e.currentTarget.getBoundingClientRect().left;
        const width = e.currentTarget.getBoundingClientRect().width;
        const percent = left / width;
        let rating = Math.ceil(percent * 5);
        // Special case, set it to zero if too far left
        if (percent * 5 < 0.5) {
            rating = 0;
        }
        this.setState({ rating });
    }

    @bind
    @action
    private onMouseDown(e: React.MouseEvent<HTMLUListElement>) {
        this.setState({ defaultRating: this.state.rating });
        if (this.props.observable && this.props.value !== undefined) {
            this.props.observable[this.props.value] = this.state.rating;
        }
        if (this.props.onChange) {
            this.props.onChange(this.state.rating);
        }
    }

    @bind
    private onMouseLeave(e: React.MouseEvent<HTMLUListElement>) {
        this.setState({ rating: this.state.defaultRating });
    }

    public render() {
        const { observable, disabled, rating, value, filledIcon, emptyIcon, className, onChange, editable, ...other } = this.props;
        const stars = [];
        const activeClass = Class("rating-full");
        const inactiveClass = Class("rating-empty");
        const classNameMod = Class("rating", "form", className);
        for (let i = 0; i < 5; i++) {
            const active = i < this.state.rating;
            stars.push(
                <li key={i}
                    className={active ? activeClass : inactiveClass}>
                    <FontAwesomeIcon icon={active ? filledIcon : emptyIcon} />
                </li>,
            );
        }
        return (
            <ul className={classNameMod}
                onMouseDown={(editable || !disabled) ? this.onMouseDown : undefined}
                onMouseMove={(editable || !disabled) ? this.onMouseMove : undefined}
                onMouseLeave={(editable || !disabled) ? this.onMouseLeave : undefined}
                {...other}
            >
                {stars}
            </ul>
        );
    }
}
