import * as Promise from "bluebird";
import * as classNames from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Modal from "./Modal";

import { bind } from "decko";
import { Provider } from "mobx-react";

/**
 *  Creates a gearsDialog with no additional formatting or controls.
 * Closing this dialog needs to be implemented by calling props.onClose with any result
 */
export default function createCustomDialog(render: JSX.Element): Promise<any> {
    return new Promise((resolve, reject) => {
        let modalElement = document.getElementById("modalContainer");
        if (!modalElement) {
            const div = document.createElement("div");
            div.id = "modalContainer";
            document.body.appendChild(div);
            modalElement = document.getElementById("modalContainer") as HTMLElement;
        }
        ReactDOM.unmountComponentAtNode(modalElement);
        ReactDOM.render(
            React.createElement(CustomDialog, { children: render, onClose: resolve }),
            modalElement,
        );
    });
}

interface ICustomDialogProps {
    onClose: (result: any) => void;
    children: JSX.Element;
}

class CustomDialog extends React.Component<ICustomDialogProps, never> {
    @bind
    private onClose(result: any) {
        const modalElement = document.getElementById("modalContainer");
        if (modalElement) {
            ReactDOM.unmountComponentAtNode(modalElement);
        }
        this.props.onClose(result);
    }

    public render() {
        return (
            <Modal className={classNames("modal-dialog2", "custom")}
                show={true}>
                <Provider resolve={this.onClose}>
                    {this.props.children}
                </Provider>
            </Modal>
        );
    }
}
