import "./Tooltip.scss";

import * as Class from "classnames";
import * as $ from "jquery";
import * as marked from "marked";
import * as React from "react";
import { bind } from "decko";

import Portal from "./Portal";

declare const global: any;

type TooltipEvent = "hover" | "click";
type Mount = "right-top" | "right-bottom" | "right-center"
    | "left-top" | "left-bottom" | "left-center"
    | "above-left" | "above-right" | "above-center"
    | "below-left" | "below-right" | "below-center";

interface ITooltipProps {
    className?: string;
    content?: (e: React.SyntheticEvent<HTMLElement>) => string;
    event: TooltipEvent;
    mount: Mount;
    target: any;
}

interface ITooltipState {
    visible: boolean;
}

const isMobile = window.orientation !== undefined;

class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
    public static defaultProps: Partial<ITooltipProps> = { mount: "right-top", event: "hover" };
    private ignore: boolean = false;
    private toolRef: HTMLElement | null;
    private currentTarget: any;
    private mounting: string;
    private longClickTimer?: NodeJS.Timer;

    public static fromAttribute(e) {
        if (e.dataset.tooltip) {
            return e.dataset.tooltip;
        }
        return undefined;
    }

    constructor(props: ITooltipProps) {
        super(props);

        this.state = { visible: false };
        this.mounting = props.mount;
    }

    private computePosition(override: Mount | string | undefined, target: any) {
        let mount: Mount | string = override || this.props.mount || "right-top";
        const $target = $(target || this.props.target);
        const $tool = $(this.toolRef);
        const targetBounds = target.getBoundingClientRect();
        const targetWidth = targetBounds.width;
        const targetHeight = targetBounds.height;
        const toolWidth = $tool.outerWidth() || 0;
        const toolHeight = $tool.outerHeight() || 0;
        const position = $target.offset();
        let requiresOverride = false;

        let left = 0;
        let top = 0;
        if (mount.includes("right-")) {
            left = position.left + targetWidth + 8;
        }
        if (mount.includes("left-")) {
            left = position.left - toolWidth - 16;
        }
        if (mount.includes("above-")) {
            top = position.top - toolHeight - 24;
        }
        if (mount.includes("below-")) {
            top = position.top + targetHeight + 8;
        }

        if (mount.includes("-top")) {
            top = position.top - 8;
        }
        if (mount.includes("-bottom")) {
            top = position.top + targetHeight - toolHeight;
        }
        if (mount.includes("left-") || mount.includes("right-")) {
            if (mount.includes("-center")) {
                top = (position.top + targetHeight / 2) - (toolHeight + 16) / 2;
            }
        }
        if (mount.includes("-right")) {
            left = position.left - 8;
        }
        if (mount.includes("-left")) {
            left = position.left - toolWidth - 8 + targetWidth;
        }
        if (mount.includes("above-") || mount.includes("below-")) {
            if (mount.includes("-center")) {
                left = (position.left + targetWidth / 2) - (toolWidth + 16) / 2;
            }
        }

        // Override and reverse mounting if we hit edges of screen
        if (left < 0) {
            mount = mount.replace("left-", "right-");
            requiresOverride = true;
        }
        if (top < 0) {
            mount = mount.replace("above-", "below-");
            requiresOverride = true;
        }
        if (left + toolWidth > $("body").width()) {
            mount = mount.replace("right-", "left-");
            requiresOverride = true;
        }
        if (top + toolHeight > $("body").height()) {
            mount = mount.replace("below-", "above-");
            requiresOverride = true;
        }

        if (!requiresOverride || override) {
            if (left + toolWidth > window.innerWidth) {
                left = window.innerWidth - toolWidth - 16;
            }
            $tool.css({ top, left });
        } else {
            this.computePosition(mount, target);
        }
    }

    @bind
    private startLongClick(e) {
        this.longClickTimer = setTimeout(() => { e.preventDefault(); this.toggle(e); }, 750);
    }

    @bind
    private endLongClick() {
        if (this.longClickTimer) {
            clearTimeout(this.longClickTimer);
            this.longClickTimer = undefined;
        }
    }

    private attach(props: ITooltipProps) {
        if (this.props.target) {
            const $target = $(props.target);
            if (props.event === "hover") {
                if (!isMobile) {
                    $target.on("mouseenter", this.show);
                    $target.on("mouseleave", this.hide);
                } else {
                    $target.on("touchstart", this.startLongClick);
                    $target.on("touchend", this.endLongClick);
                }
            } else if (props.event === "click") {
                $target.on("click", this.toggle);
            }
        } else {
            $(document.body).on("mouseenter", "[data-tooltip]", this.show);
            $(document.body).on("mouseleave", "[data-tooltip]", this.hide);
        }
    }

    public componentDidMount() {
        this.attach(this.props);
    }

    public componentWillUnmount() {
        if (this.props.target) {
            const $target = $(this.props.target);
            if (this.props.event === "hover") {
                if (!isMobile) {
                    $target.off("mouseenter", this.show);
                    $target.off("mouseleave", this.hide);
                } else {
                    $target.off("touchstart", this.startLongClick);
                    $target.off("touchend", this.endLongClick);
                }
            } else if (this.props.event === "click") {
                $target.off("click", this.toggle);
            }
        } else {
            $(document.body).off("mouseenter", "[data-tooltip]", this.show);
            $(document.body).off("mouseleave", "[data-tooltip]", this.hide);
        }
        if (global._masterTooltip === this) {
            global._masterTooltip = null;
        }
    }

    public componentWillReceiveProps(newProps: ITooltipProps) {
        let $target;
        $target = $(this.props.target);
        if (this.props.event === "hover") {
            if (!isMobile) {
                $target.off("mouseenter", this.show);
                $target.off("mouseleave", this.hide);
            } else {
                $target.off("touchstart", this.startLongClick);
                $target.off("touchend", this.endLongClick);
            }
        } else if (this.props.event === "click") {
            $target.off("click", this.toggle);
        }
        this.mounting = newProps.mount;
        this.attach(newProps);
    }

    @bind
    private show(e) {
        this.mounting = (e.currentTarget.attributes["data-tooltip-mount"] && e.currentTarget.attributes["data-tooltip-mount"].value) || this.props.mount;
        this.currentTarget = e.currentTarget;
        if (this.props.content) {
            $(this.toolRef).html(marked(this.props.content(this.currentTarget)));
        }
        this.computePosition(this.mounting, e.currentTarget);
        this.becomeMasterTooltip();
        this.setState({ visible: true });
        e.stopPropagation();
    }

    @bind
    private hide() {
        $(global).off("click", this.toggle);
        this.setState({ visible: false });
    }

    @bind
    private toggle(e) {
        if (!this.ignore) {
            this.ignore = true;
            setTimeout(() => this.ignore = false, 20);
            this.setState({ visible: !this.state.visible });
            if (this.state.visible) {
                this.becomeMasterTooltip();
                $(global).on("click", this.hide);
                $(global).on("touchstart", this.hide);
                $(this.toolRef).on("click", this.onClick);
            } else {
                $(global).off("click", this.hide);
                $(global).off("touchstart", this.hide);
                $(this.toolRef).off("click", this.onClick);
            }
            if (e && this.state.visible) {
                this.computePosition(undefined, e.currentTarget);
                this.currentTarget = e.currentTarget;
                e.stopPropagation();
            }
        }
    }

    @bind
    private onClick(e: Event) {
        e.stopPropagation();
    }

    private becomeMasterTooltip() {
        if (global._masterTooltip) {
            if (global._masterTooltip !== this) {
                global._masterTooltip.hide();
            }
        }
        global._masterTooltip = this;
    }

    public render() {
        const { target, mount, event, className, children, content, ...other } = this.props;
        const mounts = this.mounting.split("-");
        mounts[1] = "-" + mounts[1];
        if (typeof (this.props.target) === "function") {
            return null;
        }
        let realContent;
        if (this.currentTarget && content) {
            realContent = content(this.currentTarget);
        } else {
            realContent = children;
        }
        return (
            <Portal>
                <div
                    role="tooltip"
                    ref={(ref) => this.toolRef = ref}
                    className={Class("react-tooltip", this.mounting, ...mounts, className, { visible: this.state.visible && (content || children) !== undefined })}
                    {...other}>
                    {(!this.currentTarget || !this.props.content) && children}
                </div>
            </Portal>
        );
    }
}
export default Tooltip;
