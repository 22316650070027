import "./TemplateGlobals";

import * as Promise from "bluebird";
import * as $ from "jquery";
import * as mobx from "mobx";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { ICompiledPageConfig, IComponentsMapped, IPageJS } from "./PageConfig";

import gearsDialog from "commonui/Dialog";
import Loader from "commonui/Loader";
import lite from "../../modules/LiteLoader";
import PageRenderer from "./PageRenderer";
import toPageJS from "./toPageJS";
import ErrorDisplayComponent from "../ErrorDisplayComponent";
const { observable, extendObservable } = mobx;

declare const global: any;

function getLocationHashVariable(name: string, customWindow: any = window) {
    const m = customWindow.location.hash.match(new RegExp(`${name}=([^&]+)`));
    return m && m[1];
}

class StandalonePage {
    private div: HTMLDivElement;
    @observable private displayPage: { config: ICompiledPageConfig & IComponentsMapped };
    @observable private pageJS: IPageJS;
    @observable private page: any;

    constructor(componentId?: string) {
        global.ErrorDisplayComponent = ErrorDisplayComponent;
        // Load the other stylesheets
        const loadDeferredStyles = () => {
            const addStylesNode = document.getElementById("deferred-styles");
            const replacement = document.createElement("div");
            if (addStylesNode) {
                replacement.innerHTML = addStylesNode.textContent || "";
                document.body.appendChild(replacement);
                addStylesNode.parentElement.removeChild(addStylesNode);
            }
        };
        const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        if (raf) {
            raf(() => { window.setTimeout(loadDeferredStyles, 0); });
        } else {
            window.addEventListener("load", loadDeferredStyles);
        }

        global.tp = this;
        ReactDOM.render(<Loader white animated size={0.75} />, document.getElementById("preloader-icon"));
        this.loadTemplates(componentId);
    }

    private pageUrlFromLocation(customWindow: any = window) {
        const version = getLocationHashVariable("version", customWindow);
        if (version) {
            return `/display_page/${version}`;
        } else {
            const urlMatch = global.location.pathname.match(/\/p\/([^\/]+)\/(([^\/]+)\/)?([^\/]+)/);
            if (!urlMatch) {
                this.errorMessage(new Error("Cannot determine Template Page url"));
                throw new Error("Cannot determine Template Page url");
            }
            const [mat, controller, idgroup, id, identifier] = urlMatch;
            if (!identifier) {
                return `/display_page/${controller}/${id}`;
            }
            return `/display_page/${controller}/${identifier}`;
        }
    }

    private loadTemplates(componentId?: string) {
        const url: string = this.pageUrlFromLocation();

        console.debug("Loading templates", url);

        return $.ajax({
            url,
            dataType: "json",
        }).promise()
            .then(this.checkIfError)
            .then(this.onLoadTemplates)
            .catch(this.errorMessage);
    }

    private checkIfError(data: any) {
        if (typeof data === "string" && data.includes("<!DOCTYPE html>")) {
            throw new Error("PermissionDeniedError");
        }
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    }

    private errorMessage(error: any) {
        console.error(error);
        if (error.responseText.includes("PermissionDeniedError")) {
            gearsDialog({
                buttons: ["Login"],
                children: <div>
                    <p>Please note: You may need to login to access this page</p>
                    <p>If you are already logged in and believe you should be able to see this resource please email support</p>
                </div>,
                className: "width-50p width-600",
                icon: "error",
                title: "Login Required",
                width: "",
            }).then(() => {
                const url = btoa(location.toString());
                window.location.assign(`/login?redirect=${url}`);
            });
        } else {
            let errorMessage = error.responseText || error.toString();
            if (error.title) {
                errorMessage = `${error.title} - ${error.message}`;
            }
            if (error.responseJSON) {
                errorMessage = `${error.responseJSON.title} - ${error.responseJSON.message}`;
            }
            gearsDialog({
                buttons: ["OK"],
                children: (
                    <div>
                        <p>Unable to load page</p>
                        <div>{errorMessage}</div>
                    </div>
                ),
                className: "width-50p width-600",
                icon: "error",
                title: "Error",
                width: "",
            });
        }
    }

    private onLoadTemplates = (displayPage: any) => {
        console.debug("Loaded templates", displayPage);
        this.displayPage = displayPage;
        Promise.try<any>(() => {
            return (this.displayPage.config.state === "compiled" ? null : lite.templates);
        })
            .catch((e) => {
                console.error(e);
            })
            .then(() => {
                const { config } = this.displayPage;
                this.pageJS = toPageJS(config);
                this.runPreload();
            });
    };

    private runPreload() {
        this.page = observable({ loaded: false, errors: [] });
        const preload: (page: any, getComponent: any) => Promise<any> = this.pageJS.preload as () => Promise<any>;
        console.log("[STARTUP]", "Loading Data");
        const start = performance.now();
        Promise.resolve(preload ? preload(this.page, this.getComponent) : this.loadData())
            .then((data) => {
                console.log("[STARTUP]", `Finished loading data in ${(performance.now() - start).toFixed(0)}ms`);
                return data;
            })
            .then(this.checkIfError)
            .then(this.onLoadData)
            .catch(this.errorMessage);
    }

    public loadData() {
        let url: string;
        let { dataSource, gearsRoute } = this.pageJS;
        const urlMatch = global.location.pathname.match(/\/p\/([^\/]+)\/(([^\/]+)\/)?([^\/]+)/);
        if (!urlMatch) {
            throw new Error("Cannot determine Data URL");
        }
        const [mat, controller, idgroup, id, versionIdentifier] = urlMatch;
        gearsRoute = gearsRoute || controller;
        if (id) {
            url = `/${gearsRoute}/${id}${dataSource ? `/${dataSource}` : ``}.json`;
        } else {
            url = `/${gearsRoute}/${dataSource}.json`;
        }

        return $.ajax({
            url,
            dataType: "json",
        });
    }

    private onLoadData = (data: any) => {
        // Add data
        console.log("[STARTUP]", "raw data", data);
        extendObservable(this.page, { data });
        global.page_data = this.page.data || {};
        global.pageData = global.page_data;
        global.page = this.page;
        console.log("[STARTUP]", "observable data", this.page);
        this.render();
        this.page.loaded = true;
    };

    private getComponent(n: string) {
        return global[n];
    }

    private render() {
        const $preloader = $("#preloader");
        const div = document.createElement("div");
        div.style.transition = "opacity 600ms";
        div.style.opacity = "0";
        document.body.appendChild(div);
        setTimeout(() => {
            $preloader.css({ backgroundColor: "unset" });
            div.style.opacity = "1";
        }, 1);
        setTimeout(() => {
            if ($preloader) {
                $preloader.remove();
            }
        }, 600);
        ReactDOM.render(
            <PageRenderer
                pageJS={this.pageJS}
                pageData={this.page.data}
                renderWindow={window}
                getComponent={this.getComponent}
                page={this.page}
            />
            , div);
    }
}

export default StandalonePage;
