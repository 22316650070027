import * as React from "react";

import * as classnames from "classnames";
import * as styles from "./ActionItem.scss";
import Icon from "./Icon";
import { Menu, MenuItem } from "./Menu";
import { IMenuItemProps } from "./Menu/MenuItem";
import Tooltip from "./Tooltip";

interface IActionItemProps {
    title: string;
    showAsIcon?: "ifRoom" | "never" | "always";
    disabled?: boolean;
    menu?: IMenuItemProps[];
    className?: string;
    index?: number;
    icon: string | object;
    onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}

interface IActionItemRenderProps extends IActionItemProps {
    type: "icon" | "menu";
}

export default class ActionItem extends React.PureComponent<IActionItemProps, never> {
    public render() {
        const { type } = this.props as IActionItemRenderProps;
        return type === "icon" ? this.renderIcon() : this.renderMenu();
    }

    private generateMenu(menu: IMenuItemProps[]) {
        return (
            <Menu target={`#actionbar-item-${this.props.index}`}
                mount="below-left">
                {menu.map((item) => {
                    return (
                        <MenuItem
                            key={item.title + item.icon}
                            {...item} />
                    );
                })}
            </Menu>
        );
    }

    public renderIcon() {
        const { index, icon, onClick, disabled, title, menu, ...other } = this.props;
        return (
            <span
                id={`actionbar-item-${this.props.index}`}
                tabIndex={0}
                onTouchStart={() => { }}
                onClick={!disabled ? this.props.onClick : undefined}
                className={classnames({ [styles.disabled]: disabled })}
                {...other}>
                <Icon
                    icon={this.props.icon}
                />
                {!menu && !disabled && <Tooltip
                    target={`#actionbar-item-${this.props.index}`}
                    mount="below-center"
                    event="hover">
                    {this.props.title}
                </Tooltip>}
                {menu && this.generateMenu(menu)}
            </span>
        );
    }

    public renderMenu() {
        return (
            <MenuItem
                title={this.props.title}
                icon={this.props.icon}
                disabled={this.props.disabled}
                onClick={this.props.onClick} />
        );
    }
}
