import * as Class from "classnames";
import { bind } from "decko";
import { action, IObservableObject, IObservableValue, isObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styles from "./Checkbox.scss";
import Icon from "./Icon";

const defaultProps = {
    enabled: true,
};

interface IBooleanObservable {
    [key: string]: boolean;
}

interface IBaseCheckboxProps {
    className?: string;
    disabled?: boolean;
    enabled?: boolean;
    label?: string;
    name?: string;
    onChange?: (value: boolean) => void;
}

interface IStandardCheckboxProps extends IBaseCheckboxProps {
    observable?: never;
    value: boolean | IObservableValue<boolean>;
}

interface IObservableCheckboxProps extends IBaseCheckboxProps {
    observable: IBooleanObservable;
    value: string;
}

type CheckboxProps = IStandardCheckboxProps | IObservableCheckboxProps;

function hasObservable(arg: any): arg is IObservableCheckboxProps {
    return arg.observable !== undefined;
}

interface CheckboxState {
    focused: boolean;
}

/**
 * A react style checkbox that is fully customizable from CSS
 * The actual checkbox is hidden and laid over the top
 */
@observer
export default class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
    public static defaultProps = defaultProps;

    constructor(props: CheckboxProps) {
        super(props);

        this.state = { focused: false };
    }

    @bind
    @action
    private onClick(e: React.MouseEvent<HTMLInputElement>) {
        e.stopPropagation();
        if (hasObservable(this.props)) {
            this.props.observable[this.props.value] = !this.props.observable[this.props.value];
            if (this.props.onChange) {
                this.props.onChange(this.props.observable[this.props.value]);
            }
            return;
        }
        if (isObservable(this.props.value)) {
            const value = this.props.value as IObservableValue<boolean>;
            value.set(!value.get());
            if (this.props.onChange) {
                this.props.onChange(value.get());
            }
            return;
        }
        if (this.props.onChange) {
            this.props.onChange(!this.props.value);
        }
    }

    @bind
    private onFocus() {
        this.setState({ focused: true });
    }

    @bind
    private onBlur() {
        this.setState({ focused: false });
    }

    public render() {
        const { name, observable, value, onChange, className, enabled, disabled, label, ...other } = this.props;
        const realDisabled = disabled || !enabled;
        let realValue = value;
        if (hasObservable(this.props)) {
            realValue = this.props.observable[this.props.value];
        } else {
            if (isObservable(value)) {
                realValue = (value as IObservableValue<boolean>).get();
            }
        }
        realValue = realValue as boolean;

        const classNames = Class(styles.checkbox, "checkbox", className, {
            [styles.active]: realValue, [styles.disabled]: realDisabled, [styles.focused]: this.state.focused,
            active: realValue, disabled: realDisabled, focused: this.state.focused,
        });
        return (
            <span className={classNames} onClick={realDisabled ? undefined : this.onClick} {...other}>
                <div>
                    <Icon icon="fa-check" />
                </div>
                {label ?
                    (<span className={Class(styles.label, "label")}>{label}</span>)
                    : null}
                <input
                    type="checkbox"
                    name={name}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    value={realValue ? 1 : 0}
                    disabled={realDisabled}
                    onClick={realDisabled ? undefined : this.onClick}
                />
            </span>
        );
    }
}

// Legacy support
export { Checkbox as MobXCheckbox };
