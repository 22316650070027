exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "ul._2J_yjhYPPH0iUiKQd1W8JV{margin:0px;padding:0px;list-style:none}\n", ""]);

// exports
exports.locals = {
	"list": "_2J_yjhYPPH0iUiKQd1W8JV"
};