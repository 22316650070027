import * as Class from "classnames";
import * as $ from "jquery";
import * as PropTypes from "prop-types";
import * as React from "react";

import Icon from "../Icon";
import CardTab from "./CardTab";

const contextTypes = {
    colour: PropTypes.string,
};

interface ICardTabsProps extends React.HTMLProps<HTMLElement> {
    small?: boolean;
}

interface ICardTabsState {
    tab: number;
}

/**
 * CardTabs controls multiple CardTab children and manages swapping between them
 */
export default class CardTabs extends React.Component<ICardTabsProps, ICardTabsState> {
    private static contextTypes = contextTypes;
    private oldTab: number = 0;
    private tabContainerRef: HTMLElement;
    private tabRef: HTMLElement;

    constructor(props: ICardTabsProps) {
        super(props);

        this.onChangeTab = this.onChangeTab.bind(this);
        this.resetHeight = this.resetHeight.bind(this);
        this.changeTab = this.changeTab.bind(this);

        this.state = { tab: 0 };
    }

    private onChangeTab(e: any) {
        this.changeTab(parseInt(e.currentTarget.attributes["data-tab"].value, 10));
    }

    public changeTab(tab: number) {
        this.setState({ tab });
        setTimeout(this.resetHeight, 400);
    }

    public componentDidUpdate() {
        const height = $(this.tabRef).height();
        //$(this.tabContainerRef).css({ height, overflow: "hidden" });
    }

    private resetHeight() {
        //$(this.tabContainerRef).css({ height: "", overflow: "" });
    }

    public render() {
        const right = this.oldTab < this.state.tab;
        this.oldTab = this.state.tab;

        const { className, style, children, small, ...other } = this.props;

        let realStyle = style;
        if (this.context.colour) {
            if (!realStyle) {
                realStyle = {};
            }
            realStyle.backgroundColor = this.context.colour;
        }
        const realChildren: any[] = [];
        if (Array.isArray(children)) {
            for (const child of children) {
                if (Array.isArray(child)) {
                    for (const grandChild of child) {
                        if (grandChild) {
                            realChildren.push(grandChild);
                        }
                    }
                } else {
                    if (child) {
                        realChildren.push(child);
                    }
                }
            }
        } else if (children) {
            realChildren.push(children);
        }
        /**
         *  Note: it seems to be the objects with a 'key' that don't work properly.
         *
         */
        const childTabsContent = (
            <div key={this.state.tab} ref={(ref) => this.tabRef = ref}>
                {realChildren[this.state.tab]}
            </div>
        );

        return (
            <div className={Class("card-tabs", className, { coloured: this.context.colour, small })}>
                <ul style={realStyle} {...other}>
                    {realChildren.map((item: CardTab, index: number) => {
                        return (
                            <li
                                key={index}
                                data-tab={index}
                                className={Class({ active: index === this.state.tab })}
                                onClick={this.onChangeTab}>
                                {item.props.icon ? <Icon icon={item.props.icon} colour={item.props.iconColour} small={small} /> : null}
                                <span className={Class("title", { hasSubtitle: item.props.subtitle !== undefined })}>{item.props.title}</span>
                                <span className="subtitle">{item.props.subtitle}</span>
                            </li>
                        );
                    })}
                </ul>
                <div className="card-tabs-content" ref={(ref) => this.tabContainerRef = ref}>
                    {realChildren.map((item, index) => {
                        return (
                            <div key={index} className={Class({ inactive: this.state.tab !== index })}>
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
