import React from "react";

const propTypes = {

};

class PopCard extends React.Component {
    render() {
        return (
            <div ref="card" className="pop-card">
                <div ref="overlay" className="card-overlay" />
                {this.props.children}
            </div>
        );
    }
}

class PopCardHeader extends React.Component {
    render() {
        return (
            <a href={this.props.link}>
                <div className="card-header" style={{ backgroundImage: "url(" + this.props.image + ")" }}>
                    {this.props.title ?
                        <div className="card-header-title">
                            {this.props.title}
                        </div>
                        : null}
                </div>
            </a>
        );
    }
}

class PopCardContent extends React.Component {
    render() {
        return (
            <div className="card-content">
                {this.props.children}
            </div>
        );
    }
}

class PopCardActions extends React.Component {
    render() {
        return (
            <a href={this.props.link}>
                <div className="card-actions">
                    {this.props.children}
                </div>
            </a>
        );
    }
}

PopCard.propTypes = propTypes;
export default PopCard;
export { PopCard, PopCardHeader, PopCardContent, PopCardActions };
