import * as numeral from "numeral";
import  {Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, registerCalculationType} from "./Calculator";

export interface IRawValueOptions extends ICalculationOptions {
    computation_type: "raw_value";
    raw_value: string | number;
}

export default class RawValue extends Calculator {
    public raw_value: any;
    constructor(options: IRawValueOptions) {
        super(options);
        this.raw_value = numeral(options.raw_value);
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> {
        const value = inputs.baseValue;
        const rawResult = this.raw_value.value();
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = formattedResult;
        return {
            calculation_type: this.calculation_type,
            calculation,
            formattedResult,
            percentage: "",
            inputValue: numeral(value).format(),
            result: result.value(),
            rawResult,
            rounding: this.roundFormula("" + rawResult),
            resultUnit: "$",
        };
    }
}

registerCalculationType("raw_value", RawValue);
registerCalculationType("raw_input", RawValue);
