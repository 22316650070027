import * as _ from "lodash";
import * as numeral from "numeral";
import {ParseError, WebfrontException} from "../gears/Errors";
import {Calculator, ICalculationOptions, ICalculationResult} from "./Calculator";

export interface ITierSpec {
    tier: string | number | null;
    upper_value: number | string | null;
    lower_value: number | string | null;
    percentage: number | string;
}

export interface ITier {
    tier: number | null;
    upper_value: number | null;
    lower_value: number | null;
    percentage: number;
}

export function parseTier(tierSource: ITierSpec): ITier {
    const tier = tierSource.tier == null ? null : numeral(tierSource.tier as string).value();
    const upper_value = tierSource.upper_value == null ? null : numeral(tierSource.upper_value as string).value();
    const lower_value = tierSource.lower_value == null ? null : numeral(tierSource.lower_value as string).value();
    const percentage = tierSource.percentage == null ? null : numeral((tierSource.percentage + "").replace(/%/, "")).value();
    return { tier, upper_value, lower_value, percentage };
}

export class InvalidTiersSpecification extends ParseError {
}

export function parseTiers(tierSpecs: Array<ITier | ITierSpec>): ITier[] {
    if (!tierSpecs || tierSpecs.length === 0) {
        throw new InvalidTiersSpecification("No tiers supplied");
    }
    const tiers: ITier[] = _.sortBy(_.map(tierSpecs, parseTier), (t) => t.upper_value == null ? Number.MAX_VALUE : t.upper_value);

    for (let i = 0, nextLower = 0, lastTierNo = -Infinity; i < tiers.length; i++) {
        const current = tiers[i];
        if (current.lower_value != null) {
            if (current.lower_value !== nextLower) {
                throw new InvalidTiersSpecification(`(Tier ${current.tier}).lower_value: Expected ${nextLower} found ${current.lower_value}`);
            }
        } else {
            current.lower_value = nextLower;
        }

        if (current.tier == null) {
            current.tier = i + 1;
        }
        if (current.tier <= lastTierNo) {
            throw new InvalidTiersSpecification(`Tier numbers not in order.`);
        }

        if (i === tiers.length - 1) {// Last entry
            if (current.upper_value != null) {
                throw new InvalidTiersSpecification(`Final tier (Tier ${current.tier}).upper_value is required to be empty. Found ${current.upper_value}`);
            }
        } else {
            if (current.upper_value == null) {
                throw new InvalidTiersSpecification(`(Tier ${current.tier}).upper_value is missing. Expected > ${current.lower_value}`);
            } else if (current.upper_value <= current.lower_value) {
                throw new InvalidTiersSpecification(`(Tier ${current.tier}).upper_value is invalid. Expected > ${current.lower_value}. Found ${current.upper_value}.`);
            }
            nextLower = current.upper_value;
            lastTierNo = current.tier;
        }
    }
    return tiers;
}

export interface ITiersOptions extends ICalculationOptions {
    tiers: ITierSpec[];
}

export interface ITiersResult extends ICalculationResult {
    tier: ITier;
}

export class Tiers extends Calculator {
    public readonly tiers: ITier[];

    constructor(options: ITiersOptions) {
        super(options);
        this.tiers = parseTiers(options.tiers);
    }

    public findTier(value: number): ITier {
        return this.tiers[this.findTierIndex(value)];
    }

    public findTierIndex(value: number): number {
        function withinOrNulls(tier: ITier) {
            const lowerValid = tier.lower_value == null || value >= tier.lower_value;
            const upperValid = tier.upper_value == null || value <= tier.upper_value;
            return lowerValid && upperValid;
        }
        const index = _.findIndex(this.tiers, withinOrNulls);
        if (index >= 0) {
            return index;
        } else {
            throw new WebfrontException("Tier not found");
        }
    }
}
