import * as React from "react";
import styles from "./FloatingActionButton.scss";

import Icon from "./Icon";

interface FloatingActionButtonProps {
    icon: string;
}

export default class FloatingActionButton extends React.PureComponent<FloatingActionButtonProps, never> {
    public render() {
        const { icon, ...other } = this.props;
        return (
            <button className={styles.fab} {...other}>
                <Icon icon={icon} />
            </button>
        );
    }
}
