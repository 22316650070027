import * as React from "react";
import * as ReactDOM from "react-dom";

/**
 * Portal is a way to insert React components directly into the body of the page
 * ignoring hierachy
 */
class Portal extends React.Component<React.HTMLProps<HTMLElement>, never> {
    private targetElement: HTMLDivElement;

    public componentDidMount() {
        const targetElement = document.createElement("div");
        document.body.appendChild(targetElement);
        this.targetElement = targetElement;
        this.componentDidUpdate();
    }

    public componentDidUpdate() {
        ReactDOM.unstable_renderSubtreeIntoContainer(this,
            <div>
                {this.props.children}
            </div>,
            this.targetElement,
        );
    }

    public componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.targetElement);
        if (this.targetElement.remove) {
            this.targetElement.remove();
        } else if (this.targetElement.parentNode) {
            this.targetElement.parentNode.removeChild(this.targetElement);
        }
    }

    public render() {
        return null;
    }
}

export default Portal;
