import * as Class from "classnames";
import * as React from "react";
import * as styles from "./ActionTabs.scss";

interface IActionTabProps {
    title: string;
}

export default class ActionTab extends React.Component<IActionTabProps, never> {
    public render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}
