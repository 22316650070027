import * as Class from "classnames";
import * as React from "react";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { find, findIndex } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { IColumn, IColumnTooltip } from "./SmartGrid";

export interface IHeaderProps {
    columns: IColumn[];
    sort?: (e: React.MouseEvent<HTMLElement>) => void;
    group?: (e: React.MouseEvent<HTMLElement>) => void;
    sortedField?: string;
    sortOrder?: "asc" | "desc";
    groupBy?: string;
    inlineGroups?: boolean;
    lockGrouping?: boolean;
    lockSorting?: boolean;
}

function idString(str: string) {
    return (str.toString()).replace(new RegExp(/[ .+-/!/@/#/$/%/^/&/*/;/:/?/|<>/\\(\)\'\"]/, "g"), "_").toLowerCase();
}

function isTooltipArray(arg: any): arg is IColumnTooltip[] {
    return Array.isArray(toJS(arg));
}

@observer
export default class Header extends React.Component<IHeaderProps, never> {
    private renderColumn(column: IColumn, index: number) {
        const { sort, group, sortedField, sortOrder, groupBy, lockGrouping, lockSorting } = this.props;
        const tooltipArray = column.tooltip ? isTooltipArray(column.tooltip) ? column.tooltip : [column.tooltip] : undefined;
        const groupClass =
            column.groupable && !lockGrouping ?
                "groupable"
                : undefined;
        const sortClass =
            column.sortable && !lockSorting ?
                column.field === sortedField ?
                    (sortOrder === "asc" || sortOrder === undefined) ?
                        "sort-up"
                        : "sort-down"
                    : "sort"
                : undefined;
        return (
            <th
                key={index}
                className={Class("column-title", column.className, { hasTooltips: tooltipArray !== undefined, groupable: groupClass !== undefined, sortable: sortClass !== undefined, ["hidden-cell"]: column.name === "" || column.name === undefined })}
                style={{ textAlign: column.alignment }}>
                {column.icon ? <Icon small icon={column.icon} /> : null}
                <span style={{ width: column.width }}>{column.iconOnly ? null : column.name}</span>
                <span className="control-container">
                    {groupClass &&
                        [<span
                            key={"smartgrid_g_" + idString(column.field)}
                            id={"smartgrid_g_" + idString(column.field)}
                            onClick={group}
                            data-group={column.field} >
                            <FontAwesomeIcon
                                icon="object-group"
                            />
                        </span>,
                        <Tooltip
                            key={"smartgrid_gt_" + idString(column.field)}
                            event="hover"
                            target={"#smartgrid_g_" + idString(column.field)}
                            mount="below-center">
                            Group by column
                        </Tooltip>,
                        ]}
                    {sortClass &&
                        [<span
                            key={"smartgrid_s_" + idString(column.field)}
                            id={"smartgrid_s_" + idString(column.field)}
                            onClick={sort}
                            data-column={index}>
                            <FontAwesomeIcon
                                icon={sortClass}
                            />
                        </span>,
                        <Tooltip
                            key={"smartgrid_st_" + idString(column.field)}
                            event="hover"
                            target={"#smartgrid_s_" + idString(column.field)}
                            mount="below-center" >
                            Sort by column
                        </Tooltip>,
                        ]}
                </span>
                {tooltipArray &&
                    <span className="tooltip-container">
                        {tooltipArray.map((tooltip, tooltipIndex: number) => {
                            return (
                                <Icon
                                    key={tooltip.icon + "_" + tooltipIndex}
                                    icon={tooltip.icon}
                                    className={tooltip.className}
                                    small
                                    fixedWidth
                                    data-tooltip={tooltip.value}
                                    data-tooltip-mount="below-center" />
                            );
                        })}
                    </span>}
            </th>
        );
    }

    public render() {
        const { columns, sort, sortedField, sortOrder, groupBy, inlineGroups } = this.props;
        return (
            <tr>
                {columns.map((column, index) => {
                    if (groupBy && column.field === groupBy) {
                        return;
                    }
                    if (column.spacer) {
                        return <th key={index} className="spacer" />;
                    } else {
                        return this.renderColumn(column, index);
                    }
                })}
            </tr>
        );
    }
}
