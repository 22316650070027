import "./Icon.scss";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import * as Class from "classnames";
import * as React from "react";
import { getFaIcon } from "../../gears/helpers/fa";

type Flips = "horizontal" | "vertical" | "both";
type Rotation = 0 | 90 | 180 | 270;

enum IconTypes {
    FontAwesome = 0,
    MaterialDesign = 1,
}

interface IIconProps extends React.HTMLProps<HTMLElement> {
    background?: string;
    className?: string;
    colour?: string;
    fixedWidth?: boolean;
    flip?: Flips;
    icon: string | object;
    large?: boolean;
    rotate?: Rotation;
    small?: boolean;
    style?: any;
    x2?: boolean;
    x3?: boolean;
    x4?: boolean;
    x5?: boolean;
}

/**
 * A react based icon loader that supports dynamically switching between multiple icon sources
 */
export default class Icon extends React.Component<IIconProps, never> {
    private iconType: IconTypes;

    /**
     * @param props.icon - Icon name to display (currently supports FontAwesome and Material-Icons)
     * @param props.className - Additional classnames
     * @param props.style - Additional styles
     * @param props.small - Small version of icon
     * @param {boolean} [props.large] - Large version of icon
     * @param {boolean} [props.x2] - Double icon size
     * @param {boolean} [props.x3] - Triple icon size
     * @param {boolean} [props.x4] - Quadruple icon size
     * @param {boolean} [props.x5] - Quintuple icon size
     * @param {boolean} [props.fixedWidth] - Fixed width icons regardless of icon size
     * @param {number} [props.rotate] - Rotation of the icon (accepted values are 0, 90, 180 and 270)
     * @param {string} [props.flip] - Whether to flip the icon (`flip-horizontal` for horizontal, `flip-vertical` for vertical)
     * @param {string} [props.colour] - Colour to apply to the icon, any CSS compatible string is accepted
     * @param {string} [props.background] - Colour to apply to the icon background, any CSS compatible string is accepted
     */
    constructor(props: IIconProps) {
        super(props);
        this.componentWillReceiveProps(this.props);
    }

    public componentWillReceiveProps(nextProps: IIconProps) {
        if (nextProps.icon && typeof (nextProps.icon) === "string") {
            const type = nextProps.icon.substring(0, 2);
            if (type === "fa") {
                this.iconType = IconTypes.FontAwesome;
            }
            if (type === "md") {
                this.iconType = IconTypes.MaterialDesign;
            }
        }
    }

    public render() {
        const { small, large, x2, x3, x4, x5, fixedWidth, icon, className, rotate, flip, colour, background, style, children, ...other } = this.props;
        if (!icon) {
            return null;
        }
        let newStyle = style;
        let size;
        if (small) { size = "xs" }
        if (large) { size = "lg" }
        if (x2) { size = "2x" }
        if (x3) { size = "3x" }
        if (x4) { size = "4x" }
        if (x5) { size = "5x" }
        if (!newStyle && (colour || background)) {
            newStyle = {};
        }
        if (colour) {
            newStyle.color = colour;
        }
        if (background) {
            newStyle.backgroundColor = background;
        }

        if (typeof icon === "object") {
            return (
                <FontAwesomeIcon
                    className={Class("gear-icon", className)}
                    icon={icon}
                    size={size}
                    flip={flip}
                    rotation={rotate}
                    style={newStyle}
                    {...other} />
            );
        }
        if (this.iconType === IconTypes.FontAwesome) {
            return <FontAwesomeIcon
                className={Class("gear-icon", className)}
                icon={getFaIcon(icon)}
                style={newStyle}
                size={size}
                flip={flip}
                rotation={rotate}
                {...other} />;
        }
        // For legacy this is kept here, to show pages that haven't been updated
        if (this.iconType === IconTypes.MaterialDesign) {
            console.warn("MaterialDesign icons is deprecated");
            return (
                <i
                    className={Class("gear-icon", "material-icons", className)}
                    style={newStyle}
                    {...other}>
                    {icon.substring(3, icon.length)}
                </i>
            );
        }
        return null;
    }
}
