import * as Class from "classnames";
import * as React from "react";
import "./Loader.scss";

function radians(degrees: number) {
    return degrees * Math.PI / 180;
}

interface ILoaderProps {
    animated?: boolean;
    size?: number;
    white?: boolean;
}

/**
 * A loader that displays the WebFrontGears logo as a spinner
 */
export default class Loader extends React.Component<ILoaderProps, never> {
    public static defaultProps: any = { size: 1 };

    private renderCircle(progress: number) {
        const radius = 50;
        const points = 32;
        const point = [];
        point.push(0, 0);
        for (let i = 0; i <= points * progress; i++) {
            const rads = radians(-90 + (i / points) * 360);
            point.push((Math.cos(rads) * radius).toFixed(4));
            point.push((Math.sin(rads) * radius).toFixed(4));
        }
        return point;
    }

    public render() {
        const points = this.renderCircle(0.125);
        const gearPadding = 15;
        const outerSize = 45 * (this.props.size || 1);
        const innerSize = 30 * (this.props.size || 1);
        const mx = 0;
        const my = 0;
        const numPoints = 6;
        const newAngle = 360 / numPoints;
        const polygons = [];
        for (let i = 0; i < 1; i++) {
            const p = [];
            p[0] = (mx + Math.cos(radians(90 - (gearPadding + 3.5) + newAngle * i)) * outerSize).toFixed(4);
            p[1] = (my - Math.sin(radians(90 - (gearPadding + 3.5) + newAngle * i)) * outerSize).toFixed(4);
            p[2] = (mx + Math.cos(radians(90 + (gearPadding + 3.5) + newAngle * i)) * outerSize).toFixed(4);
            p[3] = (my - Math.sin(radians(90 + (gearPadding + 3.5) + newAngle * i)) * outerSize).toFixed(4);

            p[4] = (mx + Math.cos(radians(90 + gearPadding + newAngle * i)) * innerSize).toFixed(4);
            p[5] = (my - Math.sin(radians(90 + gearPadding + newAngle * i)) * innerSize).toFixed(4);
            p[6] = (mx + Math.cos(radians(90 - gearPadding + newAngle * i)) * innerSize).toFixed(4);
            p[7] = (my - Math.sin(radians(90 - gearPadding + newAngle * i)) * innerSize).toFixed(4);
            polygons.push(<polygon key={i} points={p.join(",")} />);
        }
        const gear = (
            <svg
                width={140 * (this.props.size || 1)}
                height={140 * (this.props.size || 1)}>
                <g style={{ transform: `translate(${Math.floor(70 * (this.props.size || 1))}px, ${Math.floor(70 * (this.props.size || 1))}px)` }}>
                    <g className="first-gear part">
                        <g className="gear-background" style={{ transform: "rotate(0deg)" }}>{polygons}</g>
                    </g>
                    <g className="second-gear part">
                        <g className="gear-background" style={{ transform: "rotate(60deg)" }}>{polygons}</g>
                    </g>
                    <g className="third-gear part">
                        <g className="gear-background" style={{ transform: "rotate(120deg)" }}>{polygons}</g>
                    </g>
                    <g className="fourth-gear part">
                        <g className="gear-background" style={{ transform: "rotate(180deg)" }}>{polygons}</g>
                    </g>
                    <g className="fifth-gear part">
                        <g className="gear-background" style={{ transform: "rotate(240deg)" }}>{polygons}</g>
                    </g>
                    <g className="sixth-gear part">
                        <g className="gear-background" style={{ transform: "rotate(300deg)" }}>{polygons}</g>
                    </g>
                </g>
            </svg>
        );
        return (
            <div>
                <div className={Class("gear-container", { white: this.props.white, animated: this.props.animated })}>
                    {gear}
                </div>
            </div>
        );
    }
}
