import {roundTo} from "./round";
export type RoundTypes = "up" | "down" | "round" | false;
import * as _ from "lodash";
import * as numeral from "numeral";
import {WebfrontError} from "../gears/Errors";
// import ComposeInputs from "./ComposeInputs";

export class CalculationError extends WebfrontError {
}

export const NUMBER_FORMATS  = {
    percentFormat: "0,0[.]0[0]%",
    currencyFormat: "$0,0[.]00",
    numberFormat: "0,0[.]00",
    multiplication: "&times;",
};
numeral.defaultFormat(NUMBER_FORMATS.numberFormat);

export interface ICalculationOptions {
    round?: RoundTypes;
    precision?: number;
    calculation_type: string;
    compose?: any;
    modify?: string;
    unit?: string;
}

export interface ICalculationResult {
    calculation_type: string;
    calculation: string;
    formattedResult: string;
    inputs: ICalculationInputs;
    percentage: string;
    rawResult: number;
    result: number;
    resultUnit: string;
    rounding: string;
}

export interface ICalculationError extends Partial<ICalculationResult> {
    error: Error;
}

export interface ICalculationInputs {
    baseValue: number;
}

export interface IDeprecatedCalculationInputs {
    lineValue?: number;
    groupValue?: number;
}

function parseAdd(input: string) {
    const match = input.match(/^(\w+)(?:\s*\+\s*(\w+))?(?:\s*\+\s*(\w+))?(?:\s*\+\s*(\w+))?(?:\s*\+\s*(\w+))?$/);
    if (!match) {
        return null;
    }

    return {
        calculation_type: "add",
        values: _(match).slice(2).compact().map((i) => parseFloat(i) || i).value(),
    };
}

function parseEvalWith(input: string) {
    return {
        calculation_type: "evalWith",
        code: input,
    };
}

export function parseInput(input: string, spec: any): any {
    const equationMatch = input.match(/^\s*(\w+)\s*=\s*(.+)$/);
    if (!equationMatch) {
        return null;
        // throw new Error("Invalid modificiation options: " + input);
    }
    const inputName = equationMatch[1];
    const expression = equationMatch[2];
    spec[inputName] = /*parseAdd(expression) ||*/ parseEvalWith(expression);
    return spec;
}

export class Calculator {
    public readonly calculation_type: string;
    public readonly options: ICalculationOptions;
    public readonly precision: number;
    public readonly round: RoundTypes;

    constructor(options: ICalculationOptions) {
        this.options = options;
        if (options.round == null) {
            options.round = "round";
        }
        if (options.unit == null) {
            options.unit = "$";
        }
        if (options.precision == null) {options.precision = 2; }
        this.precision = options.precision;
        this.calculation_type = options.calculation_type;
    }

    public calculate(inputsOrValue: number | ICalculationInputs): ICalculationResult {
        const inputs: ICalculationInputs = (typeof inputsOrValue === "number") ? {baseValue: inputsOrValue} : inputsOrValue as ICalculationInputs;
        inputs.baseValue = inputs.baseValue || (inputs as any).lineValue;
        const result: ICalculationResult = this.injectResultExtras(this.calculationFunction(inputs), inputs);
        return result;
    }

    public injectResultExtras(rawResultObject: number | Partial<ICalculationResult>, inputs: ICalculationInputs): ICalculationResult {
        const result: Partial<ICalculationResult> = (typeof rawResultObject === "object") ? rawResultObject : {rawResult: rawResultObject};

        if (result.calculation_type == null) {
            result.calculation_type = this.calculation_type;
        }
        const rawResult = result.rawResult as number;
        const resultN = (result.result == null) ? numeral(this.roundValue(rawResult)) : numeral(result.result);
        if (result.result == null) {
            result.result = resultN.value();
        }
        if (result.formattedResult == null) {
            result.formattedResult = resultN.format(this.roundFormat);
        }
        if (result.inputs == null) {
            result.inputs = inputs;
        }
        if (result.resultUnit == null) {
            result.resultUnit = "$";
        }
        if (result.rounding == null) {
            result.rounding = this.roundFormula("" + rawResult);
        }
        return result as ICalculationResult;
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> | number {
        throw new CalculationError("Calculator not implemented.");
    }

    public tryCalculate(value: number): ICalculationResult | ICalculationError {
        try {
            return this.calculate(value);
        } catch (e) {
            return {error: e, inputs: {}, calculation_type: this.calculation_type};
        }
    }

    public roundValue(value: number): number {
        if (!this.round) {
            return value;
        } else {
            return roundTo(this.round, value, this.precision);
        }
    }

    public roundFormula(text: string) {
        if (!this.round) {
            return text;
        } else {
            const fn = this.round === "round" ? "round" : `round${_.upperFirst(this.round)}`;
            return `${fn}(${text}, ${this.precision})`;
        }
    }

    public get roundFormat() {
        let format = `${this.options.unit}0,0`;
        if (this.precision > 0) {
            format = format + "[.]" + _.repeat("0", this.precision);
        }
        return format;
    }

    private static calculationTypes: { [key: string]: typeof Calculator; } = {};

    public static registerCalculationType(calculationType: string, calculatorClass: typeof Calculator) {
        Calculator.calculationTypes[calculationType] = calculatorClass;
    }

    private static createComposedCalculator(options: ICalculationOptions): Calculator {
        if (options.compose.input) {
            options.compose = [options.compose];
        }
        return new Calculator.calculationTypes.compose(options);
    }

    private static createModifiedCalculator(options: ICalculationOptions): Calculator {
        const inputs: any = {};
        _.each(options.modify.split(/;|\n/), (input) => parseInput(input, inputs));

        options.modify_was = options.modify;
        options.modify = undefined;
        options.compose = inputs;

        return new Calculator.calculationTypes.compose(options);
    }

    public static createCalculator(options: ICalculationOptions): Calculator {
        if (options.compose) {
          return Calculator.createComposedCalculator(options);
        } else if (options.modify) {
          return Calculator.createModifiedCalculator(options);
        } else {
          return new Calculator.calculationTypes[options.calculation_type](options);
        }
    }

    public static runCalculation(options: ICalculationOptions, value: any) {
        return Calculator.createCalculator(options).calculate(value);
    }

    public static tryCalculation(options: ICalculationOptions, value: any) {
        try {
            return Calculator.createCalculator(options).calculate(value);
        } catch (e) {
            return {error: e, inputValue: value, calculation_type: options.calculation_type};
        }
    }
}

export const createCalculator = Calculator.createCalculator;
export const runCalculation = Calculator.runCalculation;
export const tryCalculation = Calculator.tryCalculation;
export const registerCalculationType = Calculator.registerCalculationType;
