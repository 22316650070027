import React from "react";
import Button from "commonui/Button";
import PropTypes from "prop-types";

const contextTypes = {
    submitted: PropTypes.bool,
    form: PropTypes.object,
};

class FormEntry extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        if (props.canSubmit) {
            // Force browser to display warning if leaving form
            window.onbeforeunload = function() { return "asdf"; };
        }
    }

    onSubmit() {
        global.form.setSubmitted(this.props.onSubmit());
    }
    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    componentWillUpdate() {
        if (this.props.canSubmit && !this.context.form.state.submitted) {
            window.onbeforeunload = function() { return "asdf"; };
        } else {
            window.onbeforeunload = null;
        }
    }

    render() {
        if (this.context.submitted) {
            return null;
        }
        global.form = this.context.form;
        return (
            <div>
                {this.props.children}
                <div className="submit-area">
                    {this.props.canSubmit ?
                        <div className="submit-container">
                            <Button
                                raised
                                colored
                                ripple
                                className="light-blue"
                                onClick={this.onSubmit}
                                icon="fa-paper-plane"
                                title="Submit" />
                        </div>
                        : null}
                </div >
            </div>
        );
    }
}

FormEntry.contextTypes = contextTypes;
export default FormEntry;
