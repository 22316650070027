import "./MegaFlex.scss";

import * as Class from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as styles from "./Card.scss";

import { observer } from "mobx-react";

const defaultProps = {
    className: null,
    colour: null,
    fullBody: false,
    style: null,
};
const childContextTypes = {
    colour: PropTypes.string,
};

interface ICardProps extends React.HTMLProps<HTMLDivElement> {
    className?: string;
    /** Applies a CSS colour to the card */
    colour?: string;
    /** Applies the colour to the entire body instead of just header area */
    fullBody?: boolean;
    style?: any;
}

/**
 * A material style Card layout that easily adapts to multiple view types
 */
export default class Card extends React.Component<ICardProps, never> {
    public static defaultProps = defaultProps;
    public static childContextTypes = childContextTypes;
    constructor(props: ICardProps) {
        super(props);
    }

    public getChildContext() {
        return { colour: this.props.colour };
    }

    public render() {
        const { style, colour, className, fullBody, children, ...other } = this.props;
        let newStyle = style;
        if (colour && fullBody) {
            if (!newStyle) {
                newStyle = {};
            }
            newStyle.backgroundColor = this.props.colour;
        }
        return (
            <div
                className={Class("card", styles.card, className, { coloured: (colour != null) && fullBody })}
                style={newStyle}
                {...other}>
                {children}
            </div>
        );
    }
}
