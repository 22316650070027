import "./Menu.scss";

import * as Class from "classnames";
import { bind } from "decko";
import * as $ from "jquery";
import * as PropTypes from "prop-types";
import * as React from "react";

import Portal from "../Portal";

const defaultProps = {
    mount: "right-top",
};

const childContextTypes = {
    menu: PropTypes.any,
};
const contextTypes = {
    menu: PropTypes.any,
};

type Mount =
    "right-top" |
    "right-bottom" |
    "right-center" |
    "left-top" |
    "left-bottom" |
    "left-center" |
    "above-left" |
    "above-center" |
    "above-right" |
    "below-left" |
    "below-center" |
    "below-right";

interface IMenuProps extends React.HTMLProps<HTMLElement> {
    target: any;
    mount?: Mount;
    className?: string;
    onOpen?: () => any;
    onClose?: () => any;
}

interface IMenuState {
    visible: boolean;
}

/**
 * A very simple React menu that can position itself and supports
 * many sub menu's
 */
export default class Menu extends React.Component<IMenuProps, IMenuState> {
    private static defaultProps = defaultProps;
    private static childContextTypes = childContextTypes;
    private static contextTypes = contextTypes;
    private menuRef: HTMLDivElement;
    private menuContainerRef: HTMLDivElement;
    private ignore: boolean = false;
    private currentTarget: any;
    private stayOpen?: boolean;

    constructor(props: IMenuProps) {
        super(props);

        this.hide = this.hide.bind(this);
        this.onClick = this.onClick.bind(this);
        this.show = this.show.bind(this);
        this.toggle = this.toggle.bind(this);

        this.state = { visible: false };
    }

    private getChildContext() {
        return { menu: this };
    }

    private computePosition(override?: string, target?: any) {
        if (target && target.getBoundingClientRect) {
            let mount = override || this.props.mount || defaultProps.mount;
            const $target = $(target);
            const $menu = $(this.menuRef);
            const $menuContainer = $(this.menuContainerRef);
            const targetBounds = target.getBoundingClientRect();
            const targetWidth = targetBounds.width;
            const targetHeight = targetBounds.height;
            const menuWidth = Math.max(180, $menu.innerWidth() || 0);
            const menuHeight = $menuContainer.outerHeight() || 0;
            const position = $target.offset();
            let requiresOverride = false;

            let left = 0;
            let top = 0;
            if (mount.indexOf("right-") >= 0) {
                left = position.left + targetWidth - 8;
            }
            if (mount.indexOf("left-") >= 0) {
                left = position.left - menuWidth - 8;
            }
            if (mount.indexOf("above-") >= 0) {
                top = position.top - menuHeight - 8;
            }
            if (mount.indexOf("below-") >= 0) {
                top = position.top + targetHeight - 8;
            }

            if (mount.indexOf("-top") >= 0) {
                top = position.top - 8;
            }
            if (mount.indexOf("-bottom") >= 0) {
                top = position.top + targetHeight - menuHeight;
            }
            if (mount.indexOf("left-") >= 0 || mount.indexOf("right-") >= 0) {
                if (mount.indexOf("-center") >= 0) {
                    top = (position.top + targetHeight / 2) - (menuHeight + 16) / 2;
                }
            }
            if (mount.indexOf("-right") >= 0) {
                left = position.left - 8;
            }
            if (mount.indexOf("-left") >= 0) {
                left = position.left - menuWidth - 8 + targetWidth;
            }
            if (mount.indexOf("above-") >= 0 || mount.indexOf("below-") >= 0) {
                if (mount.indexOf("-center") >= 0) {
                    left = (position.left + targetWidth / 2) - (menuWidth + 16) / 2;
                }
            }
            // Override and reverse mounting if we hit edges of screen
            if (left < 0) {
                mount = mount.replace("left-", "right-");
                requiresOverride = true;
            }
            if (top < 0) {
                mount = mount.replace("above-", "below-");
                requiresOverride = true;
            }
            if (left + menuWidth > $("body").width()) {
                mount = mount.replace("right-", "left-");
                requiresOverride = true;
            }
            if (top + menuHeight > $("body").height()) {
                mount = mount.replace("below-", "above-");
                mount = mount.replace("-top", "-bottom");
                requiresOverride = true;
            }

            if (!requiresOverride || override) {
                if (left + menuWidth > window.innerWidth) {
                    left = window.innerWidth - menuWidth - 16;
                }
                if (mount.indexOf("left") >= 0) {
                    $menuContainer.css({ left: -menuWidth });
                    $menu.css({ top, left: left + menuWidth });
                } else {
                    $menu.css({ top, left });
                }
            } else {
                this.computePosition(mount, target);
            }
        }
    }

    private attach(props: IMenuProps) {
        if (props.target) {
            const $target = $(props.target);
            $target.on("click", this.toggle);
        }
    }

    @bind
    private onWindowResize() {
        this.computePosition(undefined, this.currentTarget);

        const $menu = $(this.menuRef);
        const $menuContainer = $(this.menuContainerRef);
        const mount = this.props.mount || defaultProps.mount;
        const width = Math.max($menu.innerWidth() || 0, 180);

        this.menuRef.style.setProperty("transition", "none");
        if (mount.indexOf("left") >= 0) {
            $menuContainer.css({ left: 0 });
            $menu.css({ left: parseInt($menu.css("left")) - width });
        }
    }

    public componentDidMount() {
        this.attach(this.props);
    }

    public componentWillUnmount() {
        if (this.props.target) {
            const $target = $(this.props.target);
            $target.off("click", this.toggle);
        }
        if (this.props.target) {
            $(this.menuRef).off("click", this.onClick);
        }
    }

    public componentWillReceiveProps(newProps: IMenuProps) {
        if (this.props.target) {
            const $target = $(this.props.target);
            $target.off("click", this.toggle);
        }
        this.attach(newProps);
        this.props = newProps;
    }

    public show(e) {
        this.computePosition(undefined, e.currentTarget);
        this.currentTarget = e.currentTarget;
        this.setState({ visible: true });
        setTimeout(() => { $(global).on("click", this.hide); }, 20);
        window.addEventListener("resize", this.onWindowResize);
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }
    public hide() {
        this.setState({ visible: false });
        $(global).off("click", this.hide);
        window.removeEventListener("resize", this.onWindowResize);
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    public toggle(e?: React.MouseEvent<any>) {
        if (!this.ignore) {
            this.ignore = true;
            setTimeout(() => this.ignore = false, 20);
            if (this.state.visible) {
                window.removeEventListener("resize", this.onWindowResize);
                if (this.props.onClose) {
                    this.props.onClose();
                }
            } else {
                window.addEventListener("resize", this.onWindowResize);
                if (this.props.onOpen) {
                    this.props.onOpen();
                }
            }
            this.setState({ visible: !this.state.visible });
            if (this.state.visible) {
                $(global).on("click", this.toggle);
                $(this.menuRef).on("click", this.onClick);
            } else {
                $(global).off("click", this.toggle);
                $(this.menuRef).off("click", this.onClick);
            }
            if (e && this.state.visible) {
                this.computePosition(undefined, e.currentTarget);
                this.currentTarget = e.currentTarget;
            }
        }
    }

    private onClick(e: React.MouseEvent<any>) {
        e.stopPropagation();
        if (!this.stayOpen) {
            this.toggle();
            this.stayOpen = false;
        }
    }

    public componentDidUpdate(prevProps: IMenuProps, prevState: IMenuState) {
        const $menu = $(this.menuRef);
        const $menuContainer = $(this.menuContainerRef);
        const mount = this.props.mount || defaultProps.mount;
        const width = Math.max($menu.innerWidth() || 0, 180);
        if (!prevState.visible && this.state.visible) {
            this.menuRef.style.setProperty("transition", "height 500ms, left 300ms");
            this.menuRef.style.setProperty("width", `0px`, "important");
            setTimeout(() => {
                if (mount.indexOf("left") >= 0) {
                    $menuContainer.css({ left: 0 });
                    $menu.css({ left: parseInt($menu.css("left")) - width });
                }
                $menu.css({ height: $menuContainer.innerHeight() + 16 });
                this.menuRef.style.setProperty("transition", "");
                this.menuRef.style.setProperty("width", `${width}px`, "important");
            }, 1);
        } else if (!this.state.visible && prevState.visible) {
            const width = $menuContainer.outerWidth() || 0;
            $menu.css({ height: "", width: "" });
            $menuContainer.css({ left: "" });
            if (mount.indexOf("left") >= 0) {
                $menu.css({ left: parseInt($menu.css("left")) + width });
            }
        }
    }

    public render() {
        let display = true;
        if (this.props.target && !this.state.visible) {
            display = false;
        }
        const mount = this.props.target ? this.props.mount : this.context.menu.props.mount;
        const mounts = mount.split("-");
        mounts[1] = "-" + mounts[1];
        const renderedMenu = (
            <div
                onClick={this.onClick}
                className={Class("react-menu", mount, mounts, this.props.className, { showMenu: display })}
                ref={(ref) => this.menuRef = ref}>
                <div ref={(ref) => this.menuContainerRef = ref}>
                    {this.props.children}
                </div>
            </div>
        );
        if (this.props.target) {
            return (
                <Portal>
                    <div style={{ display: display ? "block" : "none", zIndex: 9001, position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }} />
                    {renderedMenu}
                </Portal>
            );
        }
        return renderedMenu;
    }
}
