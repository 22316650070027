import * as Class from "classnames";
import * as moment from "moment-timezone";
import * as React from "react";
import { monthColours, monthColoursDark } from "./Constants";
import Icon from "../Icon";
import * as styles from "./DatePicker.scss";
import { bind } from "decko";

// Saves having to query moment all the time for month names
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

enum View {
    Day,
    Month,
    Year,
}

interface IMonthViewProps {
    date: any;
    onChange: (date: any) => void;
    showYear?: boolean;
    showMonth?: boolean;
    showDate?: boolean;
}
interface IMonthViewState {
    view: View;
}

export default class MonthView extends React.Component<IMonthViewProps, IMonthViewState> {
    private currentDate: any;

    constructor(props: IMonthViewProps) {
        super(props);

        this.currentDate = props.date.clone();
        let view = View.Day;
        if (!props.showDate) {
            view = View.Month;
            if (!props.showMonth) {
                view = View.Year;
            }
        }
        this.state = { view };
    }
    public componentWillReceiveProps(props: IMonthViewProps) {
        this.currentDate = props.date.clone();
    }

    @bind
    private incrementMonth() {
        this.currentDate.add(1, "month");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    @bind
    private decrementMonth() {
        this.currentDate.subtract(1, "month");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    @bind
    private incrementYear() {
        this.currentDate.add(1, "year");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    @bind
    private decrementYear() {
        this.currentDate.subtract(1, "year");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    @bind
    private incrementDecade() {
        this.currentDate.add(11, "year");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    @bind
    private decrementDecade() {
        this.currentDate.subtract(11, "year");
        this.props.onChange(this.currentDate);
        this.forceUpdate();
    }

    private setDayView(month: number) {
        if (this.props.showDate) {
            this.currentDate.month(month);
            this.setState({ view: View.Day });
        } else {
            this.currentDate.month(month);
            this.props.onChange(this.currentDate);
        }
    }

    private setMonthView() {
        this.setState({ view: View.Month });
    }

    private setYearView() {
        this.setState({ view: View.Year });
    }

    @bind
    private setMonthViewFromYear(e: any) {
        this.currentDate.year(e.currentTarget.attributes.data.value);
        this.setState({ view: View.Month });
    }

    @bind
    private setDate(e: any) {
        const date = e.target.attributes.data.value;
        this.currentDate.date(date);
        this.props.onChange(this.currentDate);
    }

    public render() {
        if (this.state.view === View.Year) {
            const years = [];
            const startYear = this.currentDate.year() - 5;
            for (let i = 0; i < 12; i++) {
                let active = false;
                if (startYear + i === this.props.date.year()) {
                    active = true;
                }
                years.push(
                    <div
                        key={i}
                        className={Class(styles.year, { [styles.active]: active })}
                        data={startYear + i}
                        onClick={this.setMonthViewFromYear}>
                        <span>
                            {startYear + i}
                        </span>
                    </div>,
                );
            }
            return (
                <div className={Class(styles.component, styles["year-view"], styles["month-view"])}>
                    <div className={styles["left-chevron"]} onClick={this.decrementDecade}>
                        <Icon icon="fa-chevron-left" fixedWidth />
                    </div>
                    <div className={Class(styles.title, styles.inactive)}>Year</div>
                    <div className={styles["right-chevron"]} onClick={this.incrementDecade}>
                        <Icon icon="fa-chevron-right" fixedWidth />
                    </div>
                    <div className="content years">
                        {years}
                    </div>
                </div>
            );
        } else if (this.state.view === View.Month) {
            return (
                <div className={Class(styles.component, styles["month-view"])}>
                    <div className={styles["left-chevron"]} onClick={this.decrementYear}>
                        <Icon icon="fa-chevron-left" fixedWidth />
                    </div>
                    <div className={styles.title} onClick={() => this.setYearView()}>
                        {this.currentDate.format("YYYY")}
                    </div>
                    <div className={styles["right-chevron"]} onClick={this.incrementYear}>
                        <Icon icon="fa-chevron-right" fixedWidth />
                    </div>
                    <div className={Class(styles.content, styles.months)}>
                        {months.map((item, index) => {
                            let active = false;
                            if (index === this.props.date.month()) {
                                if (this.currentDate.year() === this.props.date.year()) {
                                    active = true;
                                }
                            }
                            return (
                                <div
                                    key={index}
                                    className={Class(styles.month, { [styles.active]: active })}
                                    style={{ background: monthColours[index] }}
                                    onClick={() => this.setDayView(index)}>
                                    <span>
                                        {item}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            const month = [];
            let i = 0;
            const start = this.currentDate.clone().startOf("month").format("d");
            const end = this.currentDate.clone().endOf("month").format("D");
            const currentDay = moment();
            for (let y = 0; y < 6; y++) {
                const week = [];
                for (let x = 0; x < 7; x++) {
                    if (i >= start && i - start < end) {
                        let current = false;
                        if (this.currentDate.month() === currentDay.month()) {
                            if (this.currentDate.year() === currentDay.year()) {
                                if (i - start + 1 === currentDay.date()) {
                                    current = true;
                                }
                            }
                        }
                        let active = false;
                        if (this.currentDate.month() === this.props.date.month()) {
                            if (this.currentDate.year() === this.props.date.year()) {
                                if (i - start + 1 === this.props.date.date()) {
                                    active = true;
                                }
                            }
                        }
                        week.push(<div
                            key={i}
                            style={{
                                background: active ? monthColoursDark[this.currentDate.month()] : null,
                                borderColor: current ? monthColoursDark[this.currentDate.month()] : "transparent",
                            }}
                            className={Class(styles.date, { [styles.active]: active })}
                            data={i - start + 1}
                            onClick={this.setDate}>
                            {(i - start) + 1}
                        </div>);
                    } else {
                        week.push(<div key={i} className={Class(styles.date, styles.empty)}>&nbsp; </div>);
                    }
                    i++;
                }
                month.push(<div key={i}>{week}</div>);
            }
            return (
                <div className={Class(styles.component, styles["month-view"])}>
                    {this.props.showMonth ?
                        <div>
                            <div className={styles["left-chevron"]} onClick={this.decrementMonth}>
                                <Icon icon="fa-chevron-left" fixedWidth />
                            </div>
                            <div
                                className={Class(styles.title, { [styles.inactive]: !this.props.showMonth })}
                                onClick={this.props.showMonth ? () => this.setMonthView() : undefined}>
                                {this.currentDate.format("MMMM YYYY")}
                            </div>
                            <div className={styles["right-chevron"]} onClick={this.incrementMonth}>
                                <Icon icon="fa-chevron-right" fixedWidth />
                            </div>
                        </div>
                        : <div className={styles.spacer} />}
                    <div className={Class(styles.content, styles.days)}>
                        <div>
                            <div className={styles.day}>S</div>
                            <div className={styles.day}>M</div>
                            <div className={styles.day}>T</div>
                            <div className={styles.day}>W</div>
                            <div className={styles.day}>T</div>
                            <div className={styles.day}>F</div>
                            <div className={styles.day}>S</div>
                        </div>
                        {month}
                    </div>
                </div>
            );
        }
    }
}
