import "./PreviewWindow.scss";
import "./TemplateRenderer.scss";

import * as React from "react";

import { IObservableObject, observe, toJS } from "mobx";
import { inject, observer } from "mobx-react";

import gearsDialog from "commonui/Dialog";
import { IPageJS } from "./PageConfig";

declare const global: any;

interface ITemplateProps {
    pageJS: IPageJS;
    pageData: IObservableObject;
    page: IObservableObject;
}

@observer
export default class TemplateRenderer extends React.Component<ITemplateProps, never> {
    public render() {
        global.renderer = this;
        const Template = this.props.pageJS.template;
        this.props.pageData;
        return <Template />;
    }
}