import * as Class from "classnames";
import * as React from "react";

import Icon from "../Icon";

interface ICardActionProps extends React.HTMLProps<HTMLDivElement> {
    icon: string;
    onClick: () => void;
}

/**
 * A card action is a singular button in the top right of a card
 */
export default class CardAction extends React.Component<ICardActionProps, never> {
    constructor(props: ICardActionProps) {
        super(props);
    }

    public render() {
        const { className, icon, children, ...other } = this.props;
        return (
            <div className={Class("card-action", className)} {...other}>
                <Icon icon={icon} />
            </div>
        );
    }
}
