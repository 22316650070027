import * as React from "react";
import * as Promise from "bluebird";
import createCustomDialog from "../CustomDialog";
import { observable, action, intercept, runInAction } from "mobx";
import { observer, inject } from "mobx-react";
import styles from "./ConfigDialog.scss";
import * as Class from "classnames";
import { bind } from "decko";
import Input from "../Input";
import Switch from "../Switch";
import Checkbox from "../Checkbox";
import Select, { SelectOptions } from "../Select";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import fontawesome from "@fortawesome/fontawesome";
import Range from "../Range";
import faTimes from "@fortawesome/fontawesome-pro-solid/faTimes";
import faCheck from "@fortawesome/fontawesome-pro-solid/faCheck";
import ActionBar from "../ActionBar";
import ActionItem from "../ActionItem";
import MonacoEditor from "../MonacoEditor";
fontawesome.library.add({ faTimes, faCheck });

import Tooltip from 'commonui/Tooltip';
import DatePicker from 'commonui/DatePicker';
import { Moment } from 'moment';

export default function createConfigDialog<T>(config: CreateConfigDialogConfig<T>, data: T) {
    if (!config.dataBind) {
        return createCustomDialog(<ConfigDialog data={data} {...config} />) as Promise<T>;
    } else {
        return createCustomDialog(<ConfigDialog data={data} {...config} />)
            .then((result: T) => {
                if (result) {
                    runInAction(() => {
                        for (const sidebarGroup of config.config) {
                            for (const sidebarItem of sidebarGroup.children) {
                                for (const fieldGroup of sidebarItem.config) {
                                    for (const field of fieldGroup.children) {
                                        if (!field.ignoreDataBind) {
                                            data[field.field] = result[field.field];
                                        };
                                    }
                                }
                            }
                        }
                    });
                }
                return result;
            }) as Promise<T>;
    }
}

export interface SidebarGroupConfig {
    name?: string;
    children: SidebarItemConfig[];
}

export interface SidebarItemConfig {
    name: string;
    config: LayoutConfig[];
}

export interface LayoutConfig {
    name?: string;
    children: LayoutConfigChild[];
}

interface GenericLayoutConfigChild {
    type: string;
    /** Field to get data from */
    field: string;
    title?: string;
    /** False to make this field disabled on config, if visible is a function the return result is used instead */
    disabled?: boolean | ValidationFunction;
    /** False to make this field not show on config, if visible is a function the return result is used instead */
    visible?: ValidationFunction;
    valid?: ValidationFunction;
    /** If config was created with dataBind=true, this prevents this field from being automatically databound */
    ignoreDataBind?: boolean;
}

type ValidationFunction = (field: string, data: any) => boolean;

interface InputLayoutConfigChild extends GenericLayoutConfigChild {
    type: "string";
}
interface CodeLayoutConfigChild extends GenericLayoutConfigChild {
    type: "code";
    /** Prevents editing, functionally same as disabled with user can still select and copy text */
    readOnly?: boolean;
    language?: "typescript" | "javascript" | "lua" | "sql" | "json" | "json5" | "pgsql" | "html" | "xml";
}
interface SwitchLayoutConfigChild extends GenericLayoutConfigChild {
    type: "switch";
    subtitle?: string;
}
interface CheckboxLayoutConfigChild extends GenericLayoutConfigChild {
    type: "checkbox";
    subtitle?: string;
}
interface SelectLayoutConfigChild extends GenericLayoutConfigChild {
    type: "select";
    options: SelectOptions[] | string | SelectOptionsFunction;
    subtitle?: string;
    /** Allows multiple values inside select box, the data then becomes an array of all selected values */
    multi?: boolean;
    /** False to prevent users from emptying out the value */
    clearable?: boolean;
}
type SelectOptionsFunction = (field: string, data: any) => SelectOptions[];

interface RangeSlider extends GenericLayoutConfigChild {
    subtitle?: string;
    type: "range";
    /** Minimium value possible for the slider */
    min: number;
    /** Maximium value possible for the slider */
    max: number;
    /** Reverses the bar to be right to left instead of left to right */
    reversed?: boolean;
    /** Step range per increment/decrement. eg a step of 5 will only allows values 0, 5, 10, 15, etc */
    step?: number | string;
    /** Disables the passive grey effect when field value is at minimium (or maximium if reversed) */
    alwaysActive?: boolean;
    /** Allows you to format how the strict is displayed. value is the raw value and the returned string is the one shown */
    valueDisplay?: (value: string) => string;
}

interface DateBaseLayoutConfigChild extends GenericLayoutConfigChild {
    subtitle?: string;
    editFormat?: string;
    displayFormat?: string;
    keepMoment?: boolean; // Keeps the value formatted as a Moment object
}
interface DateTimeLayoutConfigChild extends DateBaseLayoutConfigChild {
    type: "datetime";
}
interface DateLayoutConfigChild extends DateBaseLayoutConfigChild {
    type: "date";
}

type DateAndDateTimeLayoutConfigChild = DateTimeLayoutConfigChild | DateLayoutConfigChild;

export type LayoutConfigChild = InputLayoutConfigChild
    | CodeLayoutConfigChild
    | SwitchLayoutConfigChild
    | CheckboxLayoutConfigChild
    | SelectLayoutConfigChild
    | RangeSlider
    | DateAndDateTimeLayoutConfigChild;

interface CreateConfigDialogConfig<T> {
    /** Adds a cancel button that does not save anything (empty result) */
    requireSave?: boolean;
    /** The configuration generated by ConfigDialogBuilder */
    config: SidebarGroupConfig[];
    /** An array of buttons that are displayed in the top right of dialog */
    buttons?: ConfigDialogButton<T>[];
    /** Adds an intercepter that can react to changes in values */
    intercept?: InterceptFunction<T, number | string>;
    /** Attempts to automatically update values after user closes config, this only works if 'data' is a variable */
    dataBind?: boolean;
}

type InterceptFunction<T, A> = (data: T, field: string, value: A) => A;

interface ConfigDialogButton<T> {
    label: string;
    icon: string;
    onClick: (data: T, resolve: () => void) => void;
}

interface ConfigDialogProps {
    requireSave?: boolean;
    config: SidebarGroupConfig[];
    data: any;
    buttons?: ConfigDialogButton<any>[];
    intercept?: InterceptFunction<any, number | string>;
}

const isMobile = /Mobi/.test(navigator.userAgent);
const miniMobile = isMobile && window.innerWidth < 960;

@inject("resolve")
@observer
class ConfigDialog extends React.Component<ConfigDialogProps, never>{
    @observable private activeTab: string = "";
    @observable private data: any;

    constructor(props: ConfigDialogProps) {
        super(props);

        this.data = observable(Object.assign({}, this.props.data));
        if (this.props.intercept) {
            const interceptor = this.props.intercept;
            intercept(this.data, (change) => {
                const value = interceptor(this.data, change.name, change.newValue);
                return { ...change, newValue: value };
            })
        }
        this.activeTab = this.props.config[0].children[0].name;
        if (miniMobile) {
            this.activeTab = "";
        }
    }

    @bind
    @action
    private onChangeTab(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        const name = (e.currentTarget as HTMLElement).dataset.name;
        if (name) {
            this.activeTab = name;
        }
    }

    @bind
    @action
    private onChangeFieldFromSelect(value: string, field: SelectLayoutConfigChild) {
        if (!field.multi) {
            this.data[field.field] = value;
        } else {
            this.data[field.field] = value ? value.split(",") : [];
            const data = this.data[field.field];
            // Convert values to numbers if possible
            for (const index in data) {
                if (data[index]) {
                    if (!isNaN(data[index])) {
                        data[index] = parseFloat(data[index]);
                    }
                }
            }
        }
    }

    @bind
    @action
    private onChangeField(value: string, field: LayoutConfigChild) {
        this.data[field.field] = value;
    }

    @bind
    @action
    private onChangeFieldFromDatePicker(value: Moment, field: DateAndDateTimeLayoutConfigChild) {
        if (value) {
            if (field.keepMoment) {
                this.data[field.field] = value;
            } else {
                if (field.type === "date") {
                    this.data[field.field] = value.format(field.editFormat || "DD-MM-YYYY");
                } else {
                    this.data[field.field] = value.toISOString();
                }
            }
        }
    }

    /**
     * Just pass the original data back as nothing's changed
     */
    @bind
    private onCloseWithoutSave() {
        this.props.resolve();
    }

    @bind
    private onCloseWithSave() {
        this.props.resolve(this.data);
    }

    @bind
    @action
    private onClearActiveTab() {
        this.activeTab = "";
    }

    private renderSidebar() {
        return (
            <div className={styles.sidebar}>
                {miniMobile &&
                    <ActionBar>
                        {this.renderMobileButtons()}
                    </ActionBar>}
                {this.props.config.map((config, index) => {
                    const items = [];
                    if (config.name) {
                        items.push(
                            <h6
                                key={config.name}>
                                {config.name}
                            </h6>
                        );
                    }
                    items.push(
                        config.children.map((childConfig) => {
                            return (
                                <a href="#"
                                    className={Class({ [styles.active]: this.activeTab === childConfig.name })}
                                    key={childConfig.name}
                                    data-name={childConfig.name}
                                    onClick={this.onChangeTab}
                                    tabIndex={0}>
                                    {childConfig.name}
                                </a>
                            );
                        })
                    );
                    if (index < this.props.config.length - 1) {
                        items.push(<hr key={index} />);
                    }
                    return items;
                })}
            </div>
        );
    }

    private renderDesktopButtons() {
        return (
            <div className={styles["action-container"]}>
                {this.props.buttons && this.props.buttons.map((button) => {
                    return (
                        [
                            <FontAwesomeIcon
                                id={"configBuilder_" + button.icon}
                                key={button.icon}
                                icon={button.icon}
                                tabIndex={0}
                                aria-label={button.label}
                                onClick={() => button.onClick(this.data, this.props.resolve)}
                            />,
                            <Tooltip key={button.icon + "_tooltip"} target={"#configBuilder_" + button.icon} event="hover" mount="below-center">
                                {button.label}
                            </Tooltip>
                        ]
                    )
                })}
                {this.props.requireSave &&
                    [
                        <FontAwesomeIcon
                            key="icon"
                            id="configBuilderSave"
                            icon={faCheck}
                            tabIndex={0}
                            className={styles.save}
                            onClick={this.onCloseWithSave}
                        />,
                        <Tooltip key="tooltip" target="#configBuilderSave" event="hover" mount="below-center">
                            Save &amp; Close
                            </Tooltip>
                    ]
                }
                <FontAwesomeIcon
                    id="configBuilderClose"
                    icon={faTimes}
                    tabIndex={0}
                    className={styles.close}
                    onClick={this.props.requireSave ? this.onCloseWithoutSave : this.onCloseWithSave}
                />
                <Tooltip target="#configBuilderClose" event="hover" mount="below-center">
                    Close
                    </Tooltip>
            </div>
        );
    }

    private renderMobileButtons() {
        const buttons: JSX.Element[] = [];
        this.props.buttons && this.props.buttons.map((button) => {
            buttons.push(
                <ActionItem
                    key={button.icon}
                    icon={button.icon}
                    title={button.label}
                    onClick={() => button.onClick(this.data, this.props.resolve)}
                />
            )
        })
        if (this.props.requireSave) {
            buttons.push(
                <ActionItem
                    key="_save"
                    icon={faCheck}
                    title="Save"
                    className={styles.save}
                    onClick={this.onCloseWithSave}
                />
            )
        }
        buttons.push(
            <ActionItem
                key="_close"
                icon={faTimes}
                className={styles.close}
                title="Close"
                onClick={this.props.requireSave ? this.onCloseWithoutSave : this.onCloseWithSave}
            />
        );
        return buttons;
    }

    private renderContent() {
        let activeConfig;
        for (const config of this.props.config) {
            if (config.children) {
                for (const childConfig of config.children) {
                    if (childConfig.name === this.activeTab) {
                        activeConfig = childConfig.config;
                        break;
                    }
                }
            }
        };
        return (
            <div className={styles.config}>
                {miniMobile &&
                    <ActionBar title={this.activeTab} onBackPressed={this.onClearActiveTab} />}
                {!miniMobile && this.renderDesktopButtons()}
                <div className={styles["config-content"]}>
                    {!miniMobile && <h1>{this.activeTab}</h1>}
                    {activeConfig && activeConfig.map((config) => {
                        let output: JSX.Element[] = [];
                        if (config.name) {
                            output.push(<h2 key="header">{config.name}</h2>);
                        }
                        output.push(
                            <div key="content">
                                {config.children.map(this.renderField)}
                            </div>
                        )
                        return output;
                    })}
                </div>
            </div>
        );
    }

    @bind
    private renderField(field: LayoutConfigChild, index: number) {
        const visible = field.visible !== undefined ? field.visible(field.field, this.data) : true;
        if (!visible) {
            return undefined;
        }
        const disabled = field.disabled ? typeof (field.disabled) === "boolean" ? field.disabled : field.disabled(field.field, this.data) : false;
        switch (field.type) {
            case "string":
                return (
                    <div key={field.field + index} className={Class(styles.text, { [styles.disabled]: disabled })}>
                        <Input
                            observable={this.data}
                            label={field.title}
                            value={field.field}
                            disabled={disabled}
                        />
                    </div>
                );
            case "switch":
                return (
                    <div key={field.field + index}>
                        <label className={Class(styles.switch, { [styles.disabled]: disabled, [styles["no-subtitle"]]: !field.subtitle })}>
                            <span className={styles.title}>{field.title}</span>
                            <span className={styles.subtitle}>{field.subtitle}</span>
                            <Switch
                                observable={this.data}
                                value={field.field}
                                disabled={disabled} />
                        </label>
                        <hr />
                    </div>
                );
            case "checkbox":
                return (
                    <div key={field.field + index}>
                        <label className={Class(styles.checkbox, { [styles.disabled]: disabled, [styles["no-subtitle"]]: !field.subtitle })}>
                            <span className={styles.title}>{field.title}</span>
                            <span className={styles.subtitle}>{field.subtitle}</span>
                            <Checkbox
                                observable={this.data}
                                value={field.field}
                                disabled={disabled} />
                        </label>
                        <hr />
                    </div>
                );
            case "select":
                let options = field.options;
                if (typeof field.options === "string") {
                    options = this.data[field.options];
                }
                if (typeof field.options === "function") {
                    options = field.options(field.field, this.data);
                }
                return (
                    <div key={field.field + index}>
                        <label className={Class(styles.select, { [styles.disabled]: disabled, [styles["no-subtitle"]]: !field.subtitle })}>
                            <span className={styles.title}>{field.title}</span>
                            <span className={styles.subtitle}>{field.subtitle}</span>
                            <Select
                                clearable={field.clearable}
                                value={field.multi ? (this.data[field.field] || []).join(",") : this.data[field.field]}
                                options={options}
                                simpleValue
                                onChange={(value: any) => this.onChangeFieldFromSelect(value, field)}
                                multi={field.multi}
                                disabled={disabled} />
                        </label>
                        <hr />
                    </div>
                );
            case "range":
                return (
                    <div key={field.field + index}>
                        <div className={Class(styles.range, { [styles.disabled]: disabled, [styles["no-subtitle"]]: !field.subtitle })}>
                            <span className={styles.title}>{field.title}</span>
                            <span className={styles.subtitle}>{field.subtitle}</span>
                            <Range
                                observable={this.data}
                                value={field.field}
                                min={field.min}
                                max={field.max}
                                showValueLabel
                                reversed={field.reversed}
                                step={field.step}
                                alwaysActive={field.alwaysActive}
                                valueDisplay={field.valueDisplay}
                                disabled={disabled} />
                        </div>
                        <hr />
                    </div>
                );
            case "code":
                return (
                    <div key={field.field + index} className={styles.codebox}>
                        {field.title && <span className={styles.title}>{field.title}</span>}
                        <MonacoEditor
                            type="simple"
                            language={field.language || "json"}
                            value={this.data[field.field]}
                            readOnly={field.readOnly}
                            onChange={(value: string) => this.onChangeField(value, field)}
                            style={{ height: 260 }}
                        />
                    </div>
                );
            case "date":
            case "datetime":
                const displayFormat = field.displayFormat || (field.type === "date" ? "DD-MM-YYYY" : undefined);
                const editFormat = field.editFormat || (field.type === "date" ? "DD-MM-YYYY" : undefined);
                return (
                    <div key={field.field + index}>
                        <div className={Class(styles.date, { [styles.disabled]: disabled, [styles["no-subtitle"]]: !field.subtitle })}>
                            <span className={styles.title}>{field.title}</span>
                            <span className={styles.subtitle}>{field.subtitle}</span>
                            <DatePicker
                                value={this.data[field.field]}
                                displayFormat={displayFormat}
                                editFormat={editFormat}
                                onChange={(value: any) => this.onChangeFieldFromDatePicker(value, field)}
                                disabled={disabled} />
                        </div>
                        <hr />
                    </div>
                );
            default:
                return (
                    <div key={field + index}>
                        Error, unable to render field, unknown type {field.type}
                    </div>
                )
        }
    }

    public render() {
        if (miniMobile) {
            return (
                <div className={Class(styles.container, styles["mini-mobile"], { [styles.mobile]: isMobile })}>
                    {this.activeTab ? this.renderContent() : this.renderSidebar()}
                </div>
            );
        } else {
            return (
                <div className={Class(styles.container, { [styles.mobile]: isMobile })}>
                    {this.renderSidebar()}
                    {!miniMobile && this.renderContent()}
                </div>
            );
        }
    }
}
