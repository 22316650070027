import * as _ from "lodash";
import { observer } from "mobx-react";
import * as PropTypes from "prop-types";
import * as React from "react";

import ErrorDisplayComponent from "../ErrorDisplayComponent";

import {
    ICompiledPageConfig,
    IComponentsConfig,
    IComponentsJS,
    IComponentsMapped,
    IPageJS,
    IScript,
    IScriptConfig,
} from "./PageConfig";

import { observable } from "mobx";

export function evalComponentFunction(source: string): React.ComponentClass<any> {
    if (!source.indexOf("tryCatch")) {
        const index = source.lastIndexOf("return");
        const first = source.slice(0, index);
        const second = source.slice(index + "return".length);
        source = first + "try{return" + second;
        source = source.slice(0, source.length - 3);
        source = source + "}catch(e){console.error(e);return React.createElement('div',{className:'template-component-error'},'Error: ' + e.toString())}})";
    }
    const fn = eval(source) as React.StatelessComponent<any>;
    fn.contextTypes = { mobxStores: PropTypes.any };
    return observer(fn);
}

export default function toPageJS(config: (ICompiledPageConfig | IPageJS) & IComponentsMapped): IPageJS {
    if (config.state === "js") {
        return config;
    }
    console.log("toPageJs", config);
    console.groupCollapsed("Compiled Components");
    console.log("Main", config.template);
    const template = evalComponentFunction(config.template) as React.ComponentClass<any>;
    const transformedComponents = _.transform(
        config.components as IComponentsConfig,
        (components: IComponentsJS, source: string, name: string) => {
            console.log("Evalling component", { name, source });
            components[name] = evalComponentFunction(source);
        }, {},
    );
    console.groupEnd();
    console.log(transformedComponents);
    Object.keys(transformedComponents).map((key) => {
        const component = transformedComponents[key];
        global[key] = (props: any) => React.createElement(ErrorDisplayComponent, {}, React.createElement(component, props));
    });

    console.groupCollapsed("Compiled Scripts");
    const transformedScripts = _.transform(
        config.scripts as IScriptConfig,
        (scripts, source: IScript, name: string) => {
            console.log({ name, source });
            scripts[name] = eval(source.code);
        }, {},
    );
    Object.assign(global, transformedScripts);
    console.log(transformedScripts);
    console.groupEnd();

    const javascript = eval(config.javascript);
    Object.defineProperty(javascript, "name", { value: "MainScript" });
    const preload = config.preload ? eval(config.preload) : null;
    return {
        ...config as any,
        ...{
            compiled: config,
            components: observable.map(transformedComponents),
            dataSource: config.dataSource,
            javascript,
            preload,
            state: "js",
            template,
        },
    } as IPageJS;
}
