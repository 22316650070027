import * as React from "react";
import styles from "./Note.scss";
import * as $ from "jquery";
import { observer, inject } from "mobx-react";
import { observable, action } from "mobx";
import { bind } from "decko";
import * as _ from "lodash";
import * as Class from "classnames";
import * as moment from "moment";
import NoteType from "./NoteType";
import Button from "commonui/Button";
import TextArea from "commonui/TextArea";
import Dialog from "commonui/Dialog";

import ParentNote from "./ParentNote";
import { NoteManager } from '.';

interface NoteProps {
    filter?: object;
    name?: string | NoteNameFunction;
    limit?: number;
    hideResolved?: boolean;
    canCreate?: boolean;
    showHeader?: boolean;
    showName?: boolean;
    removeControls?: boolean;
}

type NoteNameFunction = (metadata?: object) => string

@inject("noteStore")
@observer
export default class Note extends React.Component<NoteProps, never> {
    @observable private creatingNote: boolean = false;
    @observable private noteText: string = "";

    @bind
    @action
    private startCreateNote() {
        this.creatingNote = true;
    }

    @bind
    @action
    private onCancelNote() {
        this.noteText = "";
        this.creatingNote = false;
    }

    @bind
    @action
    private onAddReply(reply: NoteType) {
        this.creatingNote = false;
        this.noteText = "";
        if (_.findIndex(this.props.noteStore.notes, { id: reply.id }) < 0) {
            this.props.noteStore.notes.push(reply);
        }
    }

    @bind
    private onFailedCreate() {
        Dialog({
            title: "Failed to create note",
            content: "An unexpected error occured or your connection to the server timed out, please try again",
            buttons: ["OK"],
        });
    }

    @bind
    private onFailedReply() {
        Dialog({
            title: "Failed to reply",
            content: "An unexpected error occured or your connection to the server timed out, please try again",
            buttons: ["OK"],
        });
    }

    @bind
    @action
    private onCreateNote() {
        const noteStore = this.props.noteStore as NoteManager;
        if (this.noteText) {
            $.ajax({
                url: noteStore.submitRoute || "/notes/",
                method: "POST",
                dataType: "json",
                data: {
                    note: {
                        channel: noteStore.channel,
                        note_text: this.noteText,
                        created_by_id: noteStore.user,
                        created_by_email: noteStore.email,
                        webfront_metadata: {
                            ...this.props.filter,
                            _name: this.props.name,
                        }
                    },
                    ...noteStore.data,
                }
            })
                .then(this.onAddReply)
                .catch(this.onFailedCreate);
        } else {
            this.creatingNote = false;
        }
    }

    public render() {
        const { name, filter, limit, hideResolved, canCreate, showHeader, showName, removeControls } = this.props;

        const noteStore = this.props.noteStore as NoteManager;

        if (noteStore.loading) {
            return (
                <div className={Class(styles["no-notes"], "no-notes")}>
                    <span className={Class(styles.message, "message")}>Loading Notes...</span>
                </div>
            );
        }

        const realCreate = canCreate === undefined ? true : canCreate;
        const notes = noteStore.notes as NoteType[];
        const displayName = name ? typeof (name) === "string" ? name : name(filter) : undefined;
        const filteredNotes = notes
            .filter((item) => !item.parent_note_id)
            .filter((item) => hideResolved ? item.resolved_at === null : true)
            .filter((item) => filter ? _.find(item, filter) !== undefined : true);

        return (
            <div className={Class(styles["note-area"], "note-area")}>
                {displayName && filteredNotes.length > 0 && showName &&
                    <div className={Class(styles["note-header"], "note-header")}>{displayName}</div>
                }
                {
                    filteredNotes
                        .map((note) => {
                            return (
                                <ParentNote
                                    key={note.id}
                                    note={note}
                                    user={noteStore.user}
                                    onAddReply={this.onAddReply}
                                    onFailedReply={this.onFailedReply}
                                    filter={filter}
                                    name={displayName}
                                    canResolve={noteStore.canResolve}
                                    channel={noteStore.channel}
                                    showHeader={showHeader}
                                    removeControls={removeControls}
                                    childNotes={_.filter(notes, { parent_note_id: note.id })} />
                            );
                        })}
                {!filteredNotes.length && !this.creatingNote &&
                    <div className={Class(styles["no-notes"], "no-notes")}>
                        <span className={Class(styles.message, "message")}>{displayName ? `No Active Notes for ${displayName}` : "No Active Notes"}</span>
                        {realCreate &&
                            <Button
                                onClick={this.startCreateNote}
                                className={Class(styles.create, "create")}
                                title="Create Note"
                                icon="fa-plus"
                                coloured />}
                    </div>
                }
                {realCreate && filteredNotes.length > 0 && !this.creatingNote && (limit ? filteredNotes.length < limit : true) &&
                    <div className={styles["add-note"]}>
                        <Button
                            onClick={this.startCreateNote}
                            className={Class(styles.create, "create")}
                            title="Create Note"
                            icon="fa-plus"
                            coloured />
                    </div>
                }
                {this.creatingNote &&
                    <div className={Class(styles["create-note"], "create-note")}>
                        <TextArea
                            hideLabel
                            placeholder={displayName || "Note"}
                            observable={this}
                            value="noteText"
                            label={displayName || "Note"} />
                        <Button
                            onClick={this.onCreateNote}
                            title="Create"
                            icon="fa-check"
                            coloured
                        />
                        <Button
                            className={"red"}
                            onClick={this.onCancelNote}
                            title="Cancel"
                            icon="fa-times"
                            coloured
                        />
                    </div>
                }
            </div>
        );
    }
}
