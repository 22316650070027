exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".glPDUwq9uWsrBDGfOr-QZ blockquote p{display:inline}\n", ""]);

// exports
exports.locals = {
	"markdown": "glPDUwq9uWsrBDGfOr-QZ"
};