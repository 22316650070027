import * as Class from "classnames";
import * as React from "react";
import * as styles from "./ActionTabs.scss";

interface IActionTabsProps extends React.HTMLProps<HTMLElement> {
    defaultTab?: number;
}

interface IActionTabsState {
    tab: number;
}

export default class ActionTabs extends React.Component<IActionTabsProps, IActionTabsState> {
    constructor(props: IActionTabsProps) {
        super(props);

        this.state = { tab: props.defaultTab || 0 };
    }

    public render() {
        const { children, ...other } = this.props;
        const realChildren = (Array.isArray(children) ? children : [children]) as Array<React.ReactElement<any>>;
        return (
            <div>
                <ul className={Class(styles["tab-controller"])}>
                    {realChildren.map((item, index) => {
                        return (
                            <li className={Class(styles.tab, {
                                [styles.active]: index === this.state.tab,
                            })}
                                onClick={() => { this.setState({ tab: index }); }}
                                tabIndex={0}
                                role="link">
                                {item.props.title}
                            </li>
                        );
                    })}

                </ul>
                {realChildren[this.state.tab]}
            </div>
        );
    }
}
