exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "ul.tLa0ot8db1j__bsLF-9xq{width:100%;margin:0px;margin-top:-48px;padding:0px;height:48px;list-style:none;display:flex;position:sticky;z-index:10;top:64px;color:white;box-sizing:border-box}ul.tLa0ot8db1j__bsLF-9xq li{cursor:pointer;margin:auto;box-sizing:border-box;flex:1;display:inline-block;max-width:264px;font-size:14px;text-align:center;line-height:48px;border-bottom:4px solid transparent;transition:border-bottom-color 200ms;text-transform:uppercase;font-weight:500;height:48px;outline:none}ul.tLa0ot8db1j__bsLF-9xq li:hover,ul.tLa0ot8db1j__bsLF-9xq li:focus{border-bottom-color:rgba(255,255,255,0.5)}ul.tLa0ot8db1j__bsLF-9xq li._396__ZUnIE777b2hCrCEWj{border-bottom-color:#fff}\n", ""]);

// exports
exports.locals = {
	"tab-controller": "tLa0ot8db1j__bsLF-9xq",
	"active": "_396__ZUnIE777b2hCrCEWj"
};